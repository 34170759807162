@import '../../../../Assets/Theme/variables.scss';
@import '../../../../Assets//Theme/mixins.scss';

.sliderSidePanel {
  color: $c-black;
  .sideFlex {
    display: flex;
  }
  .pageContent {
    display: flex;
  }
  .sliderName {
    display: flex;
    justify-content: space-between;
    .MuiButton-root {
      margin-top: -5px;
      color: $c-blue;
    }
    .MuiTypography-root {
      color: $c-black;
      font-size: 16px;
    }
  }
  .inactiveSlider {
    .MuiTypography-root {
      color: $c-danger;
      font-size: 14px;
    }
  }
  .sliderStatus {
    .MuiTypography-root {
      color: $c-blue;
      font-size: 14px;
    }
  }
  .MuiSvgIcon-root {
    margin-top: 5px;
    margin-right: 10px;
    margin-bottom: -3px;
    font-size: 15px;
    @include rtl {
      margin-right: 0px;
      margin-left: 10px;
    }
  }
  .slideImg {
    background-size: cover;
    background: $c-white;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100px;
    min-width: 100px;
    height: 100px;
    min-height: 100px;
    border-radius: 10px;
    display: flex;
    box-shadow: 3px 3px 6px #00000033;
  }
  .mdi {
    color: $c-gray-dark;
  }
  .displayImg {
    background-size: cover;
    display: flex;
    flex-direction: row;
    .MuiTypography-root {
      padding-left: 20px;
      padding-right: 10px;
      @include rtl {
        padding-right: 20px;
        padding-left: 10px;
      }
      padding-top: 5px;
      padding-bottom: 10px;
      font-size: 15px;
    }
  }
  .slideImgContainer {
    max-height: 250px;
    overflow-y: scroll;
  }
  .sliderDesc {
    margin-top: -20px;
    height: 3em;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  .slideInfo {
    display: grid;
    margin-top: 10px;
  }
  .previewSlider {
    .MuiTypography-root {
      font-size: 15px;
      color: $c-black;
    }
    .previewImg {
      padding-top: 20px;
      background: $c-white;
      overflow: auto;
      display: flex;
      flex-direction: row;
    }
  }
  .editBtn {
    .MuiButton-label {
      color: $c-secondary;
    }
    background-color: $c-white !important;
    border: solid 1.5px $c-secondary;
    margin-top: 5px !important;
  }
}
