@import '../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../Assets/Theme/mixins.scss';

$colortext: #002d59;
.BestTours-Seaction {
  padding: 2rem;
  background-color: rgba(245, 245, 245, 0.383);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .best-tours-title {
    font-size: 20px;
    padding-bottom: 1rem;
  }
  .rating-wrapper {
    padding: 0.1rem;
    margin-left: -0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-container {
    display: flex;
    overflow: auto;
    justify-content: center;
    align-items: center;
  }
  .first-card-container {
    position: relative;
    padding: 1rem;
    width: 16.5rem;
    margin: 0.7rem;
    border: 0.18rem solid #e8e8e8;
    border-radius: 22px;
    background-color: white;
  }
  .img-center {
    width: 14rem;
    height: 7rem;
    border-radius: 22px;
  }
  .center-card-wrapper {
    border-radius: 22px;
    width: 17.5rem;
    box-shadow: 5px 3px 25px #00000029;
    .img-center {
      width: 15rem;
      height: 9rem;
      border-radius: 22px;
    }
  }
  .favourite-heart {
    color: rgb(255, 255, 255);
    position: absolute;
    right: 1.7rem;
    top: 1.3rem;
    background-color: white;
    border-radius: 20rem;
    box-shadow: 0px 3px 6px #00000029;
  }
  .text-big-bold {
    color: $colortext !important;
    font-size: 17px;
    font-weight: bold;
  }
  .text-big-bold-center {
    color: $colortext !important;
    padding-top: 1rem;
    font-size: 17px;
    font-weight: bold;
  }
  .text-small {
    color: $colortext !important;
    font-size: 14px;
  }
  .textrating {
    font-size: 12px;
  }
  .MuiButton-root {
    text-transform: capitalize;
    color: white;
    font-weight: bold;
    margin-top: -0.3rem;
    font-size: 12px;
    border-radius: 20rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background: rgb(255, 127, 24);
    background: linear-gradient(
      90deg,
      rgba(255, 127, 24, 1) -15%,
      rgba(255, 19, 100, 1) 43%,
      rgba(43, 6, 73, 1) 300%
    );
    float: right;
    @include rtl {
      float: left;
    }
  }
}
