.slider {
  .conterofcar {
    overflow: auto;
    max-height: 700px;
  }

  .editbutton {
    height: 40px;
  }

  .textslabel {
    color: black;
    margin-top: 5px;
    margin-left: 10px;
  }

  .mdi {
    color: black;
  }
  .mdi-home {
    color: black;
  }

  .mdi-map-marker {
    color: black;
    display: flex;
  }

  .titlessingl {
    padding-left: 40px !important;

    display: flex;
    width: 161px;

    color: var(--unnamed-color-272727);
    text-align: left;
    font: Medium 13px/20px Poppins;
    letter-spacing: 0px;
    color: #272727;
    font-weight: bold;
  }
  .textslabelmain {
    padding-left: 8px;
    font-size: 16px;
    color: black;
  }
  .textsdletemain {
    padding-left: 8px;
    font-size: 16px;
    color: #31aec4;
    cursor: pointer;
  }

  .textsdletemain:hover {
    padding-left: 8px;
    font-size: 16px;
    color: #bf3c3c;
    cursor: pointer;
  }

  .iconslideredit {
    padding-left: 8px;
    font-size: 16px;
    color: #31aec4 !important;
    cursor: pointer;
  }

  .iconslideredit:hover {
    padding-left: 8px;
    font-size: 16px;
    color: #05182e !important;
    cursor: pointer;
  }

  .Buttonedits {
    margin-top: 40px;
  }
  .btcolor {
    background-color: #006186 !important;
  }
  .btns.MuiButtonBase-root.theme-solid {
    background-color: #006186 !important;
  }
}
.labelcard {
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .sliderView .MuiGrid-root .MuiGrid-container:not(.MuiGrid-item) {
    flex-wrap: nowrap;
  }

  @media (max-width: 767.98px) {
    .sliderView .slideWrapper .sliderCard .MuiCardContent-root {
      padding-right: 0px !important;

      .sliderView .MuiGrid-root .MuiGrid-container:not(.MuiGrid-item) {
        flex-wrap: nowrap;
        justify-content: space-between;
      }
    }
  }
}
.hoverdelteslidericon {
  color: #006186 !important;
}
.hoverdelteslidericon:hover {
  color: #bf3c3c !important;
}
.iconedit {
  color: #006186 !important;
}
.iconedit:hover {
  color: #999999 !important;
}
.states{
.active
{
  
  color: #44ea0e !important;
}
 
.inactive
{
  color:#bf3c3c !important;
}

}
.PlateNum {
  width: 85px;
  height: 23px;
  border-radius: 12px;
  background-color: #060606;
  padding-left: 10px;
  padding-right: 10px;
  color: #e6e6e6;
  font-weight: 500;
  text-align: center;
}
.labelcard {
  font-weight: 600;
}
.PlateContener {
  padding-top: 1px;
}

.state-online
{
  background: rgb(66, 183, 42);
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  margin-left: 4px;
  width: 6px;
}
.state-offline
{
  background: #bf3c3c;;
  border-radius: 50%;
  display: inline-block;
  height: 6px;
  margin-left: 4px;
  width: 6px;
}

.state-off 
{
  color: #bf3c3c;
}
