@import '../../../../../Assets/Theme/mixins.scss';

.TotalSection {
  width: 86%;
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff;
 
    .MuiGrid-container{   
      justify-content:center;
    }
    
  
 
}

.ChartDiv {
  max-width: 278px;
  width: 120px;
  height: 70px;
  margin-top: 7px;
  position: relative;

  .percentage-number {
    position: absolute;
    font-weight:bold;
    color: black;
    top: 1.3rem;
    left: 3.2rem;
  }

  .chartjs.size.monitor.shrink {
    position: static;
  }

}

.titleDiv {
  display: flex;
  .titleParagraph {
    flex-basis: 45%;
    margin-top: 20px;
  }
}

.Total {
  box-shadow: 0px 25px 30px #0000001a;
  border-radius: 10px;
  opacity: 1;
  min-width: 274px;
  position: relative;
  min-height: 162px;
}

.Chart {
  background-image: url('../../../../../Assets/Images/DashboardImages/11223.png');
  margin: 18px;
  width: 48px;
  height: 48px;
  @include laptops-max {
  }
}

.legend {
  text-align: left;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 10px;
    li {
      margin: 0 0 3px 0;
      white-space: nowrap;
    }
  }
}
.list {
  padding: 0;
  margin: 10px;
  flex-wrap: nowrap;
}

.chartGrid {
  width: 90px !important;
}
