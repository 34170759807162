@import '../../../../../Assets/Theme/variables.scss';
.agents-details-wrapper {
  padding: 0.5rem;
  color: $c-black-light;
  .MuiAccordionDetails-root {
    padding: 0;
    margin-top: -1rem;
  }
  .MuiPaper-elevation1 {
    box-shadow: 0px 0px 0px 0px transparent;
  }
  .trip-cover-image {
    width: 100%;
    border-radius: 1rem;
    height: 130px;
    background-size: 100%;
    box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
    background-repeat: no-repeat;
    background-position: center;
  }
  .tour-address {
    display: flex;
    align-items: baseline;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 160px;
  }
  .item-wrapper {
    padding-top: 0.5rem;
  }
  .basket-divider {
    display: flex;
    justify-content: center;
    .MuiDivider-root {
      margin-top: 0.5rem;
      width: 90%;
    }
  }
  .basket-info {
    display: flex;
    padding-bottom: 0.3rem;
  }
  .mdi:before {
    font-size: 16px;
    margin-top: -0.1rem;
    padding-right: 0.3rem;
    padding-left: 0.3rem;
  }
  .basket-timeline {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    .basket-info-container {
      padding-top: 0.5rem;
    }
    .mdi:before {
      font-size: 16px;
      margin-top: -0.1rem;
      padding-right: 0.2rem;
      padding-left: 0rem;
    }
  }
  .MuiTimelineItem-missingOppositeContent:before {
    content: none;
  }
  .timeline-info {
    .MuiTypography-body1 {
      font-weight: bold;
      font-size: 14px;
    }
  }
}
