@import '../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../Assets/Theme/mixins.scss';
.tour-trip-card-component-wrapper {
  @include d-flex-v-center;
  flex-wrap: wrap;
  max-height: calc(100vh - 400px);
  overflow-y: auto;
  .tour-card-body {
    padding: 1rem !important;
  }
  .drag-tour-images {
    padding: 1rem 1rem 0 1rem;
  }
  .item-trip-name {
    max-width: 15rem;
    font-weight: bold;
    padding-bottom: 0.7rem;
  }
  .tour-item-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .tour-address {
    max-width: 6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .drag-tour-item {
    display: flex;
  }
  .distance-info {
    @include d-inline-flex-v-center;
    color: $c-secondary;
    font-weight: bold;
    padding: 0.5rem 1rem 0;
  }
  &.fake-dragging-item {
    pointer-events: none;
    .tour-trip-card-wrapper {
      width: 100%;
    }
  }
  .tour-trip-card-wrapper {
    @include d-inline-flex;
    flex-wrap: wrap;
    opacity: 1;
    animation: show_animation 0.5s linear 0s 1 normal;
    width: 50%;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    min-width: 280px;
    @include laptops-max {
      width: 100%;
    }
    .trip-cover-image {
      @include d-inline-flex;
      width: 100px !important;
      min-width: 100px;
      border-radius: 1rem;
      box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
      height: 100px;
      margin-left: 1rem;
      margin-right: 0.5rem;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
    .cards-wrapper {
      &.is-draggable {
        cursor: move;
      }
      &.is-drag-over {
        padding-top: 25%;
      }
      .cards-body {
        padding: 1rem 0;
        pointer-events: none;
        flex-direction: row;
        .item-wrapper {
          width: 50%;
          max-width: initial;
        }
      }
    }
  }
}
.timeline-padding {
  .MuiTimeline-root {
    min-height: calc(100vh - 400px);
    margin: 1rem;
  }
}
@keyframes show_animation {
  0% {
    opacity: 0;
    height: 0;
    overflow: hidden;
  }
  100% {
    opacity: 1;
    height: 100%;
  }
}
