@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';
.map-details-component-wrapper {
  padding: 1rem;
  .map-details-wrapper {
    border: 1px solid $c-gray-default;
    border-radius: 1rem;
    padding: 1rem;
    .map-details-header {
      position: relative;
      padding: 0.5rem 0;
      color: $c-gray-primary;
      @include d-flex-column;
      .texts-large {
        font-weight: bold;
      }
    }
  }
}
