@import '../../../../../../Assets/Theme/variables.scss';
.reservation-tours-list-details-wrapper {
  color: $c-black-light;
  .total-tours {
    min-height: 35px;
    background-color: $c-secondary;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 100%;
    margin-bottom: 1rem;
    border-radius: .5rem;
    color: $c-white;
    font-weight: bold;
  }
  .item-header {
    font-weight: bold;
    display: inline-flex;
    flex: 0 1 auto;
    align-items: center;
    .mdi:before {
      line-height: 14px;
    }
  }
  .trip-image {
    width: 80%;
    margin-bottom: 0.5rem;
    .slide-image {
      border-radius: 1rem;
      width: 100%;
    }
    border-radius: 1rem;
  }
}
