// Start Screen Sizes
@mixin laptops-xxl-max {
  @media (max-width: 2560px) {
    @content;
  }
}

@mixin laptops-xl-max {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin laptops-l-max {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin laptops-max {
  @media (max-width: 1199.98px) {
    @content;
  }
}

@mixin tablets-max {
  @media (max-width: 991.98px) {
    @content;
  }
}

@mixin phones-max {
  @media (max-width: 767.98px) {
    @content;
  }
}

@mixin small-phones-max {
  @media (max-width: 575.98px) {
    @content;
  }
}

@mixin phones-xs-max {
  @media (max-width: 320px) {
    @content;
  }
}

// Start Direction
@mixin rtl {
  .rtl & {
    @content;
  }
}

@mixin rtl-in {
  .rtl {
    @content;
  }
}

// Start Display
@mixin d-inline-flex {
  display: inline-flex;
  flex: 0 1 auto;
}

@mixin d-flex {
  display: flex;
  flex: 1 1 auto;
}

@mixin flex-h-center {
  justify-content: center;
}

@mixin flex-h-end {
  justify-content: flex-end;
}

@mixin flex-h-start {
  justify-content: flex-start;
}
@mixin flex-v-start {
  align-items: flex-start;
}

@mixin flex-v-center {
  align-items: center;
}

@mixin flex-v-end {
  align-items: flex-end;
}

@mixin flex-v-between {
  align-items: space-between;
}

@mixin flex-h-between {
  justify-content: space-between;
}

@mixin flex-column {
  flex-direction: column;
}

@mixin flex-v-center-h-between {
  @include flex-h-between;
  @include flex-v-center;
}

@mixin flex-v-end-h-between {
  @include flex-h-between;
  @include flex-v-end;
}

@mixin flex-center {
  @include flex-v-center;
  @include flex-h-center;
}

@mixin d-inline-flex-center {
  @include d-inline-flex;
  @include flex-center;
}

@mixin d-flex-column-center {
  @include d-flex;
  @include flex-column;
  @include flex-center;
}

@mixin d-flex-column {
  @include d-flex;
  @include flex-column;
}

@mixin d-flex-column-between {
  @include d-flex-column;
  @include flex-beteen;
}

@mixin d-flex-column-v-center {
  @include d-flex;
  @include flex-column;
  @include flex-v-center;
}

@mixin d-flex-center {
  @include d-flex;
  @include flex-center;
}

@mixin d-flex-column-v-center-h-start {
  @include d-flex-column;
  @include flex-v-center;
  @include flex-h-start;
}
@mixin d-flex-v-center-h-start {
  @include d-flex;
  @include flex-v-center;
  @include flex-h-start;
}

@mixin d-inline-flex-v-center {
  @include d-inline-flex;
  @include flex-v-center;
}
@mixin d-flex-v-center {
  @include d-flex;
  @include flex-v-center;
}
@mixin d-flex-h-center {
  @include d-flex;
  @include flex-h-center;
}
@mixin d-flex-v-start-h-center {
  @include d-flex;
  @include flex-h-center;
  @include flex-v-start;
}

@mixin d-flex-v-center-h-between {
  @include d-flex;
  @include flex-v-center;
  @include flex-h-between;
}

@mixin d-flex-v-center-h-end {
  @include d-flex;
  @include flex-v-center;
  @include flex-h-end;
}

@mixin d-flex-v-end-h-center {
  @include d-flex;
  @include flex-v-end;
  @include flex-h-center;
}
@mixin d-inline-flex-column-center {
  @include d-inline-flex-column;
  @include flex-center;
}
@mixin d-inline-flex-column {
  @include d-inline-flex;
  @include flex-column;
}
