@import '../../../Assets/Theme/variables.scss';
@import '../../../Assets/Theme/mixins.scss';
.dashboard-wrapper {
  width: 100%;
  height: 100% !important;
  padding-top: 2rem;
  margin-top: -1rem;
  background-color:#FAFAFA;

  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(0, 0);
    }
    50% {
      transform: scale(0.9, 1.1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  @include small-phones-max{
  bottom:0;
  width: 100%;
  justify-content: space-between;
  flex-direction: column-reverse;
  }

  .cardcontainer {
    animation: zoominoutsinglefeatured 0.7s;
    -webkit-animation: zoominoutsinglefeatured 0.7s;
    .map-wrapper .map-item-wrapper {
      z-index: -1;
    }
  }


  .dashboard-placeholder {
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    @include laptops-xxl-max {
      background-image: url('../../../Assets/Images/DashboardImages/dashboardLarge.png');
      height: 170rem;
      margin-top: -4.5rem;
      margin-left: -3rem;
    }
    @include laptops-xl-max {
      background-image: url('../../../Assets/Images/DashboardImages/dashboardLaptop.png');
      height: 248rem;
      margin-top: -5rem;
      margin-left: -2rem;
    }
    @include laptops-l-max {
      background-image: url('../../../Assets/Images/DashboardImages/dashboardTablet.png');
      height: 255rem;
      margin-top: -5rem;
      margin-left: -1rem;
    }
    @include laptops-max {
      background-image: url('../../../Assets/Images/DashboardImages/dashboardTablet.png');
      height: 215rem;
      margin-top: -4rem;
      margin-left: -1rem;
    }
    @include tablets-max {
      background-image: url('../../../Assets/Images/DashboardImages/dashboardTablet.png');
      height: 215rem;
      margin-top: -2.8rem;
      margin-left: 0;
    }
    @include phones-max {
      background-image: url('../../../Assets/Images/DashboardImages/dashboardMobile.png');
      height: 47rem;
      background-size: contain;
      margin-top: 0;
      margin-left: 0;
    }
  }

  .text {
    position: absolute;
    text-align: center;
    justify-content: center;
    top: 50%;
    left: 38%;
    right: 62;
    -ms-transform: rotate(-30deg);
    /*IE9*/
    transform: rotate(30deg);
    color: white;
    transform: translate(-50%, -50%);
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    -moz-transform: rotate(30deg);
    -o-transform: rotate(30deg);
    @include rtl {
      top: 50%;
      left: 62;
      right: 38%;
    }
    @media only screen and (min-width: 600px) {
      top: 19%;
      left: 14%;
    }
    @media only screen and (min-width: 768px) {
      top: 30%;
      left: 25%;
    }
    @media only screen and (min-width: 992px) {
      top: 50%;
      left: 38%;
    }

    .glow {
      font-size: 50px;
      color: #fff;
      text-align: center;
      -webkit-animation: glow 1s ease-in-out infinite alternate;
      -moz-animation: glow 1s ease-in-out infinite alternate;
      animation: glow 1s ease-in-out infinite alternate;
      @media only screen and (max-width: 600px) {
        font-size: 30px;
      }
    }
    @keyframes glow {
      from {
        text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #042ff0e8, 0 0 40px #2e7bc9,
          0 0 50px #055eb7, 0 0 60px #055eb7, 0 0 70px #055eb7;
      }

      to {
        text-shadow: 0 0 20px #fff, 0 0 30px #c2cfd4, 0 0 40px #4285d1, 0 0 50px #006186,
          0 0 60px #006186, 0 0 70px #006186, 0 0 80px #006186;
      }
    }
  }
}

.Tables-charts{

  .tables{
    .UpComingTable{
      margin-top:30px;
      display: flex;
    margin-right: 20px;
    @include laptops-xxl-max{
      justify-content: flex-end;
      
    }
    @include tablets-max {
      justify-content:center;
  } 
    }
    .CarsTable{
      margin-top:30px;
      display: flex;
    margin-right: 20px;
    @include laptops-xxl-max{
      justify-content: flex-end;
      
    }
    @include tablets-max {
      justify-content:center;
  }  

    }
    .UsersChart{
      margin-top:30px;
      display: flex;
    margin-right: 20px;
   @include laptops-xxl-max{
      justify-content: flex-end;
      
    }
    @include tablets-max {
      justify-content:center;
  } 
    }
  }
  .Charts{
    
  
  .Sales{
    margin-top:30px;
    @include tablets-max {
      display: flex;
    justify-content:center;
  } 
  }
  .New-User{
    margin-top:30px;
    @include tablets-max {
      display: flex;
    justify-content:center;
  } 
   
  }
  .Total-Source{
    margin-top:30px;
    @include tablets-max {
      display: flex;
    justify-content:center;
  } 
  }
  }
}
.SalesComponent{
  margin-top: 30px!important;
width:86% !important;
margin: auto!important;
justify-content:center!important;
}
.TotalSales{
width: 90%;
margin: 20px 0 20px 0;
  .statsData{
    margin:20px
  }
  .statsPercentage{
    margin:20px
  }
}
.TotalSalesComponent{
  width: 90%;
  margin: 20px 0 20px 0;
  .SalesFunnel{
width:100%;
  }
}
.TotalSoldTours{
  width: 90%;
  margin: 20px 0 20px 0;
}
.SoldTours{
  width: 90%;
  margin: 20px 0 20px 0;
}

.ToursComponent{
  margin-top: 30px!important;
width:86% !important;
margin: auto!important;
justify-content:center!important;
}
.TourStatusComponent{
  
  margin: 20px 0 20px 0;
  .TourStatus{
    width: 100%;
  }
}
.TotalTours{
  width: 90%;
  margin: 20px 0 20px 0;
  
}
.Locations-tours{
  width: 90%!important;
  margin: 20px 0 20px 0;
}
.UpComingTours{
  width: 100%;
  margin: 20px 0 20px 0;
  .UpComing-Table{
    width: 100%;
  }
}


.CarsComponent{
  margin-top: 30px!important;
width:86% !important;
margin: auto!important;
justify-content:center!important;
}
.TotalDrivers{
  width: 90%;
  margin: 20px 0 20px 0;
 
}
.TotalCars{
  width: 90%;
  margin: 20px 0 20px 0;
  
}
.UpComingTours{
  width: 100%;
  margin: 20px 0 20px 0;
 
}
.CarsActivity{
  width: 100%;
  margin: 20px 0 20px 0;
  .CarActivity-Table{
    width: 100%;
  }
 
}
.Cars-Tabel{
  text-align: -webkit-left;
}

.Users{
  width: 86%;
  .Users-Source{
    display:flex;
    width: 90%;
  }
}
.TotalUsers-Tab{
  width: 70%;
  .Total{
    min-height: 188px;
    margin: auto;
  }
  margin: 20px 0 20px 0;
 
}
.WebsiteVisitors{
  .Stats{
    width: 80%;
    margin: auto;
    .statsDiv{
      margin:20px
    }
    .statsData{
      margin:20px
    }
  }

  margin: 20px 0 20px 0;
  
}
.MobileAppVisitors{
  .Stats{
    width: 80%;
    margin: auto;
    .statsDiv{
      margin:20px
    }
    .statsData{
      margin:20px
    }
  }
  margin: 20px 0 20px 0;
}
.TotalVisitor{
  .Stats{
    width: 80%;
    margin: auto;
    .statsDiv{
      margin:20px
    }
    .statsData{
      margin:20px
    }
  }
  margin: 20px 0 20px 0;
 
}

.Users-Engag{
  text-align: -webkit-center;
  .UsersEngagement{
    margin-top: 20px;
    width: 90%
  }
}

.TotalSourceComponent{
  width: 90%;
  margin-top:20px;

}

.Users-New{
  text-align: -webkit-center;
  .NewUserComponent{
    margin-top:20px
  }
}

