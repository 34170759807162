@import '../../../../../../Assets/Theme/mixins.scss';
.car-activity-details-wrapper {
  .item-wrapper {
    @include d-flex-v-center;
    margin-bottom: 0.25rem;
  }
  .item-header {
    @include d-inline-flex-center;
    font-weight: bold;
    padding: 0 0.25rem;
  }
  .gallery-wrapper {
    max-height: 500px;
    overflow-y: auto;
  }
}
