@import './variables.scss';
@import './mixins.scss';

// ======================================================== Start Fonts
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: url('../Fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: AcuminConcept;
  font-display: swap;
  src: url('../Fonts/Poppins-Regular.ttf');
}

// ======================================================== End Fonts
//========================================================= Start Main Tags Override
body {
  min-height: 100vh;
  background-color: $c-white !important;
  font-size: $fz-default;
  font-family: Poppins, serif;
  color: $c-gray-secondary;
  min-width: 320px;
  overflow-y: scroll;
  margin: 0;

  &.rtl {
    text-align: right;
  }

  .MuiTypography-body1,
  .MuiButtonBase-root,
  .MuiFormControl-root,
  .MuiSelect-root,
  .autocomplete,
  .MuiFormLabel-root,
  .MuiChip-label,
  .MuiInputBase-input,
  .MuiTableCell-root,
  .MuiTypography-root {
    font-family: Poppins, serif;
    font-size: $fz-default;
  }
}

@include rtl-in {
  .MuiPickersCalendarHeader-iconButton {
    .MuiSvgIcon-root {
      transform: scale(-1);
    }
  }
}

label {
  margin-bottom: 0;
}

p,
label {
  font-weight: 300;
}
p {
  margin: 0 0 9px;
}

// ========================================================= End Main Tags Override
// ======================================================== Start Plugins Override
.rdrDateRangePickerWrapper {
  @include rtl {
    direction: ltr;
  }
}
.MuiFormHelperText-root,
.MuiFormHelperText-root.MuiFormHelperText-filled {
  color: $c-error !important;
}
.MuiFormHelperText-contained.Mui-error {
  margin-left: 0;
  margin-right: 0;
}
.PrivateSwitchBase-input-8 {
  display: none;
}
.MuiIconButton-colorSecondary:hover,
.MuiRadio-colorSecondary.Mui-checked:hover,
.MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: rgba($c-secondary, 0.1) !important;
}
.MuiRadio-colorSecondary.Mui-checked,
.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgba($c-secondary, 0.3) !important;
}
.MuiInputBase-root {
  &:not(.MuiOutlinedInput-multiline):not(.MuiInputBase-multiline):not(.MuiAutocomplete-inputRoot) {
    height: 30px;
  }
  font-size: $fz-13px !important;
}
.MuiFormControlLabel-label {
  font-size: $fz-13px !important;
}
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
  padding: 6.5px 4px !important;
}
.MuiIconButton-root {
  &:not(.MuiAutocomplete-popupIndicator):not(.MuiSwitch-switchBase) {
    padding: 4px !important;
  }
}
.MuiFormControlLabel-root {
  margin-left: -6px !important;
  @include rtl {
    margin-right: -6px !important;
    margin-left: 1rem !important;
  }
}
.MuiSvgIcon-root {
  width: 18px;
}
.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot,
.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  @include rtl {
    padding-left: 30px;
    padding-right: 0.5rem;
  }
}
form {
  height: 100%;
}
.dialog-footer-wrapper {
  position: sticky;
  top: 100%;
}
.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot {
  @include rtl {
    padding-right: 0.5rem;
    padding-left: 56px;
  }
}
.MuiAutocomplete-endAdornment {
  @include rtl {
    right: initial;
    left: 0;
  }
}
.MuiFormHelperText-root {
  @include rtl {
    text-align: right;
  }
}
.MuiPickersBasePicker-container {
  .MuiPickersClockPointer-pointer,
  .MuiPickersClock-pin,
  .MuiPickersClockNumber-clockNumberSelected,
  .MuiPickersToolbar-toolbar {
    background-color: $c-primary;
  }
}
// ======================================================== End Plugins Override
// ======================================================== Start Default
//default scrollbar
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #e1e1e1;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #cccccc;
  }
  &:active {
    background: #888888;
  }
}
::-webkit-scrollbar-track {
  background: #666666;
  border: 0px none #ffffff;
  border-radius: 0px;
  &:hover {
    background: #666666;
  }
  &:active {
    background: #333333;
  }
}
::-webkit-scrollbar-corner {
  background: transparent;
}

input[type='checkbox'],
input[type='radio'] {
  margin: 0;
}

[role='img'],
.icons {
  background-repeat: no-repeat;
  display: inline-flex;
}

.icons {
  height: 16px;
  width: 16px;
  background-position: center;
  background-size: 16px 16px;

  &.i-folder-white {
    background-size: 14px 14px;
  }
}

.mdi {
  line-height: 100%;
  &:before {
    line-height: 100%;
    font-size: 19px;
  }

  &.mdi-chevron-right,
  &.mdi-chevron-left,
  &.mdi-menu-right,
  &.mdi-menu-left,
  &.mdi-chevron-double-right {
    @include rtl {
      transform: scale(-1);
    }
  }

  &.mdi-magnify {
    @include rtl {
      transform: rotateZ(90deg);
    }
  }

  //&.mdi-close {
  //  color: $c-red;
  //}
}

.container {
  width: 100%;
  max-width: 100%;
  padding: 0;
  display: flex;
  flex: 1 1 auto;
}

.content-wrapper {
  width: 100%;
}

#root {
  min-height: 100%;
}
::placeholder {
  font-size: $fz-13px;
}
// ======================================================== End Default
// ======================================================== Start Global Assets
// Start Buttons
.btns.MuiButtonBase-root {
  border-radius: 30px;
  min-height: 26px;
  line-height: 100%;
  min-width: 100px;
  margin: 0 0.25rem;
  padding: 0.3rem 0.5rem;
  text-transform: initial;
  color: $c-primary;

  &.theme-solid {
    min-width: 150px;
    height: 30px;
    font-size: 14px;
    background-color: $c-secondary;
    color: $c-white;

    &.bg-cancel {
      background-color: $c-gray-default;
      color: $c-primary;

      .MuiButton-label {
        color: $c-primary;
      }
    }
  }

  &.theme-outline {
    color: $c-secondary;
    border: 1px solid $c-secondary;
  }

  &.theme-menu {
    color: $c-gray-primary;
    width: 100%;
    border-radius: 0;
    justify-content: flex-start;

    &:not([disabled]) {
      &:hover {
        background-color: $c-gray-lighter;
      }
    }
  }

  &.theme-wide {
    min-height: 66px;
    color: $c-gray-primary;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    justify-content: flex-start;
    align-items: center;
    border-radius: 0 0 1rem 1rem;
    position: relative;
    top: -5px;

    .texts-large {
      color: $c-black;
      font-weight: 500;
    }

    .texts-small {
      font-size: 10px;
      text-align: initial;
    }

    &:not([disabled]) {
      &:hover {
        background-color: $c-gray-lighter;
      }
    }
  }

  &.theme-transparent {
    background-color: transparent;
    border-radius: 0.5rem;
    color: $c-secondary;

    .MuiButton-label {
      color: $c-secondary;
    }
  }

  &:disabled {
    background-color: $c-gray-secondary !important;
    cursor: not-allowed;
    pointer-events: initial;
    color: $c-white !important;
  }
}

.btns-icon.MuiButtonBase-root {
  height: 24px;
  width: 24px;
  min-width: 24px;
  max-width: 24px;
  border-radius: 100%;
  color: $c-primary;
  padding: 0.2rem;

  .mdi {
    &::before {
      font-size: 16px;
    }
  }

  &.theme-outline {
    border: 1px solid $c-gray-light;
    color: $c-gray-light;

    &:not([disabled]):hover {
      background-color: $c-gray-lightest;
      color: $c-white;
    }

    &.c-blue-lighter {
      border-color: $c-blue-lighter;
      color: $c-blue-lighter;

      &:not([disabled]):hover {
        background-color: $c-blue-lighter;
        color: $c-white;
        border-color: $c-white;
      }
    }

    .mdi {
      &::before {
        font-size: 24px;
      }
    }
  }

  &.theme-outline-dark {
    border: 1px solid $c-gray-default;
    color: $c-black;
    background-color: $c-white;

    .mdi {
      &::before {
        font-size: 24px;
      }
    }
  }

  &.btns-small {
    width: 24px;
    height: 24px;
    min-width: 24px;
    max-width: 24px;

    .mdi {
      &:before {
        font-size: 18px;
      }
    }
  }

  &.theme-transparent {
    background-color: transparent;
    color: $c-gray-dark;

    .mdi {
      color: $c-gray-dark;

      &::before {
        font-size: 24px;
      }
    }

    &[disabled] {
      background-color: transparent !important;
      color: $c-gray-light;
    }
  }

  &.theme-action {
    background-color: $c-black-light;
    color: $c-white;

    &:hover {
      background-color: $c-primary;
    }

    &:focus,
    &:active,
    &.active {
      &.bg-phone {
        background-color: $c-secondary;
      }

      &.bg-account {
        background-color: $c-primary;
      }

      &.bg-whatsapp {
        background-color: $c-green-dark;
      }

      &.bg-email {
        background-color: $c-warning;
      }
      &.c-phone {
        color: $c-secondary;
      }

      &.c-account {
        color: $c-primary;
      }

      &.c-whatsapp {
        color: $c-green-dark;
      }

      &.c-email {
        color: $c-warning;
      }
    }
  }

  &.theme-solid {
    background-color: $c-primary;
    color: $c-white;
  }

  img {
    width: 100%;
  }

  &:disabled {
    background-color: $c-gray-secondary !important;
    cursor: not-allowed;
    pointer-events: initial;
    color: $c-white !important;
  }
}

.btns-menu {
  height: 53px;
  width: 57.5px;
}

.btns-tag.MuiButtonBase-root {
  background-color: transparent;
  height: 100%;
  min-height: 100%;
  padding: 0;
  width: 1rem;
  min-width: 1rem;
  transform: rotateZ(-48deg);
  height: 1rem;

  .MuiButton-label {
    color: $c-primary;
  }
}

// End Buttons
// Start Inputs
.input-wrapper.MuiFormControl-root {
  @include d-inline-flex-v-center;
  flex-direction: row !important;
  width: 100%;
}

.phones-wrapper {
  width: 100%;
  .react-tel-input .form-control:not(.invalid-number):focus {
    border-color: $c-primary;
  }

  .react-tel-input .form-control:not(.invalid-number):hover {
    border-color: $c-primary;
  }

  .react-tel-input .form-control {
    width: 100%;
    border-color: $c-gray-default;
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
    @include d-flex-v-center;
    border-radius: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-left: 3rem !important;
    &:hover {
      &:not([disabled]) {
        border-color: $c-gray-light;
      }
    }

    @include rtl {
      padding-right: 3rem !important;
      padding-left: 0.5rem !important;
    }

    font-size: $fz-default;
  }
  :not(.phone-error) {
    .form-control {
      &:focus {
        &:not([disabled]) {
          border-color: $c-primary !important;
          box-shadow: 0 0 2px 2px rgba($c-primary, 0.3);
        }
      }
    }
  }
  .react-tel-input .selected-flag .arrow {
    border-top: 4px solid $c-gray-default;
    @include rtl {
      left: 0;
      right: 29px;
    }
  }

  .react-tel-input .selected-flag {
    padding-left: 0.5rem;
    border-radius: 3px 0 0 3px;
    @include rtl {
      padding-left: 0;
      padding-right: 0.5rem;
      border-radius: 0 3px 3px 0;
    }
  }
  .react-tel-input {
    &.phone-error {
      .form-control {
        border-color: $c-danger !important;
        box-shadow: 0 0 2px 2px rgba($c-danger, 0.5) !important;
        transition-property: box-shadow, border-color;
        transition-delay: 0.3s;
        transition-timing-function: linear;
      }
    }
  }
  .flag-dropdown {
    &::before {
      content: initial !important;
    }
    .country-list {
      width: 100%;
      min-width: 270px;
    }
    .search {
      padding: 0.5rem 1rem;
    }
  }
  .phone-error-wrapper {
    width: 100%;
    margin: 0.25rem 0;
    color: $c-warning;
    // padding-top: 0.25rem;
  }
  .phone-input-wrapper {
    position: relative;
    @include d-flex;
  }
  .loader {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
  }
}

.inputs {
  color: $c-primary;
  width: 100%;
  .mdi {
    color: $c-gray-dark;
  }
  .MuiInputBase-adornedStart {
    .MuiInputBase-input {
      padding: 0.5rem 0.25rem !important;
    }
  }
  .MuiInputBase-input {
    color: $c-primary;
    font-family: Poppins, serif;

    @include rtl {
      padding-right: 0.25rem;
    }

    &::placeholder {
      color: $c-gray-default;
      opacity: 1;
    }
  }

  &.theme-transparent {
    &.MuiInput-underline {
      &:before {
        display: none;
      }

      &.Mui-focused {
        &::after {
          display: none;
        }
      }
    }
  }

  &.theme-solid {
    @include d-inline-flex;
    border: 1px solid $c-gray-default;
    background-color: $c-white;
    border-radius: 2rem;
    font-size: $fz-default;
    width: 100%;
    &.filter-changes {
      .MuiInputBase-input {
        min-width: 135px;
        color: $c-secondary;
        font-weight: bold;
        text-align: center;
        &::placeholder {
          color: $c-secondary;
          // font-size: $fz-16px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
    .MuiFormLabel-root {
      color: $c-gray-dark !important;
    }

    .MuiInputBase-root {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 2rem;
    }

    .MuiInputBase-input {
      color: $c-gray-dark;

      &::placeholder {
        color: $c-gray-primary;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: $c-gray-primary;
    }

    .MuiInputLabel-outlined {
      transform: translate(14px, -50%) scale(1);
      top: 50%;

      @include rtl {
        left: initial;
        right: 25px;
      }

      &.MuiInputLabel-shrink {
        transform: translate(14px, -50%) scale(0.75);
        top: initial;
      }
    }

    .MuiInput-underline::before {
      display: none;
    }
  }
  &.theme-solid-border {
    width: 100%;
    .MuiInputBase-root {
      border-radius: 0.5rem !important;
      background-color: $c-white;
      border: 1px solid $c-gray-default;
      &:hover {
        &:not([disabled]) {
          border-color: $c-gray-light;
        }
      }

      &.Mui-focused:not([disabled]) {
        border-color: $c-primary !important;
        box-shadow: 0 0 2px 2px rgba($c-primary, 0.3);
      }
      &.Mui-error {
        &::after {
          display: none;
        }
        &:not([disabled]) {
          border-color: $c-danger !important;
          box-shadow: 0 0 2px 2px rgba($c-danger, 0.5);
          transition-property: box-shadow, border-color;
          transition-delay: 0.3s;
          transition-timing-function: linear;
        }
      }
    }

    .MuiInputBase-input {
      padding: 0.5rem;
      color: $c-gray-primary;

      &::placeholder {
        color: $c-gray-default;
      }
    }

    .MuiInputBase-multiline {
      padding: 0.25rem;

      textarea {
        padding: 0.25rem;
      }
    }

    .MuiInput-underline:before {
      border-bottom: 2px solid $c-gray-light;
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid $c-primary;
    }
    .MuiInput-underline::before,
    .MuiInput-underline.Mui-focused:after {
      display: none;
    }
  }

  &.MuiFormControl-root {
    color: $c-primary;

    &.theme-underline {
      .MuiInputBase-input {
        padding: 0.5rem 1rem;
      }

      .MuiInputLabel-formControl {
        left: 1rem;
        color: $c-gray-secondary !important;

        @include rtl {
          left: initial;
          right: 1rem;
        }

        &.MuiInputLabel-shrink {
          color: $c-blue-lighter !important;
        }
      }

      .MuiFormLabel-root {
        font-size: 16px;
      }
    }
  }
  .MuiInputBase-root {
    &.Mui-disabled {
      color: $c-gray-secondary;

      cursor: not-allowed;
      path {
        fill: $c-gray-secondary;
      }
      .form-control,
      .MuiInput-input {
        border-color: $c-gray-secondary !important;
        color: $c-gray-secondary !important;
        cursor: not-allowed;
      }
    }
  }
}

// End Inputs
// Start Dialogs
.MuiDialog-root {
  .MuiDialog-container {
    .MuiDialog-paper {
      background-color: $c-white;

      &.MuiPaper-rounded {
        border-radius: 17px;
      }
    }

    .MuiDialogActions-root {
      padding: 0.5rem;

      .btns {
        margin: 0 0.5rem 0.5rem;
      }
    }
  }
}
.dialog {
  .dialog-btn-close {
    position: absolute;
    z-index: 99;
    right: 10px;
    top: 10px;
    @include rtl {
      right: unset;
      left: 10px;
    }
  }
}
// End Dialogs
// Start Links
.links {
  text-decoration: underline;
  color: $c-blue-lighter;

  &:hover {
    text-decoration: underline;
    color: $c-primary;
  }
}

// End Links
// Start Badges
.badges {
  .MuiBadge-badge {
    background-color: $c-red;
    color: $c-white;

    &.MuiBadge-anchorOriginTopRightRectangle {
      top: -25%;

      @include rtl {
        left: 0;
        right: initial;

        &:not(.MuiBadge-invisible) {
          transform: scale(1) translate(-50%, -50%);
        }
      }
    }

    &.MuiBadge-badge {
      height: 12px;
      min-width: 12px;
      padding: 0.1rem;
      font-size: $fz-10px;
      line-height: 12px;
    }
  }
}
.MuiPickersCalendar-week {
  .MuiBadge-badge {
    &.MuiBadge-anchorOriginTopRightRectangle {
      top: 0.2rem;
      right: 0.5rem;
    }
  }
}
// End Badges
// Start Avatars
.avatars-wrapper {
  &.MuiAvatar-root {
    height: 60px;
    width: 60px;

    &.theme-small {
      width: 100%;
      height: 100%;
      font-size: 100%;
      min-width: 30px;
      min-height: 30px;
    }
    background-color: $c-gray-dark;
    color: $c-white;
  }
}

// End Avatars
// Start Open Close
.animated-open-close {
  padding-top: 20px;
  position: relative;
  display: inline-flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-width: 35px;
  max-width: 35px;
  box-shadow: inset 6px 0 3px 0 rgba(0, 0, 0, 0.1);
  @include phones-max {
    box-shadow: inset 0px 0 0px 0 rgba(0, 0, 0, 0);
}
  transition-property: max-width, min-width;
  transition-duration: 0.3s;
  transition-timing-function: linear;

  @include rtl {
    box-shadow: 3px 0 6px 0px rgba(0, 0, 0, 0.1);
  }

  .open-close-content {
    position: sticky;
    top: 20px;
    border-radius: 10px;
    overflow: hidden;
    padding: 0 10px 10px;
    max-width: 0;
    opacity: 0;
  }

  .open-button {
    position: sticky;
    top: 5px;
    right: 0;
    z-index: 1;
    transform: translateX(50%);
    background-color: $c-white;
    top: 15px;

    @include rtl {
      left: 0;
      right: initial;
      transform: translateX(-50%);
    }
  }

  &.is-open {
    z-index: 1;
    max-width: 100%;
    min-width: 300px;

    .open-close-content {
      max-width: 300px;
      opacity: 1;
      transition-property: max-width, opacity;
      transition-delay: 0.3s;
    }
  }

  &.on-left {
    min-height: 660px;

    .open-button {
      right: unset;
      left: 6px;
      transform: translateX(-50%);

      @include rtl {
        right: 0px;
        left: initial;
        transform: translateX(50%);
      }
    }
  }
}

.animated-open-left {
  border: 1px solid #e6e6e6;
  position: absolute;
  display: inline-flex;
  flex: 1 1 auto;
  left: -100px;
  opacity: 0;
  transition-property: max-width, min-width, opacity;
  transition-duration: 0.1s;
  transition-timing-function: linear;
  border-radius: 10px;
  @include rtl {
    left: 100px;
  }

  .open-close-left {
    border-radius: 10px;
    overflow: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    max-width: 0;
    opacity: 0;
  }
}

// End Open Close
// Start Cards
.cards {
  display: inline-flex;
  flex-direction: column;
  flex: 0 1 auto;
  min-width: 300px;
  min-height: 150px;
  box-shadow: $bs-secondary;
  background-color: $c-white;
  border-radius: 0.75rem;

  .card-header {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: $bg-gradient-primary-right;
    border-radius: 0.75rem 0.75rem 0 0;
    font-size: $fz-11px;
    color: $c-white-dark;
    min-height: 60px;
    padding: 0.5rem;

    .texts-large {
      color: $c-white;
      font-size: $fz-default;
      font-weight: 500;
    }
  }

  .card-content {
    padding: 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  .card-footer {
    padding: 0.25rem;
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    flex-wrap: wrap;
    border-radius: 0 0 0.75rem 0.75rem;
  }
}

// End Cards
// Start Separators
.separator-h {
  position: relative;
  min-height: 1px;
  width: 100%;

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    width: 100%;
    height: 1px;
    background-color: $c-gray-default;
  }

  &.s-gray-primary {
    &:after {
      background-color: $c-gray-primary;
    }
  }
}

.separator-v {
  position: relative;
  height: 100%;
  min-width: 1px;

  &.p-25px {
    height: calc(100% + 25px);
  }

  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: ' ';
    width: 1px;
    height: 100%;
    background-color: $c-gray-lighter;

    @include rtl {
      left: initial;
      right: 0;
    }
  }

  &.s-gray-primary {
    &:after {
      background-color: $c-gray-primary;
    }
  }

  &.s-primary {
    &:after {
      background-color: $c-primary;
    }
  }
  &.s-gray-secondary {
    &:after {
      background-color: $c-gray-secondary;
    }
  }

  &.s-reverse {
    &:after {
      right: 0;
      left: initial;

      @include rtl {
        left: 0;
        right: initial;
      }
    }
  }

  &.s-h-25px {
    min-height: 25px;
  }
}

// End Separators
// Start Collapses
.collapses {
  &.absolute-collapse {
    position: absolute;
    right: 0;
    z-index: 99999;

    @include phones-max() {
      position: fixed;
      right: 10px;
      max-width: 300px;
      margin-top: 15px;

      @include rtl {
        right: initial;
        left: 10px;
      }
    }

    @include rtl {
      right: initial;
      left: 0;
    }

    &.is-centered {
      right: unset !important;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}

// End Collapses
// Start Circles
.circle-ripple {
  height: 38px;
  width: 38px;
  min-width: 38px;
  border-radius: 100%;
  background-color: $c-lavender;
  color: $c-info;
  @include d-inline-flex-center;
}

// End Circles
// Start Calendar
.calendars {
  position: relative;
  z-index: 1;

  .MuiPickersStaticWrapper-staticWrapperRoot {
    background-color: $c-white;
    border-radius: 5px;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.15);
  }

  .MuiPickersCalendarHeader-iconButton {
    background-color: transparent;
  }

  .MuiIconButton-root {
    padding: 0.25rem;
    min-height: 30px;
    min-width: 30px;
  }

  &.hide-header {
    .MuiPickersDatePickerRoot-toolbar {
      display: none;
    }
  }

  &.small-calender {
    .MuiPickersStaticWrapper-staticWrapperRoot {
      min-width: initial;
      display: inline-block;
      width: 100%;
    }

    .MuiPickersBasePicker-pickerView {
      max-width: initial;
      min-width: initial;
    }

    .MuiPickersBasePicker-pickerView {
      min-height: initial;
      max-width: initial;
      padding: 0.5rem;

      .MuiPickersCalendar-transitionContainer {
        min-height: 180px;
      }
    }

    .MuiIconButton-root {
      height: 30px;
      width: 30px;
    }

    .MuiPickersCalendarHeader-dayLabel {
      width: 30px;
    }
  }
}

// End Calendar
// Start Datepicker
.picker-wrapper {
  &.MuiFormControl-root {
    margin-bottom: 0;
    margin-top: 0;
  }

  .MuiButtonBase-root {
    padding: 0.25rem;
  }

  &.hide-button {
    .MuiButtonBase-root {
      display: none;
    }
  }
}

// End Datepicker
// Start Breadcrumb
.breadcrumb-wrapper {
  padding: 1rem 2rem 1rem;
  padding: 2.5rem 2rem 1rem;
  background-color: $c-white !important;

  .MuiBreadcrumbs-separator {
    color: $c-gray-light;
  }

  .breadcrumb-link {
    color: $c-gray-light;
    text-decoration: none;
    &.active {
      color: $c-primary;
    }
  }
}

// End Breadcrumb
// Start Tabs
.tabs-wrapper {
  &.MuiTabs-root {
    min-height: 35px;
  }

  .MuiTab-root {
    min-height: 35px;
    font-size: $fz-10px;
    padding: 0.25rem 0.5rem;
    text-transform: none;
  }
}

// End Tabs
// Start Table Actions Tooltip
.table-actions-wrapper {
  // height: 40px !important;
  margin-right: -7px;
  position: fixed;
  min-width: 70px;
  background-color: $c-primary;
  border-radius: 1rem 0 0 1rem;
  z-index: 1;
  @include d-inline-flex-center;
  padding: 0 0;

  .btns-icon {
    margin: 0 0.25rem;
  }

  // transition: opacity 0.2s linear;
  @include rtl {
    border-radius: 0 1rem 1rem 0;
  }
}

// End Table Actions Tooltip
// Start Table
.table-wrapper {
  &.MuiTable-root {
    .MuiTableCell-root {
      @include rtl {
        text-align: right;
      }
    }

    .MuiTableHead-root {
      .MuiTableRow-head {
        .MuiTableCell-head {
          background-color: $c-primary;
          color: $c-white;
          font-size: $fz-table-header;

          &:first-child {
            border-top-left-radius: 1rem;

            @include rtl {
              border-top-right-radius: 1rem;
              border-top-left-radius: 0;
            }
          }

          &:last-child {
            border-top-right-radius: 1rem;

            @include rtl {
              border-top-right-radius: 0;
              border-top-left-radius: 1rem;
            }
          }
        }
      }
    }

    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-body {
          height: 27px;
          background-color: $c-white;
          color: $c-gray-primary;
          font-size: $fz-table-body;
          &:first-child {
            border-left: 1px solid $c-gray-default;
            @include rtl {
              border-right: 2px solid $c-gray-default;
              border-left: initial;
            }
          }
          &:last-child {
            border-right: 2px solid $c-gray-default;
            @include rtl {
              border-left: 1px solid $c-gray-default;
              border-right: initial;
            }
          }
        }

        &:nth-child(even) {
          .MuiTableCell-body {
            background-color: $c-gray-lightest;
          }
        }

        &:hover,
        &.table-row-overlay {
          .MuiTableCell-body {
            background-color: $c-blue-lightest;
          }
        }
      }
    }

    .MuiTableFooter-root {
      .MuiTableRow-footer {
        .MuiTableCell-footer {
          background-color: $c-blue-lighter;
          color: $c-white;
          font-size: $fz-table-footer;
        }
      }
    }
  }
}

.pagination-wrapper {
  border: 1px solid #e6e6e6;
  background-color: $c-white;
  border-top: 2px solid $c-gray-lighter;
  color: $c-gray-primary;
  border-radius: 0 0 1rem 1rem;

  .MuiTypography-root {
    color: $c-gray-primary;
  }

  .MuiTablePagination-toolbar {
    padding: 0.25rem 0.5rem;
    min-height: unset;
  }

  .select-wrapper {
    background-color: $c-gray-lightest;
    border-radius: 1rem;
    margin: 0 0.5rem;
    overflow: hidden;
    min-width: 70px;
    color: $c-primary;
    path {
      fill: $c-primary;
    }

    .MuiSelect-select {
      width: 100%;
      @include d-flex-center;

      @include rtl {
        padding-left: 24px;
        padding-right: 0.5rem;
      }
    }

    .MuiSelect-icon {
      @include rtl {
        right: initial;
        left: 0;
      }
    }
  }

  .btns-icon {
    &.theme-transparent {
      color: $c-primary;
    }
  }
}

.MuiTablePagination-root {
  .MuiTablePagination-actions {
    svg {
      @include rtl {
        transform: scaleX(-1);
      }
    }
  }
}

.editProfileBtn {
  width: 40px !important;
  height: 30px !important;
}

// End Table
// Start Select
.select-wrapper {
  &.MuiFormControl-root {
    min-height: 26px;
    background-color: $c-white;
    min-width: 150px;

    .selects {
      width: 100%;
      .MuiSelect-select {
        min-height: 26px;
        padding-top: 0;
        padding-bottom: 0;
        padding-right: 24px;
        padding-left: 0.5rem;
        @include d-flex;
        @include flex-v-center;

        @include rtl {
          padding-left: 24px;
          padding-right: 0.5rem;
        }
        &.Mui-disabled {
          background-color: $c-gray-secondary !important;
          cursor: not-allowed;
          pointer-events: initial;
          color: $c-white !important;
        }
      }

      .MuiSelect-icon {
        @include rtl {
          right: initial;
          left: 0;
        }
      }

      color: $c-primary;
    }

    &.theme-solid {
      border-radius: 2rem;
      width: 100%;
      .MuiInput-underline.Mui-error:after {
        display: none;
      }
      .selects {
        width: 100%;
        &.Mui-error {
          .MuiSelect-select:not([disabled]):not(.Mui-disabled) {
            border-color: $c-error !important;
            box-shadow: 0 0 2px 2px rgba(191, 60, 60, 0.5);
          }
        }
        .MuiSelect-select {
          border-radius: 0.5rem !important;
          background-color: $c-white;
          border: 1px solid $c-gray-default;
          &:hover:not([disabled]):not(.Mui-disabled) {
            border-color: $c-gray-light;
          }

          &.Mui-focused:not([disabled]) {
            border-color: $c-primary !important;
            box-shadow: 0 0 2px 2px rgba($c-primary, 0.3);
          }
          // box-shadow: inset 5px 1px 5px 0px rgba(0, 0, 0, 0.16);
        }

        .MuiSelect-icon:not(.Mui-disabled) {
          fill: $c-primary;
        }
        &.MuiInput-underline::before,
        &.MuiInput-underline.Mui-focused:after {
          display: none;
        }
      }
      &.filter-changes {
        .selects {
          .MuiSelect-root {
            border-radius: 1rem !important;
            min-height: 30px;
            justify-content: center;
            font-weight: bold;
            color: $c-secondary;
          }
        }
      }
    }

    &.theme-underline {
      border-radius: 0;
      width: 100%;

      .selects {
        .MuiSelect-select {
          color: $c-gray-primary;
        }

        .MuiSelect-icon:not(.Mui-disabled) {
          fill: $c-primary;
        }
      }

      &.select-filled {
        .selects {
          .MuiSelect-select {
            color: $c-primary;
          }
        }
      }

      .MuiInput-underline:not(.Mui-disabled)::before {
        border-bottom-color: $c-gray-default;

        &.Mui-focused {
          border-bottom-color: $c-primary;
        }

        &:hover {
          border-bottom-color: $c-gray-primary;
        }
      }
    }
  }
}

// End Select
// Start Autocomplete
.MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
  padding: 0.5rem !important;
}
.MuiAutocomplete-popupIndicator {
  height: 100%;
  margin-right: -6px;
  .MuiSvgIcon-root {
    fill: $c-primary;
  }
  @include rtl {
    margin-left: -6px;
  }
}
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
  padding: 5.5px 4px;
}
.autocomplete-wrapper {
  @include d-inline-flex;
  @include flex-v-center;
  width: 100%;
  padding: 0 0.5rem;

  .autocomplete {
    @include d-flex;
    &::not(.theme-solid-border) {
      background-color: $c-white;
      box-shadow: inset 6px 0 3px 0 rgba(0, 0, 0, 0.16);
      border-radius: 2rem;
      max-width: calc(100% - 45px);
    }
    font-size: $fz-default;

    .MuiFormLabel-root {
      color: $c-gray-dark !important;
    }

    .MuiInputBase-root {
      padding-top: 0;
      padding-bottom: 0;
      border-radius: 2rem;
    }

    .MuiInputBase-input {
      color: $c-gray-dark;

      &::placeholder {
        color: $c-gray-default;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: $c-gray-primary;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      @include rtl {
        padding-left: 65px;
        padding-right: 0.5rem;
      }

      .MuiAutocomplete-endAdornment {
        @include rtl {
          left: 9px;
          right: initial;
        }
      }
    }

    .MuiInputLabel-outlined {
      transform: translate(14px, -50%) scale(1);
      top: 50%;

      @include rtl {
        left: initial;
        right: 25px;
      }

      &.MuiInputLabel-shrink {
        transform: translate(14px, -50%) scale(0.75);
        top: initial;
      }
    }

    .MuiChip-root {
      height: 22px;
    }

    .MuiInput-underline::before {
      display: none;
    }
  }
}

// End Autocomplete
// Start Radio Groups
.radio-groups-wrapper.MuiFormControl-root {
  padding: 0;

  .radio-group-wrapper {
    flex-direction: row;

    .radio-wrapper {
      .radio-icon {
        border: 2px solid $c-gray-default;
        color: $c-gray-default;
        @include d-inline-flex-center;
        padding: 1px;
        border-radius: 100%;

        &::before {
          font-size: 15px;
          transform: translate(1%);
          @include rtl {
            transform: translate(-3%);
          }
        }
      }

      &.Mui-checked {
        .radio-icon {
          border-color: $c-secondary;
          color: $c-secondary;
        }
      }

      .MuiSvgIcon-root {
        color: $c-gray-primary;
      }
    }

    .MuiFormControlLabel-label {
      color: $c-gray-primary;
    }
  }

  .MuiFormLabel-root {
    margin: 0 0 0.25rem;
    border-bottom: none;
    color: $c-gray-primary !important;
  }
}

// End Radio Groups
// Start Advance Search
.advance-search-btn {
  position: absolute;
  bottom: 130%;
  right: 0;

  @include rtl {
    left: 0;
    right: initial;
  }
}

.advance-search-wrapper {
  @include d-flex-column;
  padding: 0 0.5rem;
}

// End Advance Search
// Start BXSlider
.bxslider-wrapper {
  @include d-flex;
  @include flex-v-center;
  width: 100%;
  margin-bottom: 1rem;

  .bxslide-arrow-decrement-wrapper,
  .bxslide-arrow-increment-wrapper {
    padding: 0.5rem;
  }

  .bxslider-items-wrapper {
    @include d-flex;
    width: 100%;
    min-height: 90px;
    justify-content: center;

    .bxslider-item {
      padding: 0 0.5rem;
      @include d-inline-flex;
      @include flex-v-center;
    }
  }
}

// End BXSlider
// Start Views Cards
.card-wrapper {
  @include d-inline-flex;
  width: 20%;
  min-width: 270px;
  padding: 0 0.5rem 1rem;

  @include laptops-xl-max {
    width: 20%;
  }

  @include laptops-l-max {
    width: 33.3333%;
  }

  @include laptops-max {
    width: 50%;
  }

  @include phones-max {
    width: 100%;
  }

  &.is-expanded {
    width: 33.33333%;

    @include laptops-xl-max {
      width: 50%;
    }

    @include laptops-l-max {
      width: 50%;
    }

    @include laptops-max {
      width: 100%;
    }
  }
}

.cards-link {
  width: 100%;
  cursor: pointer;
}
.cards-wrapper {
  @include d-flex-column;
  cursor: pointer;
  border-radius: 1rem;
  border: 1px solid $c-gray-default;
  color: $c-gray-primary;
  .cards-header {
    @include d-flex-column;
    padding: 0.5rem;
  }
  .cards-body {
    position: relative;
    @include d-flex-column;
    height: 100%;
    .item-wrapper {
      max-width: calc(100% - 45px);
      width: 100%;
    }
  }
  .cards-footer {
    @include d-flex-column;
  }
  .item-wrapper {
    @include d-flex;
    flex-wrap: wrap;
    .btns {
      border-color: $c-gray-default;
    }
    .item-header {
      @include d-inline-flex-center;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }
    .item-body {
      @include d-inline-flex-center;
      margin-bottom: 0.25rem;
      padding: 0 0.25rem;
      &.new-line {
        @include d-flex;
        padding: 0 0.5rem;
      }
    }
    &.actions {
      @include d-flex-column;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      max-width: 45px;
      @include rtl {
        right: initial;
        left: 0;
      }
      .btns {
        &:last-child {
          margin-bottom: 0;
        }
        margin: 0 0 0.5rem;
        min-width: initial;
        width: 45px;
        border-radius: 1rem 0 0 1rem;
        @include rtl {
          border-radius: 0 1rem 1rem 0;
        }
      }
    }
  }
}
.cards-views {
  &.theme-contacts {
    @include d-flex-column-v-center;
    box-shadow: $bs-secondary;
    background-color: $c-white;
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    max-height: 110px;
    transition-property: max-height;
    transition-duration: 0.3s;
    transition-timing-function: linear;
    position: relative;

    .img-wrapper {
      border-radius: 0.75rem;
      overflow: hidden;
      height: 75px;
      min-height: 75px;
      max-height: 75px;
      margin-bottom: 0.25rem;
      width: 100%;
      text-align: center;
      transition-property: max-height, height;
      transition-duration: 0.5s;
      transition-timing-function: linear;

      .contact-img {
        width: 100%;
        max-width: 100%;

        &.is-default {
          width: 85px;
          height: 85px;
        }
      }
    }

    .card-content {
      border-radius: 0.75rem 0.75rem 0 0;
      padding: 0 0 1rem;
      @include d-flex-column-v-center-h-start;
      width: 100%;

      .contact-name {
        color: $c-primary;
      }

      .contact-name,
      .actions-wrapper {
        margin-top: 0.25rem;
      }

      .actions-wrapper {
        max-height: 0;
        transition-property: max-height;
        transition-duration: 0.3s;
        transition-timing-function: linear;
        white-space: nowrap;
        overflow: hidden;

        .btns-icon {
          margin: 0 0.25rem;
        }
      }
    }

    .tag-wrapper {
      position: absolute;
      top: calc(100% - 9px);
      background-color: $c-white;
      transform: rotateZ(48deg);
    }

    &.is-open {
      max-height: 100%;

      .img-wrapper {
        height: 100%;
        max-height: 100%;

        .contact-img {
          &.is-default {
            width: 75px;
          }
        }
      }

      .card-content {
        min-height: 65px;
        overflow: hidden;

        .actions-wrapper {
          max-height: 100%;
        }
      }
    }
  }

  &.theme-details-contacts {
    @include d-inline-flex-column;
    box-shadow: $bs-secondary;
    width: 100%;
    height: 100%;
    background-color: $c-white;
    color: $c-gray-primary;
    border-radius: 1.1rem;
    position: relative;

    .card-content {
      display: grid;
      grid-template-columns: [col-1] 75px [col-2] auto;
      grid-template-rows: [row-1] auto [row-2] auto [row-3] auto;
      width: 100%;
      height: 100%;
      border-radius: 0.75rem 0.75rem 0 0;

      .img-wrapper {
        grid-area: row-1 / col-1 / row-3 / col-1;
        @include d-flex-center;

        .card-img {
          width: 55px;
          height: 55px;
          border-radius: 1.1rem;
          background-color: $c-gray-lightest;
        }
      }

      .card-price {
        @include d-inline-flex-center;
        color: $c-secondary;
        padding: 1rem 1rem 0.5rem;
        font-size: $fz-15px;
      }

      .card-tooltip {
        position: absolute;
        top: 40px;
        left: 0;
        background-color: $c-danger;
        color: $c-white;
        padding: 0.25rem 0.5rem;
        border-radius: 0 1rem 1rem 0;

        @include rtl {
          left: initial;
          right: 0;
          border-radius: 1rem 0 0 1rem;
        }
      }

      .card-name {
        grid-area: row-1 / col-2 / row-1 / col-2;
        @include d-flex;
        @include flex-v-center;
        padding: 1rem 1rem 0.5rem;
        font-size: $fz-15px;
        color: $c-primary;
        overflow: hidden;
        max-width: calc(100% - 25px);

        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .actions-wrapper {
        grid-area: row-2 / col-2 / row-2 / col-2;
        @include d-flex;
        @include flex-v-center;
        margin-bottom: 0.5rem;
        padding: 0 0.75rem;

        .action-item {
          margin: 0 0.25rem;
        }
      }

      .card-list-wrapper {
        grid-area: row-3 / col-2 / row-3 / col-2;
        overflow: hidden;
        padding: 0 0.5rem;

        .card-list-item {
          @include d-flex-v-center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding: 0 0.5rem 0.2rem;
          span:first-child {
            margin: 0 0.25rem;
          }
        }
      }

      .tag-curve-wrapper {
        position: absolute;
        top: 0;
        right: 0;

        @include rtl {
          right: initial;
          left: 0;
          transform: scaleX(-1);
        }
      }

      .side-actions-wrapper {
        // @include d-flex-column-v-center;
        // @include flex-h-end;
        display: none;
      }
    }

    .card-footer {
      @include d-flex;
      @include flex-h-between;
      flex-wrap: wrap;
      padding: 0.5rem 0.5rem 1rem;
      width: 100%;

      .progresses-wrapper {
        padding: 0 0.5rem;
        margin-bottom: 1rem;
        order: 1;
      }

      .date-wrapper,
      .time-wrapper {
        @include d-inline-flex-center;
        white-space: nowrap;

        span {
          margin: 0 0.25rem;
        }
      }

      .date-wrapper {
        order: 3;
      }

      .time-wrapper {
        order: 2;
      }
    }

    &.is-expanded {
      .card-content {
        grid-template-columns: [col-1] 75px [col-2] auto [col-3] auto [col-4] 55px;
        grid-template-rows: [row-1] auto [row-2] auto;

        @include laptops-max {
          grid-template-rows: [row-1] auto [row-2] auto [row-3] auto;
        }

        .img-wrapper {
          margin-top: 1rem;
          // grid-row-start: row-1;
          // grid-row-end: row-2;
          grid-area: row-1 / col-1 / row-2 / col-1;

          @include laptops-max {
            grid-area: row-1 / col-1 / row-3 / col-1;
          }
        }

        .card-name {
          padding: 0.25rem 0.5rem;
          margin-bottom: 0.5rem;

          @include laptops-max {
            grid-area: row-1 / col-2 / row-1 / col-4;
          }
        }

        .actions-wrapper {
          grid-area: row-1 / col-3 / row-1 / col-4;
          padding: 0.25rem 0.5rem;

          @include laptops-max {
            grid-area: row-2 / col-2 / row-2 / col-4;
          }
        }

        .card-list-wrapper {
          grid-area: row-2 / col-2 / row-2 / 4;
          display: inline-grid;
          grid-template-columns: auto auto;

          @include laptops-max {
            grid-area: row-3 / col-2 / row-3 / 4;
            grid-template-columns: auto;
          }

          .card-list-item {
            .item-value {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .side-actions-wrapper {
          @include d-flex-column-center;
          grid-area: row-1 / col-4 / 4 / col-4;
          margin-top: 2.5rem;

          @include laptops-max {
            grid-area: row-1 / col-4 / 4 / col-4;
          }

          .side-action-item {
            padding: 0 0.25rem;
            margin-bottom: 0.25rem;
          }
        }
      }

      .card-footer {
        flex-wrap: nowrap;

        @include phones-max {
          flex-wrap: wrap;
        }

        .progresses-wrapper {
          margin-bottom: 0;
          order: 2;

          @include phones-max {
            order: 1;
          }
        }

        .date-wrapper {
          order: 3;
        }

        .time-wrapper {
          order: 1;
        }
      }
    }

    &.theme-details-properties {
      .card-content {
        grid-template-columns: [col-1] auto [col-2] auto;

        // grid-template-rows: [row-1] auto [row-2] auto [row-3] auto;
        .img-wrapper {
          grid-area: row-1 / col-1 / row-1 / 3;
          height: 164px;

          .card-img {
            height: 100%;
            width: 100%;
            border-radius: 1rem 1rem 0 0;
          }
        }

        .card-name {
          grid-area: row-2 / col-1 / row-2 / col-2;
        }

        .card-price {
          grid-area: row-2 / col-2 / row-2 / 3;
        }

        .card-list-wrapper {
          grid-area: row-3 / col-1 / row-3 / 3;
        }
      }

      &.is-expanded {
        .card-content {
          grid-template-columns: [col-1] 270px [col-2] auto [col-3] 70px;
          grid-template-rows: [row-1] 64px [row-2] 100px [row-2] auto;

          .img-wrapper {
            grid-area: row-1 / 1 / span 2 / span 1;
            margin-top: 0;

            .card-img {
              border-radius: 1rem 0 1rem 0;

              @include rtl {
                border-radius: 0 1rem 0 1rem;
              }
            }
          }

          .card-name {
            grid-area: row-1 / col-2 / span 1 / span 1;
            padding: 1rem 1rem 0.5rem;
            margin-bottom: 0;
          }

          .card-price {
            grid-area: row-1 / col-3 / span 1 / span 1;
          }

          .card-list-wrapper {
            display: grid;
            grid-template-columns: [col-1] 270px [col-2] auto;
            grid-template-rows: auto auto;
            grid-area: row-2 / col-1 / span 2 / span 2;

            .card-list-item {
              grid-area: auto / col-2 / span 1 / span 1;
            }

            :nth-child(n + 6) {
              grid-area: auto / auto / span 1 / span 1;
            }
          }

          .side-actions-wrapper {
            grid-area: row-2 / col-3 / span 2 / span 1;
            margin-top: 0;

            @include tablets-max {
              grid-area: row-1 / col-4 / 4 / col-4;
            }

            .side-action-item {
              padding: 0 0.25rem;
              margin-bottom: 0.25rem;
            }
          }
        }
      }
    }
  }

  .card-checkbox-wrapper {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;

    &.trl-15px {
      top: 15px;
      right: 15px;
    }

    @include rtl {
      right: initial;
      left: 12px;

      &.trl-15px {
        left: 15px;
      }
    }
  }
}

// End Views Cards
// Start Over Input Texts
.over-input-text-wrapper {
  // .MuiSelect-root {
  //   padding-left: 5rem !important;
  //   @include rtl {
  //     padding-right: 5rem !important;
  //     padding-left: 0.5rem !important;
  //   }
  // }
  .over-input-text {
    @include d-inline-flex-center;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    left: 0.5rem;
    .mdi {
      padding-bottom: 0.1rem;
    }
    @include rtl {
      right: 0.5rem;
      left: initial;
    }
  }
}
// End Over Input Texts
// Start Slider
.slide-images {
  margin-bottom: 1rem;
  position: relative;
  height: 100px;
  width: 100%;
  .slide-image {
    background-color: $c-white;
    position: absolute;
    border-radius: 1rem;
    box-shadow: 0 0 3px 1px rgba($c-black-dark, 0.3);
    width: 100px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      z-index: 999999 !important;
    }
  }
}
// End Slider
// Start Timelines
.timeline-wrapper {
  &.MuiTimeline-alignLeft,
  &.MuiTimeline-alignRight {
    .MuiTimelineItem-root {
      &::before {
        content: initial;
      }
    }
  }
}
// End Timelines
// Start Progresses
.circular-progress-wrapper {
  transform: translateX(-50%);

  @include rtl {
    transform: translateX(50%);
    left: initial !important;
    right: 50%;
  }
}

.progresses-wrapper {
  width: 100%;

  &.nowrap {
    @include d-flex-center;

    .progresses-text {
      @include flex-center;
      padding: 0 0.25rem;
      white-space: nowrap;
      order: 2;
    }

    .progresses {
      width: 100%;
      order: 1;
    }
  }

  .progresses-text {
    @include d-flex-v-center-h-end;
    color: $c-primary;
  }

  .progresses {
    &.MuiLinearProgress-colorPrimary {
      background-color: $c-gray-default !important;
      border-radius: 4px;
      box-shadow: inset 0 0 4px 1px rgba(0, 0, 0, 0.13);

      // box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, 0.16);
      .MuiLinearProgress-bar {
        border-radius: 4px;
      }
    }

    &.bg-primary {
      .MuiLinearProgress-barColorPrimary {
        background-color: $c-primary;
      }
    }

    &.bg-success {
      .MuiLinearProgress-barColorPrimary {
        background-color: $c-success;
      }
    }

    &.bg-warning {
      .MuiLinearProgress-barColorPrimary {
        background-color: $c-warning;
      }
    }

    &.bg-danger {
      .MuiLinearProgress-barColorPrimary {
        background-color: $c-danger;
      }
    }
  }
}

// End Progresses
// Start Form Controls Label
.form-control-label.MuiFormControlLabel-root {
  // margin-left: 0;
  // margin-right: 0;

  .MuiFormControlLabel-label {
    color: $c-gray-primary;
  }
}

// End Form Controls Label
// Start Stepper
.stepper-wrapper {
  &.MuiStepper-root {
    background-color: transparent;
    padding: 0 0.5rem 1.5rem;
    @include d-flex-v-end-h-center;
  }

  .MuiStepConnector-alternativeLabel {
    top: initial;
    // left: calc(-50% + 20px);
    // right: calc(50% + 20px);
    // position: absolute;
    bottom: 12px;

    @include rtl {
      left: calc(50% + 20px);
      right: calc(-50% + 20px);
    }
  }

  .step-progress-text-wrapper {
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translateX(-50%);
  }

  .step-wrapper {
    display: inline-flex;
    justify-content: center;
  }

  .step-label-wrapper,
  .step-button-wrapper {
    height: 100%;

    .MuiStepLabel-iconContainer {
      order: 2;

      svg {
        fill: $c-gray-secondary;
      }
      .MuiStepIcon-active {
        // svg {
        fill: $c-secondary;
        // }
      }
    }

    .MuiStepLabel-label {
      color: $c-gray-dark;
      order: 1;
      margin-top: 0;

      &.MuiStepLabel-active {
        color: $c-black;
      }
    }
  }
}

// End Stepper
// Start Checkbox
.checkbox-groups-wrapper.MuiFormControl-root {
  padding: 0;
}

.checkbox-wrapper {
  .MuiSvgIcon-root {
    path {
      fill: $c-gray-default;
    }
  }
  &.Mui-checked,
  &.MuiCheckbox-indeterminate {
    .mdi {
      color: $c-white;
      background-color: $c-secondary;
    }
  }
  .mdi {
    height: 18px;
    width: 18px;
    border-radius: 0.15rem;

    &::before {
      font-size: 17px;
    }
  }
  .i-unchecked {
    height: 18px;
    width: 18px;
    border: 2px solid $c-gray-default;
  }
  .PrivateSwitchBase-root-5 {
    padding: 0.5rem;
  }

  &.MuiIconButton-root {
    border-radius: 0.15rem;
  }

  &.theme-secondary {
    .MuiSvgIcon-root {
      path {
        fill: $c-secondary;
      }
    }

    &.Mui-checked,
    &.MuiCheckbox-indeterminate {
      .mdi {
        background-color: $c-secondary;
      }
    }
  }
}

// End Checkbox
// ======================================================== End Global Assets
// ======================================================== Start Pages
// Start Header Page
.header-wrapper {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 70px;
  background-color: $c-white;
  box-shadow: $bs-primary;
  z-index: 3;
  position: relative;

  @include small-phones-max() {
    flex-wrap: wrap;
    justify-content: center;
  }

  .section {
    padding: 0.5rem;
    display: flex;
  }

  .logo-text {
    font-size: $fz-10px;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: $c-green;
  }

  .logo-text-small {
    font-size: $fz-9px;
    color: $c-blue-light;

    .text-small {
      font-variant: small-caps;
    }
  }

  .logo-text,
  .logo-text-small {
    font-family: AcuminConcept, serif;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .btns-icon {
    margin: 0 0.25rem !important;

    img {
      border-radius: 100%;
      width: 24px;
      height: 24px;
    }
  }

  .i-text-gray {
    margin-right: 0.5rem;
    margin-left: 1rem;
  }

  .user-img {
    height: 50px;
    width: 50px;
    max-width: 50px;
    max-height: 50px;
    margin-bottom: 1rem;
    border-radius: 100%;
  }

  .user-btn {
    position: absolute;
    right: 0.25rem;
    bottom: 0.25rem;
    transform: translate(50%, -50%);
  }
  .btns-icon {
    &.mdi,
    .mdi {
      color: $c-secondary;
    }

    &img,
    img {
      border: 2px solid $c-secondary;
      border-radius: 100%;
    }
  }
  .btns-active {
    &.mdi,
    .mdi {
      color: $c-primary;
    }

    &.icons,
    .icons {
      filter: invert(13%) sepia(93%) saturate(1767%) hue-rotate(193deg) brightness(92%)
        contrast(103%);
    }

    &img,
    img {
      border: 2px solid $c-primary;
    }
  }
}
.rdrDateDisplay {
  display: none !important;
}
// .rdrDayEndOfWeek .rdrInRange {
//   @include rtl {
//     border-top-left-radius: 1.042em;
//     border-bottom-left-radius: 1.042em;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     right: 0;
//   }
// }
// .rdrStartEdge {
//   @include rtl {
//     border-top-right-radius: 1.042em;
//     border-bottom-right-radius: 1.042em;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     left: 0;
//   }
// }
// .rdrDayStartOfMonth .rdrInRange {
//   @include rtl {
//     border-top-right-radius: 1.042em;
//     border-bottom-right-radius: 1.042em;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     left: 0;
//   }
// }
// .rdrEndEdge {
//   @include rtl {
//     border-top-left-radius: 1.042em;
//     border-bottom-left-radius: 1.042em;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     right: 0;
//   }
// }
// .rdrDayStartOfWeek .rdrInRange {
//   @include rtl {
//     border-top-right-radius: 1.042em;
//     border-bottom-right-radius: 1.042em;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     left: 0;
//   }
// }
// .rdrDayEndOfMonth .rdrInRange {
//   @include rtl {
//     border-top-left-radius: 1.042em;
//     border-bottom-left-radius: 1.042em;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     right: 0;
//   }
// }

// .rdrDayStartOfMonth .rdrDayInPreview,
// .rdrDayStartOfMonth .rdrDayEndPreview,
// .rdrDayStartOfWeek .rdrDayInPreview,
// .rdrDayStartOfWeek .rdrDayEndPreview {
//   @include rtl {
//     border-top-right-radius: 1.333em;
//     border-bottom-right-radius: 1.333em;
//     border-right-width: 1px;
//     right: 0px;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     border-left-width: 0;
//   }
// }

// .rdrDayEndOfMonth .rdrDayInPreview,
// .rdrDayEndOfMonth .rdrDayStartPreview,
// .rdrDayEndOfWeek .rdrDayInPreview,
// .rdrDayEndOfWeek .rdrDayStartPreview {
//   @include rtl {
//     border-top-left-radius: 1.333em;
//     border-bottom-left-radius: 1.333em;
//     border-left-width: 1px;
//     left: 0px;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     border-right-width: 0;
//   }
// }

// .rdrDayStartPreview {
//   @include rtl {
//     border-top-right-radius: 1.333em;
//     border-bottom-right-radius: 1.333em;
//     right: 0;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//   }
// }

// End Header Page
// Start Main Menu
.menu-wrapper {
  min-height: 400px;
  display: inline-flex;
  flex: 1 1 auto;
  z-index: 1000;
  position: relative;
  .menu-content {
    position: sticky;
    top: 1rem;
  }
  .submenu-wrapper {
    height: 100%;
    position: absolute;
    top: 0;
    width: 1.1rem;
    left: 57px;
    @include rtl {
      left: initial;
      right: 57px;
    }
  }
  &:hover:not(.is-closed) {
    .animated-open-close {
      left: 1rem;
      opacity: 1;
      max-width: 100%;
      min-width: 250px;

      @include rtl {
        left: initial;
        right: 1rem;
      }

      .open-close-content {
        background-color: $c-white;
        max-width: 250px;
        opacity: 1;
        transition-property: max-width, opacity;
        transition-delay: 0.1s;
      }
    }
    .animated-open-left {
      left: 1rem;
      opacity: 1;
      max-width: 100%;
      min-width: 250px;

      @include rtl {
        left: initial;
        right: 1rem;
      }

      .open-close-left {
        background-color: $c-white;
        max-width: 250px;
        transition-property: max-width, opacity;
        transition-delay: 0.1s;
      }
    }
  }
  .open-close-content {
    box-shadow: 1px 5px 5px 0.8px rgba(0, 0, 0, 0.1);
  }
  .open-close-left {
    background-color: $c-white;
    max-width: 250px;
    opacity: 1;
    transition-property: max-width, opacity;
    transition-delay: 0.1s;
  }
  .open-close-left {
    box-shadow: 7px 4px 9px #00000024;

    @include rtl {
      box-shadow: -7px 4px 9px #00000024;
    }
  }

  .menu {
    padding-top: 1rem;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    background-image: $bg-gradient-primary-bottom;
    box-shadow: $bs-primary;
    border-radius: 0 0 2rem 0;
    z-index: 1;
    max-width: 57.5px;
    height: 95%;

    @include rtl {
      border-radius: 0 0 0 2rem;
    }

    .btns-menu {
      color: $c-blue-lighter;

      &:hover {
        color: $c-white;
      }

      &.active {
        .mdi {
          color: $c-white;
        }

        &::after {
          content: ' ';
          height: 100%;
          width: 4px;
          border-radius: 0.25rem 0 0 0.25rem;
          background-color: $c-white;
          position: absolute;
          right: 0;
          left: initial;

          @include rtl {
            right: initial;
            left: 0;
            border-radius: 0 0.25rem 0.25rem 0;
          }
        }
      }
    }
  }

  .menuTriangleUp {
    position: absolute;
    left: -11px;
    width: 0;
    height: 0;
    z-index: -1;
    top: 10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #e6e6e6;

    @include rtl {
      border-left: 10px solid $c-white;
      left: 0px;
      right: -11px;
    }
  }
  .menuTriangleDown {
    position: absolute;
    left: -11px;
    z-index: -1;
    top: 20px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e6e6e6;

    @include rtl {
      border-left: 10px solid $c-white;
      left: 0px;
      right: -11px;
    }
  }

  .menuTriangleLeft {
    position: absolute;
    width: 0;
    height: 0;
    left: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid $c-white;

    @include rtl {
      border-left: 10px solid $c-white;
      left: 0px;
      right: -10px;
      border-right: 0px;
    }
  }

  .menuNavLink {
    margin-top: 10px;
  }

  .btns-submenu {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    min-width: 70px;
    min-height: 53px;
    font-size: 13px;
    color: $c-gray-secondary;
    text-decoration: none;

    &:hover {
      color: $c-primary;
    }

    .icons,
    .mdi {
      margin: 0.5rem;
    }

    &.active {
      background-color: $c-gray-lighter;
      position: relative;
      color: $c-primary;

      &:after {
        content: ' ';
        height: 90%;
        background-color: $c-secondary;
        position: absolute;
        width: 5px;
        top: 0;
        right: initial;
        border-radius: 0 4px 4px 0;

        @include rtl {
          right: 0;
          left: initial;
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
}

.menuGroupName {
  color: $c-blue;
  font-size: 1.2em;
  padding: 10px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

// End Main Menu
// Start ViewTypes
.view-types-wrapper {
  @include d-inline-flex;
  margin: 0 0.5rem;
  border: 1px solid #e6e6e6;
  border-radius: 0.5rem;
  // min-height: 2.5rem;

  .btns-view-type.MuiButton-root {
    outline: none;
    min-width: 2rem;
    min-height: 2rem;

    .MuiButton-label {
      color: $c-gray-light;
    }

    &:first-child {
      border-radius: 0.5rem 0 0 0.5rem;

      @include rtl {
        border-radius: 0 0.5rem 0.5rem 0;
      }
    }

    &:last-child {
      border-radius: 0 0.5rem 0.5rem 0;

      @include rtl {
        border-radius: 0.5rem 0 0 0.5rem;
      }
    }

    &.active {
      .MuiButton-label {
        color: $c-primary;
      }

      position: relative;

      &::after {
        content: ' ';
        position: absolute;
        border: 1.5px solid $c-primary;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        border-radius: 0.5rem;
      }
    }
  }
}

// End ViewTypes
.view-wrapper {
  width: 100%;

  .header-section {
    @include d-flex-column;
    margin-bottom: 1rem;

    .filter-section {
      @include d-flex;
      @include flex-v-center-h-between;
      position: relative;

      @include tablets-max {
        flex-direction: column;
        .section {
          &:nth-child(1) {
            margin-bottom: 0.5rem;
            justify-content: center;
          }
          &:nth-child(2) {
            margin-bottom: 1rem;
          }
        }
      }

      .section {
        @include d-inline-flex-v-center;
        // @include flex-center;
        &:first-child {
          .btns {
            margin: 0 0.5rem;
          }
        }
        .btns-icon {
          width: 30px;
          height: 30px;
          min-width: 30px;
        }

        @include tablets-max {
          width: 100%;

          &:first-child {
            order: 2;
            .actions-buttons-wrapper {
              @include flex-h-center;
            }
          }

          &:nth-child(2) {
            @include small-phones-max {
              flex-direction: column;

              .d-flex-column {
                margin-bottom: 1rem;
              }
            }

            order: 1;
          }
        }

        width: 50%;
      }
    }
    .second-filter-section {
      @include d-flex-v-center;
      @include laptops-l-max {
        flex-wrap: wrap;
      }
      @include tablets-max {
        .section:last-child {
          width: 100% !important;
        }
      }
      .section {
        @include d-inline-flex-center;
        width: 33.33333%;
        margin-bottom: 1rem;
        @include tablets-max {
          width: 50%;
        }
        @include phones-max {
          width: 100%;
        }
      }
    }
    .contacts-hidder-section {
      display: flex;
      margin-bottom: 0.5rem;
      padding: 0.5rem;
      align-items: center;
    }
  }

  .body-section {
    @include d-flex;
    @include flex-h-center;
    flex-wrap: wrap;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
  }
}

// Start Action Buttons
.actions-buttons-wrapper {
  @include d-flex;
  padding: 0 0.5rem;
}

// End Action Buttons
// Start Login View
.account-layout-wrapper {
  height: 100%;
  min-width: 320px;
  // min-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

.login-wrapper {
  @include d-flex-center;
  // height: 100%;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  // background-image: $bg-login-dubai;
  background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  @include laptops-max {
    align-items: flex-start;
  }

  .login-content-wrapper {
    position: relative;
    z-index: 99;
    width: 100%;
    max-width: 991px;
    @include d-flex;
    padding: 0.5rem;

    @include laptops-max {
      // margin-bottom: 320px;
      flex-direction: column;
      align-items: center;
    }

    .text-section-wrapper {
      @include d-inline-flex-column;
      @include flex-h-center;
      width: 100%;
      max-width: 50%;
      padding: 0.5rem;

      @include laptops-max {
        width: 100%;
        align-items: center;
        text-align: center;

        .texts-large {
          font-size: 19px;
        }

        .texts {
          font-size: 14px;
        }
      }

      .text-section-content {
        @include d-inline-flex-column;

        :nth-child(even) {
          margin-bottom: 2rem;
        }

        .texts,
        .texts-large {
          text-shadow: 29px -34px 30px rgba(150, 150, 150, 0);
          animation: texts_shadow_animation 3s linear 0s 1 normal;
        }
      }
    }

    .box-section-wrapper {
      padding: 0.5rem;
      @include d-inline-flex-column;
      width: 100%;
      max-width: 50%;

      @include laptops-max {
        max-width: 300px;
        padding-bottom: 200px;
      }

      .box-content {
        position: relative;
        background-color: $c-white;
        border-radius: 0.5rem;
        padding: 1rem 0.5rem 0;
        @include d-flex-column;
        min-height: 350px;
        width: 100%;

        .logo-wrapper {
          .logo {
            margin-left: 20px;
            margin-bottom: 15px;
            @include rtl {
              margin-right: 20px;
              margin-left: initial;
            }
            max-width: 60px;
            min-width: 40px;
          }
        }

        .form-wrapper {
          padding: 3rem 2.5rem;
        }

        .curve-edge {
          background-image: $bg-box-curve-right;
          transform: scaleX(-1);
          left: 0;

          @include rtl {
            left: initial;
            right: 0;
          }
        }

        .curve-edge-reverced {
          background-image: $bg-box-curve-right;
          right: 0;

          @include rtl {
            right: initial;
            left: 0;
          }
        }

        .animated-btn-wrapper {
          padding-right: 50px;
          @include d-inline-flex;
          position: relative;
          transition: padding 0.3s linear;

          @include rtl {
            padding-right: 0;
            padding-left: 50px;
          }

          &.in-animate {
            padding-right: 0 !important;
            padding-left: 0 !important;
          }

          .btns {
            width: 100px;
            min-width: 100px;
            height: 26px;
            position: relative;
            z-index: 2;
            border-radius: 0.5rem;
            margin: 0;
          }

          .animated-icon {
            position: absolute;
            @include d-flex-center;
            border-radius: 0.5rem;
            z-index: 1;
            width: 60px;
            padding-left: 10px;
            right: 0;
            height: 26px;
            border: 1px solid $c-gray-secondary;

            @include rtl {
              right: initial;
              left: 1px;
            }
          }
        }

        .curve-edge,
        .curve-edge-reverced {
          position: absolute;
          top: calc(100% - 5px);
          width: 45%;
          background-size: cover;
          min-height: 35px;
          background-repeat: no-repeat;
        }
      }
    }
  }

  .light-shadow {
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 50%;
    border-radius: 100%;
    transform: rotateZ(-35deg);
    background-color: rgba($color: #fffbdd, $alpha: 0.08);
    box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.08);
    animation: light_animation 3s linear 0s 1 normal;

    @include rtl {
      left: initial;
      right: 0;
    }
  }

  .city-wrapper {
    overflow: hidden;
    height: 100%;
    width: 100%;
    // min-width: 537px;
    // min-height: 374px;
    min-width: 300px;
    z-index: 3;

    @include rtl {
      left: initial;
      right: 0;
    }

    .city {
      background-size: cover;
      background-image: $bg-dubai;
      z-index: 2;
      // animation: city_animation 1s linear 0s 1 normal;
    }

    .city-shadow {
      background-image: $bg-city-shadow;
      z-index: 1;
      animation: city_shadow_animation 3.5s linear 0s 1 normal;
    }

    .city-shadow,
    .city {
      background-position: bottom;
      width: 100%;
      height: 100%;

      @include rtl {
        transform: scaleX(-1);
      }
    }
  }

  .city-wrapper,
  .city,
  .city-shadow {
    position: absolute;
    left: 0;
    bottom: 0;
    // background-size: 100% 100%;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}

@keyframes texts_shadow_animation {
  0% {
    text-shadow: 0px 0px 0px rgba(150, 150, 150, 1);
  }

  100% {
    text-shadow: 29px -34px 20px rgba(150, 150, 150, 0);
  }
}

@keyframes light_animation {
  0% {
    bottom: -90%;
    left: -40%;
    border-radius: 100%;
    width: 30%;
    height: 30%;
    background-color: rgba($color: #fffbdd, $alpha: 0.1);
    box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.1);
  }

  30% {
    box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.3);
    background-color: rgba($color: #fffbdd, $alpha: 0.3);
  }

  50% {
    bottom: 0;
    left: 0;
    box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.25);
    background-color: rgba($color: #fffbdd, $alpha: 0.25);
    border-radius: 100%;
    height: 100%;
  }

  100% {
    height: 200%;
    width: 50%;
    border-radius: 100%;
    background-color: rgba($color: #fffbdd, $alpha: 0.08);
    box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.08);
  }
}

@keyframes city_shadow_animation {
  0% {
    bottom: -50%;
    left: -100%;
  }

  100% {
    bottom: 0;
    left: 0;
  }
}

@keyframes city_animation {
  0% {
    bottom: -100%;
    left: -100%;
  }

  100% {
    bottom: 0;
    left: 0;
  }
}

@include rtl-in {
  @keyframes light_animation {
    0% {
      bottom: -90%;
      right: -40%;
      border-radius: 100%;
      width: 30%;
      height: 30%;
      background-color: rgba($color: #fffbdd, $alpha: 0.1);
      box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.1);
    }

    30% {
      box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.3);
      background-color: rgba($color: #fffbdd, $alpha: 0.3);
    }

    50% {
      bottom: 0;
      right: 0;
      box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.25);
      background-color: rgba($color: #fffbdd, $alpha: 0.25);
      border-radius: 100%;
      height: 100%;
    }

    100% {
      height: 200%;
      width: 50%;
      border-radius: 100%;
      background-color: rgba($color: #fffbdd, $alpha: 0.08);
      box-shadow: 0 0 1.5rem 0.8rem rgba($color: #fffbdd, $alpha: 0.08);
    }
  }

  @keyframes city_shadow_animation {
    0% {
      bottom: -50%;
      right: -100%;
    }

    100% {
      bottom: 0;
      right: 0;
    }
  }

  @keyframes city_animation {
    0% {
      bottom: -100%;
      right: -100%;
    }

    100% {
      bottom: 0;
      right: 0;
    }
  }
}

// End Login
// ======================================================== End Pages
// ======================================================== Start Texts
.text-nowrap {
  white-space: nowrap;
}

.texts,
.texts-header,
.texts-form {
  font-size: $fz-default;

  &.gray-primary-bold,
  &.primary-bold {
    font-weight: bold;
  }

  &.primary-bold {
    color: $c-primary;
  }

  &.gray-primary-bold {
    color: $c-gray-primary;
  }
}

.texts-form {
  padding: 0 0 0.25rem;
  color: $c-gray-primary;
  &.disabled {
    color: $c-gray-secondary !important;
  }
}

.texts-header {
  font-size: 45px;
}

.texts-truncate {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ======================================================== End Texts
// ======================================================== Start Images
.img-logo {
  background-image: $img-logo;
  height: 43px;
  width: 48px;
  background-size: contain;
  background-position: center;
}

// ======================================================== End Images
// ======================================================== Start Icons
.i-bell-blue {
  background-image: $i-bell-blue;
}

.i-text-gray {
  background-image: $i-text-gray;
}

.i-home-blue {
  background-image: $i-home-blue;
}

.i-home-blue-dark {
  background-image: $i-home-blue-dark;
}

.i-home-gray {
  background-image: $i-home-gray;
}

.i-home-white {
  background-image: $i-home-white;
}

.i-contacts-blue {
  background-image: $i-contacts-blue;
}

.i-contacts-blue-dark {
  background-image: $i-contacts-blue-dark;
}

.i-contacts-gray {
  background-image: $i-contacts-gray;
}

.i-contacts-white {
  background-image: $i-contacts-white;
}

.i-people-blue {
  background-image: $i-people-blue;
}

.i-people-blue-dark {
  background-image: $i-people-blue-dark;
}

.i-people-gray {
  background-image: $i-people-gray;
}

.i-people-white {
  background-image: $i-people-white;
}

.i-arrow-reverse-blue {
  background-image: $i-arrow-reverse-blue;
}

.i-arrow-reverse-blue-dark {
  background-image: $i-arrow-reverse-blue-dark;
}

.i-arrow-reverse-gray {
  background-image: $i-arrow-reverse-gray;
}

.i-arrow-reverse-white {
  background-image: $i-arrow-reverse-white;
}

.i-speed-monitor-blue {
  background-image: $i-speed-monitor-blue;
}

.i-speed-monitor-blue-dark {
  background-image: $i-speed-monitor-blue-dark;
}

.i-speed-monitor-gray {
  background-image: $i-speed-monitor-gray;
}

.i-speed-monitor-white {
  background-image: $i-speed-monitor-white;
}

.i-report-edit-blue {
  background-image: $i-report-edit-blue;
}

.i-report-edit-blue-dark {
  background-image: $i-report-edit-blue-dark;
}

.i-report-edit-gray {
  background-image: $i-report-edit-gray;
}

.i-report-edit-white {
  background-image: $i-report-edit-white;
}

.i-report-view-blue {
  background-image: $i-report-view-blue;
}

.i-report-view-blue-dark {
  background-image: $i-report-view-blue-dark;
}

.i-report-view-gray {
  background-image: $i-report-view-gray;
}

.i-report-view-white {
  background-image: $i-report-view-white;
}

.i-folder-blue {
  background-image: $i-folder-blue;
}

.i-folder-blue-dark {
  background-image: $i-folder-blue-dark;
}

.i-folder-gray {
  background-image: $i-folder-gray;
}

.i-folder-white {
  background-image: $i-folder-white;
}

.i-box-circle-blue {
  background-image: $i-box-circle-blue;
}

.i-box-circle-blue-dark {
  background-image: $i-box-circle-blue-dark;
}

.i-box-circle-gray {
  background-image: $i-box-circle-gray;
}

.i-box-circle-white {
  background-image: $i-box-circle-white;
}

.i-activities {
  background-image: $i-activities;
}

.i-cloud {
  background-image: $i-cloud;
}

.i-upload {
  background-image: $i-upload;
}

.i-shield {
  background-image: $i-shield;
}

.i-eiffel-tower {
  background-image: $i-eiffel-tower;
}

.i-calendar-blank {
  background-image: $i-calendar-blank;
}

.i-calendar {
  background-image: $i-calendar;
}

.i-oclock {
  background-image: $i-oclock;
}

.i-email {
  background-image: $i-email;
}

.i-account {
  background-image: $i-account;
}
.i-slider {
  background-image: $i-slider;
}
.i-slider-white {
  background-image: $i-slider-white;
}
.i-slider-gray {
  background-image: $i-slider-gray;
}
.i-slider-primary {
  background-image: $i-slider-primary;
}

// ======================================================== End Icons
// ======================================================== Start Flexing
.d-flex-column,
.d-flex-center,
.d-flex,
.d-flex-h-between,
.d-flex-v-center,
.d-flex-v-center-h-between,
.d-flex-column-center {
  @include d-flex;
}

.d-flex-v-center-h-end {
  @include d-flex-v-center-h-end;
}

.d-inline-flex,
.d-inline-flex-center,
.d-inline-flex-column-center-v,
.d-inline-flex-column {
  @include d-inline-flex;
}

.d-inline-flex-center,
.d-flex-center,
.d-flex-column-center {
  @include flex-center;
}
.d-flex-v-center,
.d-flex-v-center-h-between {
  @include flex-v-center;
}

.d-inline-flex-column-center-v {
  justify-content: center;
  align-items: flex-start;
}
.d-inline-flex-v-center {
  @include d-inline-flex-v-center;
}

.fa-start {
  align-items: flex-start !important;
}
.fas-center {
  align-self: center !important;
}

.fj-start {
  justify-content: flex-start !important;
}

.fj-end {
  justify-content: flex-end !important;
}

.fj-between,
.d-flex-h-between,
.d-flex-v-center-h-between {
  justify-content: space-between !important;
}

.d-inline-flex-column-center-v,
.d-flex-column,
.d-inline-flex-column,
.d-flex-column-center {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap !important;
}
.flex-nowrap {
  flex-wrap: nowrap !important;
}

.d-flex-column {
  width: 100%;
}

// ======================================================== End Flexing
// ======================================================== Start Display
.d-inline-block {
  display: inline-block !important;
}
.d-none {
  display: none !important;
}
// ======================================================== End Display
// ======================================================== Start Positions
.p-relative {
  position: relative;
}

.p-absolute,
.p-absolute-r-0,
.p-absolute-t-2-r-3,
.p-absolute-t-2-r-2,
.p-absolute-tr-0 {
  position: absolute !important;
  z-index: 1;
}
.p-absolute-tr-0,
.p-absolute-r-0 {
  right: 0;
  @include rtl {
    right: unset;
    left: 0;
  }
}
.p-absolute-t-2-r-3,
.p-absolute-r-3 {
  right: 1rem;
  @include rtl {
    right: unset;
    left: 1rem;
  }
}
.p-absolute-t-2-r-2,
.p-absolute-r-2 {
  right: 0.5rem;
  @include rtl {
    right: unset;
    left: 0.5rem;
  }
}

.p-absolute-tr-0,
.p-absolute-t-0 {
  top: 0;
}
.p-absolute-t-2-r-2,
.p-absolute-t-2-r-3,
.p-absolute-t-2 {
  top: 0.5rem;
}

.top-m5px {
  top: -5px;
}

// ======================================================== End Positions
// ======================================================== Start Margins & Padding
.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}

.ml-2 {
  margin-left: 0.5rem !important;

  @include rtl {
    margin-right: 0.5rem !important;
  }
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.mt-1 {
  margin-top: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.my-2,
.mt-2 {
  margin-top: 0.5rem !important;
}
.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 2rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.px-0,
.p-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.pt-0,
.p-0 {
  padding-top: 0 !important;
}

.pb-0,
.p-0 {
  padding-bottom: 0 !important;
}

.py-2,
.pb-2,
.p-2 {
  padding-bottom: 0.5rem !important;
}

.py-2,
.pt-2,
.p-2 {
  padding-top: 0.5rem !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}
.px-2,
.p-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.px-5,
.p-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-3,
.p-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.pt-3,
.py-3,
.p-3 {
  padding-top: 1rem !important;
}
.pb-3,
.py-3,
.p-3 {
  padding-bottom: 1rem !important;
}

// ======================================================== End Margins & Padding
// ======================================================== Start Borders
.br-0 {
  border-radius: 0 !important;
}
.br-2 {
  border-radius: 0.5rem !important;
}
.br-r-1rem-reversed {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;

  @include rtl {
    border-top-left-radius: 1rem !important;
    border-bottom-left-radius: 1rem !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.br-l-1rem-reversed {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;

  @include rtl {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top-right-radius: 1rem !important;
    border-bottom-right-radius: 1rem !important;
  }
}

// ======================================================== End Borders
// ======================================================== Start Width
.mw-50 {
  min-width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.wmx-320px {
  max-width: 320px;
}

.wmx-270px {
  max-width: 270px;
}

.wmx-435px {
  max-width: 435px;
}

// ======================================================== End Width
// ======================================================== Start Font Sizes
.fz-15px {
  font-size: $fz-15px;
}
.fz-16px {
  font-size: $fz-16px;
}
.fz-30px {
  font-size: 30px;
}

// ======================================================== End Font Sizes
// ======================================================== Start Font Weight
.fw-bold {
  font-weight: bold !important;
}
.fw-normal {
  font-weight: normal !important;
}
// ======================================================== End Font Weight
//========================================================= Start Colors Classes
.c-primary {
  color: $c-primary !important;
}

.c-primary-dark {
  color: $c-primary-dark !important;
}

.c-secondary {
  color: $c-secondary !important;
}

.c-success {
  color: $c-success !important;
}

.c-warning {
  color: $c-warning !important;
}

.c-danger {
  color: $c-danger !important;
}

.c-info {
  color: $c-info !important;
}

.c-white {
  color: $c-white !important;
}

.c-black {
  color: $c-black !important;
}

.c-black-dark {
  color: $c-black-dark;
}

.c-black-light {
  color: $c-black-light!important;
}

.c-blue {
  color: $c-blue;
}

.c-blue-light {
  color: $c-blue-light;
}

.c-blue-lighter {
  color: $c-blue-lighter;
}

.c-red {
  color: $c-red;
}

.c-gray-default {
  color: $c-gray-default;
}

.c-gray-primary {
  color: $c-gray-primary !important;
}

.c-gray-secondary {
  color: $c-gray-secondary;
}

.c-gray-dark {
  color: $c-gray-dark;
}

.c-gray-light {
  color: $c-gray-light;
}

.c-gray-lightest {
  color: $c-gray-lightest;
}

.c-green-light {
  color: $c-green-light;
}

.c-green {
  color: $c-green;
}

.c-green-dark {
  color: $c-green-dark;
}

// ========================================================= End Colors Classes
// ========================================================= Start Backgrounds Colors
.bg-green-light {
  background-color: $c-green-light !important;
}

.bg-green-dark {
  background-color: $c-green-dark !important;
}

.bg-green {
  background-color: $c-green !important;
}

.bg-danger {
  background-color: $c-danger !important;
}

.bg-success {
  background-color: $c-success !important;
}

.bg-primary {
  background-color: $c-primary !important;
}

.bg-blue-light {
  background-color: $c-blue-light !important;
}

.bg-blue-lighter {
  background-color: $c-blue-lighter !important;
}

.bg-secondary {
  background-color: $c-secondary !important;
}

.bg-warning {
  background-color: $c-warning;
}

.bg-warning-light {
  background-color: $c-warning-light;
}

.bg-info {
  background-color: $c-info;
}

.bg-gray-default {
  background-color: $c-gray-default;
}

.bg-gray-primary {
  background-color: $c-gray-primary;
}

.bg-gray-light {
  background-color: $c-gray-light;
}

.bg-gray-lighter {
  background-color: $c-gray-lighter !important;
}

.bg-gray-lightest {
  background-color: $c-gray-lightest;
}

// ========================================================= End Backgrounds Colors
// ========================================================= Start Gradients Classes
.bg-gradient-primary {
  background-image: $bg-gradient-primary;
}

.bg-gradient-primary-left {
  background-image: $bg-gradient-primary-left;

  @include rtl {
    background-image: $bg-gradient-primary-right;
  }
}

.bg-gradient-primary-right {
  background-image: $bg-gradient-primary-right;

  @include rtl {
    background-image: $bg-gradient-primary-left;
  }
}

.bg-gradient-primary-top {
  background-image: $bg-gradient-primary-top;
}

.bg-gradient-primary-bottom {
  background-image: $bg-gradient-primary-bottom;
}

//========================================================= End Gradients Classes
