.pending-tour-details-component-wrapper {
  .trip-cover-image {
    width: 100%;
    border-radius: 1rem;
    height: 130px;
    background-size: 100%;
    box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
    background-repeat: no-repeat;
    background-position: center;
  }
  .trips-timeline-details-wrapper {
    overflow-y: auto;
    min-height: 500px !important;
    max-height: calc(100vh - 100px) !important;
  }
}
