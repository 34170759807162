@import '../../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../../Assets/Theme/mixins.scss';
.time-management-dialog {
  width: 100%;
  position: relative;

  .trip-date-wrapper {
    @include d-flex-v-center;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    .trip-date-section {
      @include d-inline-flex-center;
      max-width: 350px;
      padding: 0 0.5rem;
    }
    .is-repeated-section {
      @include d-inline-flex-v-center;
      padding: 0 0.5rem;
      align-self: flex-end;
    }
  }
  // .MuiAutocomplete-inputRoot {
  //   max-height: 57px;
  //   overflow-y: auto;
  // }
  .selects {
    // &.MuiInputBase-root.MuiInput-underline {
    //   height: initial !important;
    // }
    .MuiSelect-root {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
      max-width: 247px;
      display: inline-block !important;
      line-height: 25px;
    }
    .MuiSelect-root.Mui-disabled {
      max-width: 275px;
    }
  }
  .trip-times-wrapper {
    @include d-flex-column;
    .trip-time-item {
      @include d-flex-column;
      padding-top: 0.5rem;
      margin-bottom: 0.5rem;
      border: 1px solid $c-gray-default;
      border-radius: 0.5rem;
      .sections-wrapper {
        @include d-flex;
        align-items: flex-end;
        @include tablets-max {
          flex-wrap: wrap;
        }
        .section {
          @include d-inline-flex;
          padding: 0 0.5rem 0.5rem;
          width: 100%;
          &.mxw-100px {
            max-width: 100px;
          }
        }
      }
    }
  }
}

.dialog-content-wrapper.MuiDialogContent-root {
  padding: 0.5rem;
}
