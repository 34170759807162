@import '../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../Assets/Theme/mixins.scss';

.mobile-points-tours-wrapper {
  cursor: pointer;
  padding: 2rem;
  padding-left: 3rem;
  @include rtl {
    padding-left: 2rem;
    padding-right: 3rem;
  }
  .mobile-points-tours-title {
    font-size: 18px;
    padding-bottom: 1rem;
  }
  .mobile-points-tours-content {
    overflow-x: scroll;
    display: flex;
    padding-bottom: 1rem;
    .mobile-points-card {
      padding: 1rem;
      border: 0.18rem solid #e8e8e873;
      box-shadow: 0px 3px 15px #00000017;
      height: 11rem;
      min-width: 21rem;
      border-radius: 1rem;
      margin-right: 1rem;
      @include rtl {
        margin-right: 0;
        margin-left: 1rem;
      }
      .mobile-card-title {
        font-size: 14px;
        font-weight: bold;
      }
      .rating-wrapper {
        display: flex;
        align-items: center;
        font-size: 13px;
        .tour-price {
          font-weight: bold;
          color: $c-secondary;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
          margin-top: 0.2rem;
        }
      }
      .mobile-card-body {
        padding-top: 0.5rem;
        margin-left: -0.9rem;
        @include rtl {
          margin-left: 0;
          margin-right: -0.9rem;
        }
        .mobile-card-timeline {
          .timeline-wrapper {
            display: flex;
            flex-direction: row;
            .MuiTimelineSeparator-root {
              transform: rotate(90deg);
              @include rtl {
                transform: rotate(270deg);
              }
              margin-top: -3.5rem;
              height: 7rem;
            }
            .mobile-card-timeline-body {
              .img-center {
                margin-left: -0.8rem;
                @include rtl {
                  margin-left: 0;
                  margin-right: -0.8rem;
                }
                width: 4rem;
                height: 4rem;
                border-radius: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
}
