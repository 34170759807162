@import '../../Assets/Theme/mixins.scss';
.cropper-wrapper {
  @include d-flex-column-center;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
  .cropper-image-wrapper {
    margin-bottom: 1rem;
    .cropper-image {
      height: 350px;
      width: 300px;
    }
  }
}
