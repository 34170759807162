@import '../../../../../Assets/Theme/mixins.scss';
.theme-Dashboard{
    .MuiTableContainer-root::-webkit-scrollbar{
        width: 0px;
        height: 0px;
    }
  
.table-wrapper {
    &.MuiTable-root {
    .MuiTableHead-root .MuiTableRow-head .MuiTableCell-head{
        background-color:#EFEFEF;
        color:black
    }
.MuiTableBody-root .MuiTableRow-root:nth-child(even) .MuiTableCell-body {
    background-color: white;

}

.MuiTablePagination-root .pagination-wrapper .MuiInputBase-root .select-wrapper{
    background-color:white;
}

    
}

}
}
.CarActivity-Table{
    background-color: #FFFFFF;
    border-radius: 20px;
    position: relative;
    @include laptops-xxl-max{
        width:90%
      }
      @include tablets-max {
        width: 86%;
    }     
      
}

.CarActivityTitle {
    display: flex;
    .CarActivityPargraph{
      width: 100%;
      flex-basis: 100%;
      padding: 0 2rem;
      text-align: start;
      margin-top: 5px;
    }
}

.CarActivity-select{
    .select-wrapper.MuiFormControl-root.theme-solid .selects .MuiSelect-select{
      border: none;
      justify-content:flex-end;
      color:#2B0649;
      margin-right: 7px;
    }
}
