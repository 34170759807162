@import '../../../../../../../Assets/Theme/mixins.scss';
.tour-card-component-wrapper {
  padding: 1rem;
  .tour-card-wrapper {
    display: inline-block;
    box-sizing: inherit;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    column-count: 1;
    width: 25%;
    min-width: 300px;
    .cards-wrapper {
      .cards-body {
        height: initial;
      }
    }
    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
      width: 100%;
    }
    .cards-footer {
      padding: 0.5rem;
    }
    .tour-cover-image {
      width: 100%;
      border-radius: 1rem;
      box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
      height: 130px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
