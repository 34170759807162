@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';
.user-management-view {
  .body-content {
    @include d-flex-v-start-h-center;
    flex-wrap: wrap;
    padding: 1rem 0.5rem;
    .information-section {
      @include d-inline-flex;
      width: 50%;
      @include laptops-max {
        width: 100%;
      }
      margin-bottom: 1rem;
      padding: 0 0.5rem;
      color: $c-black-light;
      .information-box-wrapper {
        @include d-flex-column;
        // padding: 1rem 0;
        border: 1px solid $c-gray-default;
        border-radius: 1rem;
      }
      .information-section-content {
        @include d-flex;
        // flex-wrap: wrap;
        padding: 1rem 0.5rem;
        width: 100%;
        @include small-phones-max {
          flex-direction: column;
        }
        .image-wrapper {
          padding: 0.5rem;
        }
        .roles-wrapper {
          @include d-flex-v-center;
          @include small-phones-max {
            flex-direction: column;
          }
          .roles-content {
            color: $c-secondary;
            @include d-inline-flex-v-center;
            flex-wrap: wrap;
            margin-bottom: 0.25rem;

            span {
              //   padding: 0 0.5rem;
              margin-bottom: 0.25rem;
            }
          }
          .roles-button {
            margin: 0 0.5rem 0.5rem;
            border-radius: 0.5rem;
            border-color: $c-gray-default;
            justify-content: space-between;
          }
        }
        .information-content-wrapper {
          @include d-flex-column;
          width: 100%;
          padding: 0.5rem;
        }
      }
      .footer-content-wrapper {
        @include d-flex-v-center-h-between;
        flex: wrap;
        width: 100%;
        padding: 1rem 0.5rem 0;
        @include small-phones-max {
          flex-direction: column;
        }
        .footer-section {
          @include d-inline-flex-column;
          padding: 0 0.5rem;
          margin-bottom: 0.5rem;
          font-weight: bold;
          flex: 1 1 auto;
          .section-item {
            @include d-flex-v-center;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
    .form-item {
      width: 100%;
      margin-bottom: 1rem;
    }
    .account-dialog-section {
      @include d-inline-flex-column;
      width: 50%;
      @include laptops-max {
        width: 100%;
      }
      padding: 0 0.5rem;
    }
    .dialog-header {
      @include d-flex-center;
      background-color: $c-primary;
      border-radius: 1rem 1rem 0 0;
      padding: 1rem;
      color: $c-white;
      font-size: 16px;
    }
    .dialog-body {
      @include d-flex-column;
      border: 1px solid $c-gray-default;
      border-top: 0;
      border-radius: 0 0 1rem 1rem;
      padding: 1rem 1rem 0;
    }
  }
}
