@import '../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../Assets/Theme/mixins.scss';
.tour-discounts-fees-wrapper {
  .on-input-select {
    position: absolute;
    z-index: 1;
    right: 0.5rem;
    height: 30px;
    // width: 50px;
    @include rtl {
      right: initial;
      left: 0.5rem;
    }
    .select-wrapper {
      min-width: initial;
      .selects {
        .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
          border-radius: 0 0.5rem 0.5rem 0 !important;
          height: 28px;
          @include rtl {
            border-radius: 0.5rem 0 0 0.5rem !important;
            -webkit-border-radius: 0.5rem 0 0 0.5rem !important;
            -moz-border-radius: 0.5rem 0 0 0.5rem !important;
            -ms-border-radius: 0.5rem 0 0 0.5rem !important;
            -o-border-radius: 0.5rem 0 0 0.5rem !important;
          }
        }
      }
    }
  }
  .form-wrapper {
    @include d-flex;
    flex-wrap: wrap;
    .item-3 {
      width: 33.3333%;
      @include tablets-max {
        width: 100%;
      }
    }
    .form-item {
      position: relative;
      display: inline-flex;
      flex: 1 1 auto;
      align-items: flex-end;
      flex-wrap: wrap;
      width: 50%;
      padding: 0 0.5rem;
      margin-bottom: 1rem;
      @include tablets-max {
        width: 100%;
      }
    }

    .form-inpo {
      position: relative;
      display: inline-flex;
      flex: 1 1 auto;
      align-items: flex-end;
      flex-wrap: wrap;
      width: 50%;
      padding: 0 0.5rem;
      margin-bottom: 1rem;
      @include tablets-max {
        width: 60%;
      }
    }

    .part-1 {
      position: relative;
      display: inline-flex;
      width: 88%;
      @media only screen and (max-width: 768px) {
        width: 83%;
      }
      @media only screen and (max-width: 480px) {
        width: 80%;
      }
      @media only screen and (max-width: 320px) {
        width: 60%;
      }
    }
    .part-2 {
      position: relative;
      display: inline-flex;
      width: 4%;
    }
    .line-heder {
      border-bottom: 2px solid #cccaca;
      margin-bottom: 10px;
      border-radius: 2px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
    }
    .Delete-Icon:hover {
      color: $c-danger;
    }
    .addIconactive {
      background-color: $c-green;
      color: #ffffff;
    }
    .addIcondisactive {
      background-color: #dddddd;
      color: #8b8686;
    }
    .part-add {
      @media only screen and (max-width: 768px) {
        padding-left: 10px;
        @include rtl {
          padding-right: 10px;
        }
      }
      @media only screen and (max-width: 480px) {
        padding-left: 30px;
        @include rtl {
          padding-right: 10px;
        }
      }
      @media only screen and (max-width: 320px) {
        padding-left: 20px;
        @include rtl {
          padding-right: 10px;
        }
      }
    }
  }
}
