@import '../../../../../Assets/Theme/mixins.scss';

.TourStatus{
  width:86%;
    text-align: center;
    margin-top: 20px;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    background-color:  #FFFFFF ;
    @include phones-max {
      .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2{
        justify-content:center;
      }
    }
    @include small-phones-max {
      width: 100%;
      justify-content: center;
      .MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-2{
        justify-content:center;
      }
    }
    
}

.TourCard{
    box-shadow: 0px 25px 30px #0000001A;;   
    border-radius:10px;
    opacity:1;
    display: flex;
    min-width: 200px;
    position: relative;
      }

    .ongoingTours{
        background-image: url('../../../../../Assets/Images/DashboardImages/11221.png');
        margin:18px;
        min-width: 48PX;
        height: 48PX;
        @include laptops-max{
        
        }
    }
    .pendingTours{
        background-image: url('../../../../../Assets/Images/DashboardImages/11220.png');
        margin:18px;
        width: 48PX;
        height: 48PX;
        @include laptops-max{
         
            }
    }
    .upComingTours{
        background-image: url('../../../../../Assets/Images/DashboardImages/11222.png');
        margin:18px;
        width: 48PX;
        height: 48PX;
        @include laptops-max{
            
            }
    }
    .completedTours{
        background-image: url('../../../../../Assets/Images/DashboardImages/11223.png');
        margin:18px;
        width: 48PX;
        height: 48PX;
        @include laptops-max{
           
            }
    }
    
    .StatusDiv{
        margin-top: 18px;

      .ToursNum{
        font-weight: 800;
        text-align:justify;
      }  
    }
