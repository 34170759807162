@import '../../Assets/Theme/variables.scss';
.gallery-wrapper {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  padding: 0.5rem;
  .gallery-item-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0.5rem;
    margin-bottom: 0.5rem;
    .gallery-item-box {
      height: 100px;
      width: 100px;
      max-width: 100px;
      max-height: 100px;
      border-radius: 0.5rem;
      border: 2px solid $c-gray-secondary;
      overflow: hidden;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      align-items: center;
      .gallery-image {
        width: 100%;
        height: 100%;
        &.is-default {
          width: 100%;
          height: 100%;
        }
      }
    }
    .gallery-text {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
