@import '../../../../Assets/Theme/variables.scss';
@import '../../../../Assets/Theme/mixins.scss';
.invoice-card-component-wrapper {
  padding: 1rem;
  .invoice-card-wrapper {
    display: inline-block;
    box-sizing: inherit;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    width: 25%;
    min-width: 300px;
    .cards-wrapper {
      &:hover {
        box-shadow: 3px 3px 6px $c-gray-lightest;
      }
      position: relative;
      .cards-header {
        position: relative;
      }
      .cards-body {
        height: initial;
        .item-wrapper {
          max-width: 100%;
          padding: 0.5rem;
          border-radius: 1rem;
        }
      }
    }
    .invoice-header-wrapper {
      font-size: 18px;
      font-weight: bolder;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      .MuiDivider-root {
        background-color: $c-gray-light;
        margin-top: 0.4rem;
        width: 90%;
        height: 0.01rem;
      }
      .download-button {
        display: flex;
        position: absolute;
        right: 1rem;
        .MuiButton-root {
          display: block;
          background-color: $c-blue-lightest;
          color: $c-blue-light;
          min-width: 2.5rem;
          width: 2.5rem;
          height: 2rem;
        }
      }
    }
    .invoice-detailes-container {
      display: flex;
      .invoice-info-container {
        padding: 0 1rem 0.5rem;
        color: $c-gray-dark;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .invoice-info-content-container {
        padding: 0 1rem 0.5rem;
        color: $c-gray-dark;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
    .payment-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 0.4rem;
    }

    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
      width: 100%;
    }
    .cards-footer {
      padding: 0.5rem;
      align-items: center;
    }
    .item-wrapper {
      justify-content: center;
    }
  }
}
