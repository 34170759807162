@import '../../Assets/Theme/variables.scss';
@import '../../Assets/Theme/mixins.scss';

.dialog-wrapper.MuiDialog-root {
  .MuiPaper-root {
    width: 100%;
  }
  .dialog-title-wrapper {
    padding: 0;
    .MuiTypography-root {
      padding: 0.5rem 0 0;
      @include d-flex-v-center-h-between;
    }
    .dialog-title-text {
      border-bottom: 1px solid $c-secondary;
      color: $c-secondary;
      font-size: 16px;
      padding: 0 0.5rem 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
  .dialog-footer-wrapper {
    @include d-flex-column;
    &.MuiDialogActions-root {
      padding: 0;
      margin-bottom: 0;
    }
  }
}
