@import '../../../../../Assets/Theme/variables.scss';
.drivers-details-wrapper {
  position: relative;
  color: $c-black-light;
  .car-plate {
    background-color: $c-black-light;
    border-radius: 1rem;
    color: $c-white;
    margin: 0 0.25rem;
    padding: 0 0.25rem;
  }
  .day-button-wrapper {
    &.have-events {
      .MuiTypography-root {
        color: $c-secondary;
        font-weight: bold;
      }
    }
    .MuiButtonBase-root {
      background-color: $c-white;
    }
    .MuiTypography-root {
      color: $c-black-light;
    }
  }
  .tours-collapses-wrapper {
    min-height: 385px;
    max-height: calc(100vh - 415px);
    overflow-y: auto;
  }
  .tourNm {
    color: $c-secondary;
  }
  .space
  {
    padding-top: 20px;
  }
  .spacetop
  {
    margin-top: 30px;
  }
}
