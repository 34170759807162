@import '../../../../../Assets/Theme/mixins.scss';
@import '../../../../../Assets/Theme/variables.scss';

.sections-cards-conponent-wrapper {
  @include d-flex;
  flex-wrap: wrap;
  justify-content: center;
  .drop-navigator {
    border: 1px solid $c-success;
    border-radius: 1rem;
    pointer-events: none;
    width: 100%;
    height: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @include d-flex-center;
    min-height: 50px;
    padding: 0.5rem;
    &.not-allowed {
      border-color: $c-danger;
    }
    &.is-drop {
      border-width: 3px;
      font-weight: bold;
      background-color: $c-green-light;
    }
  }
  .loadable-image-name {
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tour-header {
    font-size: 18px;
  }
  .num-of-tours {
    color: $c-secondary;
  }
  .card-date {
    color: $c-secondary;
  }
  .section-load-image {
    pointer-events: none;
    width: 100%;
    height: 130px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    position: absolute !important;
    top: 0;
  }
  .tour-name-wrapper {
    display: flex;
    justify-content: center;
    width: 80%;
    position: relative;
    .tour-number {
      font-size: 13px;
      font-weight: bolder;
      color: white;
      text-align: center;
      padding-bottom: 0.7rem;
    }
  }

  .active-tour {
    font-size: 14px;
    color: $c-secondary;
  }
  .inactive-tour {
    font-size: 14px;
    color: $c-danger;
  }
  .trip-card-wrapper {
    position: relative;
    @include d-inline-flex;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    width: 15%;
    min-width: 300px;
    @include laptops-l-max {
      width: 20%;
    }
    @include laptops-max {
      width: 30%;
    }
    @include tablets-max {
      width: 40%;
    }
    .cards-footer {
      padding: 0.5rem;
    }
    .trip-cover-image {
      width: 100%;
      height: 130px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  .item-actions {
    .actions {
      top: -25% !important;
      @include rtl {
        margin-left: -0.5rem;
      }
    }
  }
  .cards-wrapper {
    overflow: hidden;
  }
  .section-cover-wrapper {
    width: 97%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .section-cover-image {
      pointer-events: none;
      background-color: cornflowerblue;
      border-radius: 1rem;
      width: 55%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      z-index: 2;
      overflow: hidden;
      box-shadow: 0px 5px 10px $c-gray-light;
    }
    .section-cover-placeholder {
      position: absolute;
      background-color: #313135;
      border-radius: 1rem;
      width: 67%;
      height: 87%;
      z-index: 1;
      box-shadow: 0px 0px 10px $c-gray-dark;
    }
    .section-cover-placeholder2 {
      position: absolute;
      background-color: #707882;
      border-radius: 1rem;
      width: 77%;
      height: 77%;
      box-shadow: 0px 0px 10px $c-gray-dark;
    }
    .section-cover-button-right {
      position: absolute;
      right: -8px;
      .mdi::before {
        color: $c-secondary;
        font-size: 26px;
      }
    }
    .section-cover-button-right-disabled {
      position: absolute;
      right: -8px;
      cursor: not-allowed;
      .mdi::before {
        color: $c-gray-light;
        font-size: 26px;
      }
    }
    .section-cover-button-left-disabled {
      position: absolute;
      left: -8px;
      cursor: not-allowed;
      .mdi::before {
        color: $c-gray-light;
        font-size: 26px;
      }
    }
    .section-cover-button-left {
      position: absolute;
      left: -8px;
      .mdi::before {
        color: $c-secondary;
        font-size: 26px;
      }
    }
    .all-time-section {
      position: absolute;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -2rem;
      top: -0.3rem;
      background-color: #ff8710;
      color: white;
      transform: rotate(30deg);
      width: 6rem;
      height: 0.8rem;
      font-size: 12px;
    }
  }
  width: 100%;
  .tour-header {
    font-size: 18px;
  }
  .cards-container {
    width: 50%;
  }
  .tour-number {
    width: 100%;
    height: 100%;
    padding-top: 5rem;
    display: flex;
    justify-content: center;
    flex-direction: column-reverse;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
    color: white;
  }
  .active-tour {
    font-size: 14px;
    color: $c-secondary;
  }
  .tour-padding {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
  .trips-card-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.5rem;
    width: 100%;
    border-top: 1px solid $c-gray-light;
    border-bottom: 1px solid $c-gray-lighter;
    &:hover {
      background-color: #fff4f4;
      border: 1.2px solid $c-gray-dark;
      border-style: dashed;
    }
    .cards-footer {
      padding: 0.5rem;
    }
    .trip-cover-image {
      width: 100%;
      height: 130px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  .unpublish-card-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 0.5rem;
    width: 100%;
    border-top: 1px solid $c-gray-light;
    border-bottom: 1px solid $c-gray-lighter;
    .cards-footer {
      padding: 0.5rem;
    }
    .trip-cover-image {
      width: 100%;
      height: 130px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  .cards-wrapper {
    cursor: move;
    overflow: hidden;
  }
  .unpublish-wrapper {
    width: 99%;
    overflow: hidden;
    @include d-flex-column;
    cursor: pointer;
    border-radius: 1rem;
    border: 1px solid $c-gray-default;
    color: $c-gray-primary;
    .cards-header {
      @include d-flex-column;
      padding: 0.5rem;
    }
    .cards-body {
      position: relative;
      @include d-flex-column;
      height: 100%;
      .item-wrapper {
        max-width: calc(100% - 45px);
        width: 100%;
      }
    }
    .cards-footer {
      @include d-flex-column;
    }
    .item-wrapper {
      @include d-flex;
      flex-wrap: wrap;
      .item-header {
        @include d-inline-flex-center;
        font-weight: bold;
        margin-bottom: 0.25rem;
      }
      .item-body {
        @include d-inline-flex-center;
        margin-bottom: 0.25rem;
        padding: 0 0.25rem;
        &.new-line {
          @include d-flex;
          padding: 0 0.5rem;
        }
      }
    }
  }
  .section-cover-wrapper {
    width: 97%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .section-cover-image {
      background-color: cornflowerblue;
      border-radius: 1rem;
      width: 55%;
      height: 100%;
      position: absolute;
      z-index: 2;
      box-shadow: 0px 5px 10px $c-gray-light;
    }
    .section-cover-placeholder {
      position: absolute;
      background-color: #313135;
      border-radius: 1rem;
      width: 67%;
      height: 87%;
      z-index: 1;
      box-shadow: 0px 0px 10px $c-gray-dark;
    }
    .section-cover-placeholder2 {
      position: absolute;
      background-color: #707882;
      border-radius: 1rem;
      width: 77%;
      height: 77%;
      box-shadow: 0px 0px 10px $c-gray-dark;
    }
    .section-cover-button-right {
      position: absolute;
      right: -8px;
      .mdi::before {
        color: $c-secondary;
        font-size: 26px;
      }
    }
    .section-cover-button-left {
      position: absolute;
      left: -8px;
      .mdi::before {
        color: $c-secondary;
        font-size: 26px;
      }
    }
    .all-time-section {
      position: absolute;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -2rem;
      top: -0.3rem;
      background-color: #ff8710;
      color: white;
      transform: rotate(30deg);
      width: 6rem;
      height: 0.8rem;
      font-size: 12px;
    }
  }
  .drag-card-wrapper {
    display: flex;
    width: 100%;
    &.fake-dragging-item {
      pointer-events: none;
      .trip-card-wrapper {
        width: 50%;
      }
    }
  }
}
