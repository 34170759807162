@import '../../../../../Assets/Theme/mixins.scss';



.TotalStats{
  width: 86%;
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff;
  
  .MuiGrid-container{   
    justify-content:center;
  }
      
}

.Stats {
    box-shadow: 0px 25px 30px #0000001a;
    border-radius: 10px;
    opacity: 1;
    min-width: 245  px;
    position: relative;
    min-height: 144px;
  }

  .StatsTitleDiv {
    display: flex;
    .titleParagraph {
      width: 100%;
      flex-basis: 100%;
      padding: 0 1.5rem;
      text-align: start;
      margin-top: 20px;
    }
  }

.usersStats{
  background-image: url('../../../../../Assets/Images/DashboardImages/usersChart.png');
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 55px;
  width:100px;
  height: 40px;
  position: relative;
}
.greenstats{
    background-image: url('../../../../../Assets/Images/DashboardImages/greenChart.png');
    background-repeat: no-repeat;
    margin: auto;
    margin-top: 55px;
    width:100px;
    height: 40px;
    position: relative;
  }
  .redstats{
    background-image: url('../../../../../Assets/Images/DashboardImages/redChart.png');
    background-repeat: no-repeat;
    width:100px;
    height: 40px;
    margin: auto;
    margin-top: 55px;
    position: relative;
  }


.statsDiv{
    margin-top:36px;
    padding: 0 0.5rem;
    .statsData{
        font-size:larger;
        font-weight: bold;
        color: black
    }
    .statsPercentage{
        font-size: small;
    }
}






