@import '../../../../Assets/Theme/variables.scss';

.sliderDialogs {
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiDivider-root {
    width: 200px;
    margin: 0px;
    margin-left: -30px;
  }
  .dialogTitle {
    padding-bottom: 10px;
    .MuiTypography-root {
      padding-left: 10px;
      padding-bottom: 10px;
      font-size: 16px !important;
      color: $c-blue;
    }
  }
  .sameCityField {
    margin-top: 20px;
    margin-bottom: -15px;
  }
  .textField {
    display: inherit;
    padding-top: 15px;
    padding-bottom: 0px;
    .MuiTypography-root {
      padding-bottom: 10px;
      // font-size: 16px !important;
      color: $c-gray-dark;
    }
  }
  .checkBox {
    .MuiTypography-root {
      // font-size: 16px !important;
      color: $c-gray-dark;
    }
  }
  .selectData {
    padding-bottom: 20px;
    .MuiSvgIcon-root {
      font-size: 18px;
      color: $c-primary;
    }
    .MuiOutlinedInput-root {
      border-radius: 10px;
      display: flex;
      flex-direction: row-reverse;
    }
  }
  .activeField {
    .MuiTypography-root {
      padding-bottom: 0px !important;
    }
  }
  .nextDialog {
    .theme-solid {
      margin-bottom: 50px !important;
      width: 192px !important;
      height: 35px !important;
    }
  }
}
.doneBtnContainercaradd {
  .MuiGrid-container {
    margin-top: 10px  !important;
    background: $c-primary !important;
  }
}
.doneBtnContainercaradddisable {
  .MuiGrid-container {
    margin-top: 10px  !important;
    background: #999999 !important
  }
}
.doneBtn {
  display: contents;
  .theme-solid {
    height: 56px !important;
    width: 100% !important;
    border-radius: 0px !important;
    background-color: $c-primary !important;
  }
}
.diloge {
  .MuiPaper-root {
    min-width: 800px !important;
    border-radius: 17px !important ;
    -webkit-border-radius: 17px !important ;
    -moz-border-radius: 17px !important ;
    -ms-border-radius: 17px !important ;
    -o-border-radius: 17px !important ;
  }

  .action {
    margin-top: 10px;
  }
  .actiongroup {
    margin-top: 20px;
  }
}

.clearbutton
{
  cursor: pointer;
  text-justify: center;
  color: $c-primary;
}


.colorfiled
{
  width: 30%;
}
.vald
{
  color:#f44336 !important ;
}
.spaceon
{
  margin-top: 17px  !important ;
}
