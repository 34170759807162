@import '../../Assets/Theme/variables.scss';
@import '../../Assets/Theme/mixins.scss';
.date-range-picker-component {
  @include d-flex-column;
  width: 100%;
  .display-wrapper {
    @include d-flex-v-center-h-between;
    width: 100%;
    padding: 0.25rem 0.5rem;
    min-width: 287px;
    border-radius: 0.5rem;
    &.wide-ovel {
      min-height: 32px;
      border-radius: 1rem;
    }
    border: 1px solid $c-gray-default;
    &.has-error {
      border-color: $c-danger;
      .mdi,
      .description-text {
        color: $c-danger !important;
      }
      .start-date-error,
      .end-date-error {
        color: $c-danger;
      }
    }
  }
  .error-wrapper{
    color: $c-danger;
    // font-size: 13px;
  }
}
