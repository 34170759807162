@import '../../../../../../Assets/Theme/mixins.scss';
.car-activity-management-dialog {
  .form-item {
    @include d-inline-flex;
    flex-wrap: wrap;
    padding: 0 0.5rem 1rem;
    width: 50%;
    &.mxw-50 {
      max-width: 50%;
    }
  }
}
