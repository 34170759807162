@import '../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../Assets/Theme/mixins.scss';
.car-activities-card-component-wrapper {
  @include d-flex;
  flex-wrap: wrap;
  justify-content: center;
  .cards-wrapper {
    min-height: 200px;
  }
  .cards-header {
    padding: 0 1rem 0.5rem !important;
  }
  .status-wrapper {
    @include d-flex-v-center;
    border-radius: 0 0 1rem 1rem;
    padding: 0.25rem 0.5rem;
    min-height: 35px;
    span {
      min-width: 50px;
    }
    span:first-child {
      width: 100%;
      padding-left: 50px;
      @include rtl {
        padding-left: 0.5rem;
        padding-right: 50px;
      }
    }
    .item-header {
      color: #fff;
    }
  }
  .attached-wrapper {
    max-width: 100%;
    overflow-x: auto;
  }
  .attached-boxes-wrapper {
    display: flex;
    flex: 1 1 auto;
    padding-bottom: 0.25rem;
    max-width: 100%;
    justify-content: space-between;
    .attached-box-item {
      @include d-inline-flex-center;
      margin: 1rem 0.5rem 0;
      position: relative;
      border-radius: 1rem;
      white-space: nowrap;
      padding: 0.5rem;
      min-height: 35px;
      border: 1px solid $c-gray-default;
      color: $c-secondary;
      .attached-box-text {
        @include d-flex-center;
      }
      &:after {
        content: ' ';
        height: 1.01rem;
        width: 1px;
        background-color: $c-gray-secondary;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -1.1rem;
      }
    }
  }
  .car-activities-card-wrapper {
    @include d-inline-flex-column;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    width: 25%;
    min-width: 300px;
    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
      width: 100%;
    }
    .cards-footer {
      padding: 0.5rem;
    }
  }
}
