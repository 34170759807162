@import '../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../Assets/Theme/mixins.scss';

.points-tours-wrapper {
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  padding-left: 3rem;
  @include rtl {
    padding-left: 2rem;
    padding-right: 3rem;
  }
  .points-tours-title {
    font-size: 20px;
    padding-bottom: 1rem;
  }
  .points-tours-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    .points-tours-cards {
      background-color: white;
      margin: 0.5rem;
      border: 0.18rem solid #e8e8e8;
      border-radius: 1rem;
      width: 12rem;
      height: auto;
      padding: 1rem;
      box-shadow: 0px 3px 30px #00000029;
    }
    .favourite-heart {
      background-color: white;
      .MuiIconButton-root {
        border-radius: 20rem;
        box-shadow: 0px 3px 6px #00000029;
        float: right;
        @include rtl {
          float: left;
        }
      }
    }
    .points-card-add-cart {
      .MuiButton-root {
        text-transform: capitalize;
        color: white;
        font-weight: bold;
        font-size: 10px;
        border-radius: 20rem;
        padding-left: 1rem;
        padding-right: 1rem;
        background: rgb(255, 127, 24);
        background: linear-gradient(
          90deg,
          rgba(255, 127, 24, 1) -15%,
          rgba(255, 19, 100, 1) 43%,
          rgba(43, 6, 73, 1) 300%
        );
        float: left;
        @include rtl {
          float: right;
        }
      }
    }
    .points-card-title {
      font-size: 13px;
      font-weight: bold;
    }
    .tour-price {
      font-weight: bold;
      color: $c-secondary;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    .points-card-description {
      display: flex;
      font-size: 12px;
    }
    .points-card-timeline {
      margin-left: -1rem;
      @include rtl {
        margin-left: 0;
        margin-right: -1rem;
      }
      .points-card-timeline-body {
        margin-left: -0.5rem;
        @include rtl {
          margin-left: 0;
          margin-right: -0.5rem;
        }
        .item-card-wrapper {
          display: flex;
          font-size: 12px;
          .item-card-header {
            display: flex;
          }
          .item-card-body {
            display: flex;
          }
          .mdi::before {
            font-size: 14px;
            margin-top: 0.12rem;
          }
        }
        .item-card-image {
          .img-center {
            border-radius: 1rem;
            width: 8rem;
            height: 3rem;
          }
        }
        .rating-wrapper {
          padding-left: 0.1rem;
          @include rtl {
            padding-left: 0;
            padding-right: 0.1rem;
          }
          display: flex;
          .MuiRating-root {
            font-size: 1rem;
          }
          .rating-amount {
            font-size: 10px;
            width: 4rem;
            margin-top: 0.12rem;
            padding-left: 0.2rem;
            @include rtl {
              padding-left: 0;
              padding-right: 0.2rem;
            }
          }
        }
      }
    }
  }
}
