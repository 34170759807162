@import '../../../../../Assets/Theme/mixins.scss';

.newUser {
  background-color: #ffffff;
  border-radius: 20px;
position: relative;
  @include laptops-xxl-max{
    width:80%
  }
  @include tablets-max {
    width: 86%;
}
}
.User {
  margin: 15px 0px 15px 0px;
  display: flex;
  .loadable-user-image {
    margin: 0 20px 20px 20px;
    background-size: contain;
    width: 60px;
    height: 60px;
  }
  .userImg {
    img {
      width: 60px;
      height: 60px;
    }
  }
  .UsersName {
    font-weight: 900;
  }
}

.NewUserTitle {
  display: flex;
  .NewUserPargraph {
    width: 100%;
    flex-basis: 100%;
    padding: 0 2rem;
    text-align: start;
    margin-top: 5px;
  }
}
