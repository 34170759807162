@import '../../Assets/Theme/variables.scss';
@import '../../Assets/Theme/mixins.scss';
.time-range-picker-component {
  @include d-flex-column;
  width: 100%;
  .display-wrapper {
    @include d-flex-v-center-h-between;
    width: 100%;
    min-width: 274px;
    padding: 0.25rem 0.5rem;
    border: 1px solid $c-gray-default;
    border-radius: 0.5rem;
    &.wide-ovel {
      min-height: 32px;
      border-radius: 1rem;
    }
    border: 1px solid $c-gray-default;
    &.has-error {
      border-color: $c-danger;
      .mdi,
      .description-text {
        color: $c-danger !important;
      }
      .start-date-error,
      .end-date-error {
        color: $c-danger;
      }
    }
  }
  .error-wrapper {
    color: $c-danger;
  }
}
.time-range-pickers-wrapper {
  @include d-flex-center;
  @include phones-max {
    flex-wrap: wrap;
  }
  .picker-section {
    @include d-inline-flex-column;
    flex-wrap: wrap;
    width: 50%;
    padding: 0.25rem 0.5rem;
    @include phones-max {
      width: 100%;
    }
  }
  .MuiPickersToolbar-toolbar {
    border-radius: 0.5rem 0.5rem 0 0;
  }
  .MuiPickersBasePicker-pickerView {
    border: 1px solid $c-gray-default;
    border-radius: 0 0 0.5rem 0.5rem;
  }
}
