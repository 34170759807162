@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';

.section-view-preview-wrapper {
}
.preview-dialog {
  font-size: 16px;
  .dialog-close-button {
    .MuiIconButton-root {
      margin-top: -3rem;
      background-color: white;
      color: $c-danger;
      .mdi {
        margin-right: -0.3px;
        margin-top: -0.1px;
      }
    }
  }
  .MuiPaper-root {
    background-color: transparent !important;
    min-width: 100%;
    height: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    box-shadow: 0 0 0 0;
  }
  .preview-dialog-website {
    overflow-y: auto;
    background-color: white;
    border: 15px solid $c-gray-light;
    border-radius: 2rem;
    width: 65%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .preview-dialog-mobile {
    overflow-y: auto;
    background-color: white;
    border: 15px solid $c-gray-light;
    border-radius: 2rem;
    width: 25%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
