@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';
.tours-management-view {
  padding: 0.5rem;
  .second-filter-section {
    @include d-flex-v-center-h-end;
    .section {
      @include d-inline-flex;
      width: inherit !important;
      @include tablets-max {
        width: 100% !important;
      }
    }
    .MuiAutocomplete-inputRoot {
      min-width: 200px;
      border: none !important;
      box-shadow: none !important;
      .MuiAutocomplete-input::placeholder {
        color: $c-black-light;
      }
    }
  }
  .filter-title {
    @include tablets-max {
      margin-bottom: 1rem;
    }
  }
  .trips-list-wrapper {
    background-color: #fff;
    width: 66.666%;
    margin-bottom: 1rem;
    @include tablets-max {
      width: 100%;
    }
    @include d-inline-flex;
    padding: 0 0.5rem;
    overflow-x: hidden;
    transition: all 0.8s linear;
    left: 0;
    @include rtl {
      right: 0;
      left: initial;
    }
    &.is-back {
      position: absolute;
      padding: 0;
      left: -100%;
      @include rtl {
        right: -100%;
        left: initial;
      }
      // animation: trips_list_slide_animation 1s linear 0s 1 normal;
    }
  }
  .tour-form-wrapper {
    @include d-inline-flex;
    position: relative;
    width: 33.3333%;
    @include tablets-max {
      width: 100%;
    }
    padding: 0 0.5rem;
    vertical-align: top;
    flex-direction: column;
    // &.is-open {
    //   right: 0;
    //   transition: 0.8s;
    // }
    // &.is-closed {
    //   position: absolute;
    //   right: 62%;
    //   transition: 0.8s;
    // }
  }
  .trip-header {
    @include tablets-max {
      text-align: center;
    }
  }
  .trips-view {
    padding: 1rem 0.5rem 0;
  }
  .form-content {
    padding: 1rem 1rem 0.5rem;
    border-radius: 1rem 1rem 0 0;
    border: 1px solid $c-gray-default;
    height: 100%;
  }
  .drop-navigator {
    border: 1px solid $c-success;
    border-radius: 1rem;
    pointer-events: none;
    width: 100%;
    margin-bottom: 1rem;
    @include d-flex-center;
    min-height: 30px;
    padding: 0.5rem;
    &.not-allowed {
      border-color: $c-danger;
    }
    &.is-drop {
      border-width: 2px;
      font-weight: bold;
    }
  }
  .form-footer {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-radius: 0 0 1rem 1rem;
    border: 1px solid $c-gray-default;
    box-shadow: 0 -3px 6px 0px rgba(0, 0, 0, 0.08);
    .MuiButtonBase-root {
      width: 60%;
    }
  }
  .trip-total {
    @include d-flex-v-center-h-end;
    padding: 0.5rem 0 0;
  }
  .trips-view {
    border: 1px solid $c-gray-default;
    border-radius: 1rem;
    height: 100%;
  }
  .tour-trips-wrapper {
    @include d-flex;
    flex-wrap: wrap;
    overflow-x: hidden;
  }
  .tour-trips-timeline-wrapper {
    height: calc(100vh - 165px);
    overflow-y: auto;
  }
  .tours-section-wrapper {
    @include d-inline-flex;
    flex-direction: column;
    // height: calc(100vh - 15px);
    overflow-y: auto;

    position: absolute;
    // width: 0;
    padding: 0;
    overflow-x: hidden;
    right: 100%;
    width: 66.666%;
    @include tablets-max {
      width: 100%;
    }
    // transform: translateX(100%);
    @include rtl {
      // transform: translateX(-100%);
      left: 100%;
      right: initial;
    }
    transition: all 0.8s linear;
    z-index: 1;
    &.is-back {
      padding: 0 0.5rem;
      margin-bottom: 1rem;
      right: 0;
      @include rtl {
        // transform: translateX(-100%);
        left: 0;
        right: initial;
      }
      position: relative;
    }
    margin-bottom: 1rem;
    border-radius: 1rem;
  }
  .tours-section-item {
    background-color: $c-white;
    margin-bottom: 1rem;
    padding: 1rem;
    min-height: 10rem;
    width: 100%;
    border: 1px solid $c-gray-default;
    border-radius: 1rem;
  }
}
.select-wrapper.MuiFormControl-root.theme-solid.filter-changes.tour-types-check
  .selects
  .MuiSelect-root {
  white-space: normal;
}
