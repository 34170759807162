@import '../../../../../Assets/Theme/variables.scss';

.sliderDialogs {
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiDialogActions-root {
    justify-content: center;
  }
  .cancelBtn {
    display: contents;
    .MuiButton-root {
      text-transform: capitalize;
      color: $c-blue;
      font-size: 14px;
    }
  }
  .deleteBtn {
    display: contents;
    .MuiButton-root {
      text-transform: capitalize;
      color: $c-danger;
      font-size: 14px;
    }
  }
  .MuiDivider-root {
    width: 200px;
    margin: 0px;
    margin-left: -30px;
  }
  .cropperBox {
    box-shadow: 3px 3px 6px #00000033;
  }
  .dialogTitle {
    padding-bottom: 10px;
    .MuiTypography-root {
      padding-left: 10px;
      padding-bottom: 10px;
      font-size: 16px !important;
      color: $c-blue;
    }
  }
  .editImage {
    width: 250px;
    height: 450px;
    background-color: $c-blue;
  }
  #upload-photo {
    display: none;
  }
  .textField {
    padding-top: 15px;
    padding-bottom: 0px;
    .MuiTypography-root {
      padding-bottom: 10px;
      // font-size: 16px !important;
      color: $c-gray-dark;
    }
    .MuiButton-root {
      text-transform: capitalize;
      color: $c-blue;
    }
  }
  .selectData {
    padding-bottom: 20px;
    .MuiSvgIcon-root {
      font-size: 18px;
      color: $c-primary;
    }
    .MuiOutlinedInput-root {
      border-radius: 10px;
      display: flex;
      flex-direction: row-reverse;
    }
  }
  .activeField {
    .MuiTypography-root {
      padding-bottom: 0px !important;
    }
  }
  .DisableddoneBtn {
    background: #999999 !important;
  }
  .doneBtnContainer {
    .MuiGrid-container {
      margin-top: 10px;
      background: $c-primary !important;
    }
  }
  .doneBtn {
    display: contents;
    .theme-solid {
      height: 56px !important;
      width: 100% !important;
      border-radius: 0px !important;
      background-color: $c-primary !important;
    }
  }
  .imageUpload {
    margin-top: 7px;
    margin-left: 5px;
    color: $c-gray-light;
  }
}
