@import '../../../../Assets/Theme/variables.scss';
@import '../../../../Assets/Theme/mixins.scss';
.trips-management-view {
  .trip-header {
    @include tablets-max {
      text-align: center;
    }
  }
  .form-rows {
    @include d-flex;
    @include small-phones-max {
      flex-wrap: wrap;
      .cover-image-wrapper {
        order: 0 !important;
        justify-content: center;
      }
    }
    .form-row {
      @include d-inline-flex;
      align-items: flex-start;
      padding: 0 0.5rem 0.5rem;
      flex-wrap: wrap;
      width: 100%;
      order: 1;
      &.cover-image-wrapper {
        max-width: 116px;
        @include small-phones-max {
          max-width: 100%;
        }
      }
    }
  }
  .form-item {
    padding: 0.5rem;
    @include d-inline-flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    width: 25%;
    @include laptops-l-max {
      width: 33.333333%;
    }
    @include tablets-max {
      width: 50%;
    }
    @include small-phones-max {
      width: 100%;
    }
    .w-50 {
      width: 50%;
    }
  }
  .trip-form-content {
    height: initial;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
