@import '../../../../../Assets/Theme/mixins.scss';
@import '../../../../../Assets/Theme/variables.scss';

.trip-card-conponent-wrapper {
  @include d-flex;
  flex-wrap: wrap;
  justify-content: center;
  .loadable-image-name {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tour-header {
    font-size: 18px;
  }
  .num-of-tours {
    color: $c-secondary;
  }
  .card-date {
    color: $c-secondary;
  }
  .section-load-image {
    width: 100%;
    height: 130px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    position: absolute !important;
    top: 0;
  }
  .preview-hover {
    z-index: 99;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 17px;
    color: white;
    text-align: center;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .preview-wrapper {
      width: 80%;
      font-weight: bold;
      padding-top: 0.3rem;
    }
    &:hover {
      opacity: 1;
      backdrop-filter: saturate(130%) blur(3px);
      z-index: 9;
      .preview-text {
        font-size: 16px;
        font-weight: bold;
      }
      .preview-icon {
        .mdi::before {
          font-size: 40px;
        }
        .hover-tour-number {
          padding-top: 0.2rem;
          font-size: 13px;
          font-weight: bolder;
          color: white;
          text-align: center;
        }
      }
    }
  }
  .tour-name-wrapper {
    display: flex;
    justify-content: center;
    width: 80%;
    position: relative;
    .tour-number {
      font-size: 13px;
      font-weight: bolder;
      color: white;
      text-align: center;
      padding-bottom: 0.7rem;
    }
  }

  .active-tour {
    font-size: 14px;
    color: $c-secondary;
  }
  .inactive-tour {
    font-size: 14px;
    color: $c-danger;
  }
  .tour-padding {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
  .trip-card-wrapper {
    position: relative;
    @include d-inline-flex;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    width: 15%;
    min-width: 300px;
    @include laptops-l-max {
      width: 20%;
    }
    @include laptops-max {
      width: 30%;
    }
    @include tablets-max {
      width: 40%;
    }
    .cards-footer {
      padding: 0.5rem;
    }
    .trip-cover-image {
      width: 100%;
      height: 130px;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
  }
  .item-actions {
    .actions {
      top: -25% !important;
      @include rtl {
        margin-left: -0.5rem;
      }
    }
  }
  .cards-wrapper {
    overflow: hidden;
  }
  .section-cover-wrapper {
    width: 97%;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .section-cover-image {
      background-color: cornflowerblue;
      border-radius: 1rem;
      width: 55%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      position: absolute;
      z-index: 2;
      overflow: hidden;
      box-shadow: 0px 5px 10px $c-gray-light;
    }
    .section-cover-placeholder {
      position: absolute;
      background-color: #313135;
      border-radius: 1rem;
      width: 67%;
      height: 87%;
      z-index: 1;
      box-shadow: 0px 0px 10px $c-gray-dark;
    }
    .section-cover-placeholder2 {
      position: absolute;
      background-color: #707882;
      border-radius: 1rem;
      width: 77%;
      height: 77%;
      box-shadow: 0px 0px 10px $c-gray-dark;
    }
    .section-cover-button-right {
      position: absolute;
      right: -8px;
      .mdi::before {
        color: $c-secondary;
        font-size: 26px;
      }
    }
    .section-cover-button-right-disabled {
      position: absolute;
      right: -8px;
      cursor: not-allowed;
      .mdi::before {
        color: $c-gray-light;
        font-size: 26px;
      }
    }
    .section-cover-button-left-disabled {
      position: absolute;
      left: -8px;
      cursor: not-allowed;
      .mdi::before {
        color: $c-gray-light;
        font-size: 26px;
      }
    }
    .section-cover-button-left {
      position: absolute;
      left: -8px;
      .mdi::before {
        color: $c-secondary;
        font-size: 26px;
      }
    }
    .all-time-section {
      position: absolute;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -2rem;
      top: -0.3rem;
      background-color: #ff8710;
      color: white;
      transform: rotate(30deg);
      width: 6rem;
      height: 0.8rem;
      font-size: 12px;
    }
  }
}
