@import '../../../Assets/Theme/variables.scss';
@import '../../../Assets/Theme/mixins.scss';
.pending-tours-view {
  .view-changers-wrapper {
    @include d-inline-flex;
    margin: 0 0.5rem;
    border-radius: 1rem;
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      border: 1px solid $c-gray-light;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 1;
      width: 100%;
      pointer-events: none;
      border-radius: 1rem;
    }
    // min-height: 2.5rem;

    .btns-view.MuiButtonBase-root {
      outline: none;
      padding: 0.5rem;
      min-width: 2rem;
      min-height: 2rem;
      color: $c-black-light;

      &:first-child {
        border-radius: 1rem 0 0 1rem;

        @include rtl {
          border-radius: 0 1rem 1rem 0;
        }
      }

      &:last-child {
        border-radius: 0 1rem 1rem 0;

        @include rtl {
          border-radius: 1rem 0 0 1rem;
        }
      }

      &.active-view {
        color: $c-secondary;

        position: relative;

        &::after {
          content: ' ';
          position: absolute;
          border: 1.5px solid $c-secondary;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          pointer-events: none;
          z-index: 2;
          border-radius: 1rem;
        }
      }
    }
  }
}
