@import '../../../../../Assets/Theme/mixins.scss';
.UsersEngagement{
    background-color: #FFFFFF;
position: relative;
    border-radius: 20px; 
    @include laptops-xxl-max{
      width:90%
    }
    @include tablets-max {
      width: 86%;
  }     
}

.UsersEngagementTitle {
    display: flex;
    .UsersEngagementPargraph {
      width: 100%;
      flex-basis: 100%;
      padding: 0 2rem;
      text-align: start;
      margin-top: 5px;
    }}

    .LineChart{
      width: 100%;
      height: 100%;
    }
    .lables-Engagement{
      display: flex;
      justify-content: space-around;
    width: 60%;
    }
