@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';

.sections-management-wrapper {
  .select-tour-wrapper {
    padding: 1.2rem;
    font-size: 14px;
  }
  .drop-navigator {
    border: 1px solid $c-success;
    border-radius: 1rem;
    pointer-events: none;
    width: 100%;
    margin-bottom: 1rem;
    @include d-flex-center;
    min-height: 30px;
    padding: 0.5rem;
    &.not-allowed {
      border-color: $c-danger;
    }
    &.is-drop {
      border-width: 2px;
      font-weight: bold;
    }
  }
  .selected-tours {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 24px;
    text-align: center;
    padding: 1rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    border: 1px solid #cfcfcfc9;
    border-radius: 17px;
    min-height: 10rem;
  }
  .search-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .drag-filter {
    display: flex;
    padding-top: 0.5rem;
    .drag-filter-radio {
      margin-left: 1rem;
      .MuiFormGroup-root {
        display: block;
      }
    }
  }
  .sections-management-content-right {
    position: absolute;
    margin-top: 1rem;
    color: $c-black;
    font-size: 16px;
    border-radius: 17px;
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
    &.is-closed {
      position: fixed;
      @include laptops-xl-max {
        right: -5rem;
        width: 0;
      }
      @include rtl {
        @include laptops-xl-max {
          left: -5rem;
          right: auto;
          width: 0;
        }
      }
      transition: 0.8s;
    }
    &.is-open {
      padding-bottom: 40rem;
      @include laptops-xl-max {
        right: 1.5rem;
        width: 65%;
      }
      @include laptops-l-max {
        right: 1rem;
        width: 54%;
      }
      @include laptops-max {
        right: 5rem;
        top: 50rem;
        width: 86%;
      }
      @include tablets-max {
        right: 5rem;
        top: 50rem;
        width: 60%;
      }
      @include phones-max {
        right: 5rem;
        top: 50rem;
        width: 67%;
      }
      @include rtl {
        @include laptops-xl-max {
          left: 1.5rem;
          right: auto;
          width: 65%;
        }
        @include laptops-l-max {
          left: 5rem;
          right: auto;
          width: 40%;
        }
        @include laptops-max {
          left: 5rem;
          top: 50rem;
          right: auto;
          width: 50%;
        }
        @include tablets-max {
          left: 5rem;
          top: 50rem;
          right: auto;
          width: 60%;
        }
        @include phones-max {
          left: 5rem;
          top: 50rem;
          right: auto;
          width: 67%;
        }
      }
      transition: 0.8s;
    }
    .tours-content {
      display: flex;
      .tours-drag {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        border: 1px solid #cfcfcfc9;
        border-radius: 17px;
        min-height: calc(100vh - 230px);
        max-height: calc(100vh - 230px);
        width: 65%;
      }
      .tours-drop {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        border: 1px solid #cfcfcfc9;
        border-radius: 17px;
        overflow-y: auto;
        min-height: calc(100vh - 230px);
        max-height: calc(100vh - 230px);
        width: 35%;
      }
    }
  }
  .sections-management-content {
    .sections-content-postion {
      position: absolute;
      margin-top: 1rem;
      color: $c-black;
      padding: 1.5rem;
      font-size: 16px;
      border: 1px solid #9f9f9f;
      border-radius: 17px;
      overflow-y: auto;
      min-height: calc(100vh - 230px);
      max-height: calc(100vh - 230px);
      &.is-center {
        @include laptops-xl-max {
          left: 40rem;
          width: 30%;
        }
        @include laptops-l-max {
          left: 30rem;
          width: 40%;
        }
        @include laptops-max {
          left: 17rem;
          width: 50%;
        }
        @include tablets-max {
          left: 10rem;
          width: 60%;
        }
        @include phones-max {
          left: 5rem;
          width: 67%;
        }
        @include rtl {
          @include laptops-xl-max {
            right: 40rem;
            left: 0;
            width: 30%;
          }
          @include laptops-l-max {
            right: 30rem;
            left: 0;
            width: 40%;
          }
          @include laptops-max {
            left: 0;
            right: 17rem;
            width: 50%;
          }
          @include tablets-max {
            left: 0;
            right: 10rem;
            width: 60%;
          }
          @include phones-max {
            left: 0;
            right: 5rem;
            width: 67%;
          }
        }
        transition: 0.8s;
      }
      &.is-left {
        @include laptops-xl-max {
          left: 5rem;
          width: 30%;
        }
        @include laptops-l-max {
          left: 5rem;
          width: 40%;
        }
        @include laptops-max {
          left: 5rem;
          width: 85%;
        }
        @include tablets-max {
          left: 5rem;
          width: 82%;
        }
        @include phones-max {
          left: 5rem;
          width: 75%;
        }
        @include small-phones-max {
          left: 5rem;
          width: 80%;
        }
        @include rtl {
          @include laptops-xl-max {
            right: 5rem;
            left: 0;
            width: 30%;
          }
          @include laptops-l-max {
            right: 5rem;
            left: 0;
            width: 40%;
          }
          @include laptops-max {
            right: 5rem;
            left: 0;
            width: 50%;
          }
          @include tablets-max {
            right: 5rem;
            left: 0;
            width: 60%;
          }
          @include phones-max {
            right: 5rem;
            left: 0;
            width: 75%;
          }
          @include small-phones-max {
            right: 5rem;
            width: 80%;
          }
        }
        transition: 0.8s;
      }
    }
    .content-wrapper {
      width: 95%;
      .add-filter {
        display: flex;
        .add-button-color {
          .MuiIconButton-root {
            background-color: $c-secondary;
            color: $c-white;
            height: 1.7rem;
            margin-top: 0.1rem;
            margin-left: 1rem;
            @include rtl {
              margin-left: 0;
              margin-right: 1rem;
            }
          }
        }
        .disable-add {
          .MuiIconButton-root {
            background-color: $c-gray-light;
            color: $c-white;
            height: 1.7rem;
            margin-top: 0.1rem;
            margin-left: 1rem;
            @include rtl {
              margin-left: 0;
              margin-right: 1rem;
            }
          }
        }
      }
      .form-row {
        padding: 1rem;
        padding-bottom: 0.5rem;
      }
      .checkbox-form {
        padding-bottom: 1rem;
        padding-top: 0.5rem;
        display: block;
        font-size: 14px;
      }
      .content-description {
        padding-left: 1.5rem;
        color: $c-gray-light;
        font-size: 12px;
        width: 20rem;
      }
      .content-date {
        .MuiTypography-body1 {
          padding-bottom: 0.3rem;
        }
        .MuiOutlinedInput-adornedEnd {
          border-radius: 8px;
          display: flex;
          flex-direction: row-reverse;
          color: $c-gray-dark;
          .MuiSvgIcon-root {
            font-size: 20px;
          }
        }
      }
    }
    .content-subtitle {
      padding-top: 0.5rem;
      color: $c-gray-light;
      font-size: 12px;
    }
    .type-items {
      display: flex;
      flex-direction: column;
      .type-item {
        display: flex;
      }
      .num-of-tours {
        padding-top: 1rem;
        align-self: center;
        margin-left: -8rem;
        width: 50%;
        position: relative;
        @include rtl {
          margin-left: 0;
          margin-right: -8rem;
        }
        .MuiIconButton-root {
          background-color: transparent;
          color: $c-secondary;
          margin-left: 0.5rem;
          margin-right: 0.5rem;
        }
        .MuiButton-root {
          text-transform: capitalize;
          width: 12rem;
          border-radius: 0.4rem;
        }
        .v-line {
          background-color: $c-gray-light;
          width: 1.5px;
          height: 2.19rem;
          position: absolute;
          top: 0;
          left: -1.5rem;
          @include rtl {
            right: -1.5rem;
            left: 0;
          }
        }
        .h-line {
          background-color: $c-gray-light;
          width: 1.5px;
          height: 1.5rem;
          transform: rotate(90deg);
          position: absolute;
          top: 1.4rem;
          left: -0.79rem;
          @include rtl {
            right: -0.79rem;
            left: 0;
          }
        }
      }
    }
  }
}
