@import '../../../../Assets/Theme/variables.scss';
@import '../../../../Assets/Theme/mixins.scss';

.LoginViewWrapper {
  .box-section-wrapper {
    .box-content {
      background: transparent !important;
      border: 3px solid $c-blue-lightest !important;
      border-radius: 1rem !important;
      padding: 2.5rem 0.5rem 0 !important;
      width: 110% !important;

      .logo-wrapper {
        .logo {
          margin-left: 60px !important;
          @include phones-max {
            margin-left: 20px !important;
          }
          @include tablets-max {
            margin-left: 20px !important;
          }
        }
        .form-wrapper {
          padding: 3rem 2.5rem !important;
        }
      }

      .select-wrapper.MuiFormControl-root.theme-underline {
        .selects .MuiSelect-icon {
          display: none;
        }
      }

      .select-language {
        display: flex;
      }

      .login-select {
        .MuiInput-underline:before {
          border-bottom-color: transparent !important;
        }
        .select-wrapper.MuiFormControl-root {
          background-color: transparent !important;
        }
      }

      .MuiDivider-flexItem {
        background-color: $c-secondary;
        margin-right: 5px;
        margin-left: 5px;
        width: 1.5px;
      }

      .forget-password-text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }

      .forget-password {
        margin-top: 2.6rem;
        font-size: 1.5rem;
        color: $c-gray-dark;
      }

      .cancel-forget-passwrod {
        border: 1px solid $c-secondary;
        width: 40%;
      }

      .forget-password-radio {
        .radio-groups-wrapper.MuiFormControl-root .radio-group-wrapper {
          flex-direction: column;
        }
        .radio-groups-wrapper.MuiFormControl-root {
          margin-bottom: 2rem;
          margin-top: -2rem;
        }
      }

      .submit-forget-password {
        background-color: $c-primary;
        color: $c-white;
        width: 40%;
      }

      .verification-text {
        max-width: 23rem !important;
        text-align: center;
      }
    }
  }
}
