.report-basket-container {
  .MuiPaper-rounded {
    padding: 0.5rem;
    border-radius: 17px !important;
  }
}
.btns-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .MuiButton-root {
    margin: 0.5rem;
    width: 100%;
    border-radius: 10px !important;
    border: solid 1px #e6e6e6 !important;
  }
}
