@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';
.agents-card-component-wrapper {
  padding: 1rem;
  .agents-card-wrapper {
    display: inline-block;
    box-sizing: inherit;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
    width: 25%;
    min-width: 300px;
    .user-name {
      color: $c-gray-dark;
    }
    .added-tours {
      font-size: 18px;
    }
    .status-icon {
      position: absolute;
      border-radius: 100%;
      height: 20px;
      width: 20px;
      bottom: 1rem;
      margin-left: 2rem;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
      border: 1px solid $c-white;
      @include rtl {
        margin-left: -2rem;
      }
    }
    .cards-wrapper {
      &:hover {
        box-shadow: 3px 3px 6px $c-gray-lightest;
      }
      position: relative;
      .cards-header {
        position: relative;
      }
      .cards-body {
        height: initial;
        .item-wrapper {
          max-width: 100%;
          padding: 0.5rem;
          border-radius: 1rem;
        }
      }
    }
    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
      width: 100%;
    }
    .cards-footer {
      padding: 0.5rem;
      align-items: center;
    }
    .item-wrapper {
      justify-content: center;
    }
    .cover-image {
      width: 95px;
      border-radius: 100%;
      box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
      height: 95px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 1rem;
    }
  }
}
