@import '../../../../../Assets/Theme/mixins.scss';

.TotalSource{
    background-color: #FFFFFF;
    border-radius:20px;
    position: relative;
    @include laptops-xxl-max{
        width:80%
      }
      @include tablets-max {
        width: 86%;
    }
    
}

.SoialMedia{
display: flex;
justify-content:space-around;
flex-wrap: wrap;
.UsersNum{
    text-align: center;
    margin: 15px 10px 15px 10px;
}
.Soial{
    text-align: center;
    margin: 15px 10px 15px 10px;
}

.SoialImg{
    margin: 15px 10px 15px 10px;
    img{
        width:60px;
        height: 60px;
    }

}
}

.TotalSourceTitle {
    display: flex;
    .TotalSourcePargraph {
      width: 100%;
      flex-basis: 100%;
      padding: 0 2rem;
      text-align: start;
      margin-top: 5px;
    }}

    .TotalSource-select{
        .select-wrapper.MuiFormControl-root.theme-solid .selects .MuiSelect-select{
          border: none;
          justify-content:flex-end;
          color:#2B0649;
          margin-right: 7px;
        }
    }
