@import '../../../../../../Assets/Theme/mixins.scss';
@import '../../../../../../Assets/Theme/variables.scss';
.slideruser {
  .cards-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    cursor: default;
    border-radius: 0rem;
    border: 0px solid #ffffff;
    color: #121212;
    -webkit-border-radius: 0rem;
    -moz-border-radius: 0rem;
    -ms-border-radius: 0rem;
    -o-border-radius: 0rem;
  }

  .CardContenater {
    padding-left: 15px;
    padding-right: 15px;
  }

  .Cardgrid {
    text-align: center;
    width: 100%;
    height: 90px;
    background-color: #f0f0f0;
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
  }

  .bigCardgridleft {
    text-align: center;
    width: 100%;
    height: 110px;
    color: #ffffff !important;
    background-color: #58acc1;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    @include rtl {
      border-bottom-right-radius: 20px;
      border-top-right-radius: 20px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .bigCardgridright {
    padding: 16px;
    text-align: center;
    width: 100%;
    height: 110px;
    color: #ffffff !important;
    background-color: #58acc1;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    @include rtl {
      border-bottom-left-radius: 20px;
      border-top-left-radius: 20px; 
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
  }

  .icon {
    color: #58acc1;
    margin-top: 20px;
    font-weight: 900;
    font-size: 30px;
  }
  .textcard {
    color: #58acc1;
    font-size: 15px;
    font-weight: 700;
  }
  .textcardsmoll {
    color: #58acc1;
    font-weight: 500;
    font-size: 13px;
  }

  .iconbig {
    font-size: 35px;
    color: #ffffff;
    margin-left: -22px;
    @include rtl {
      margin-left: 0px;
    }
  }

  .textcardbig {
    color: #ffffff;
    font-size: 15px;
    padding-top: 4px;
  }
  .textcardsmollbig {
    text-align: left !important;
    padding-top: 10px;
    padding-left: 28px;
    color: #ffffff;
    font-size: 13px;
    padding-bottom: 10px;
  }

  .textcardsmollbig2 {
    color: #ffffff;
    font-size: 13px;
  }

  .Contenater-icon {
    margin-top: 10px !important;
  }

  .state-online {
    background: rgb(66, 183, 42);
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-left: 4px;
    width: 6px;
    @include rtl {
      margin-right : 10px ;
    }
  }
  .state-offline {
    background: rgb(201, 37, 15);
    border-radius: 50%;
    display: inline-block;
    height: 6px;
    margin-left: 4px;
    width: 6px;
  }
}


 