@import '../../../../../Assets/Theme/variables.scss';

.sliderDialogs {
  .MuiOutlinedInput-root {
    border-radius: 10px;
  }
  .MuiDivider-root {
    width: 200px;
    margin: 0px;
    margin-left: -30px;
  }
  .dialogTitle {
    padding-bottom: 10px;
    .MuiTypography-root {
      padding-left: 10px;
      padding-bottom: 10px;
      font-size: 16px !important;
      color: $c-blue;
    }
  }
  .textField {
    padding-top: 15px;
    padding-bottom: 0px;
    .MuiTypography-root {
      padding-bottom: 10px;
      // font-size: 16px !important;
      color: $c-gray-dark;
    }
  }
  .checkBox {
    margin-top: 10px;
    .MuiTypography-root {
      // font-size: 16px !important;
      color: $c-gray-dark;
    }
  }
  .selectData {
    padding-bottom: 20px;
    .MuiSvgIcon-root {
      font-size: 18px;
      color: $c-primary;
    }
    .MuiOutlinedInput-root {
      border-radius: 10px;
      display: flex;
      flex-direction: row-reverse;
    }
  }
  .activeField {
    .MuiTypography-root {
      padding-bottom: 0px !important;
    }
  }
}
