.timeline-wrapper {
  position: relative;
  &.is-draggable {
    padding-left: 0;
    padding-right: 0;

    cursor: move !important;
    .cards-body {
      cursor: move !important;
    }
    .MuiTimelineItem-content {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
