@import '../../../../../Assets/Theme/mixins.scss';

.SubNav{
box-shadow:0px 3px 12px #00000022;
opacity: 1;
padding: 0;
font-size: 16px;
border-radius:10px;
background-color: #FFFFFF 0% 0% no-repeat padding-box;
width:86% ;
margin: auto;
margin-top: 20px;
background-color: white;

@include phones-max {
    width: var(--divWidth) ;
    position: fixed;
    bottom: 0;
    text-align: -webkit-center;
}

.SubNavBorder{
    justify-content: space-between;
    display:flex;
    padding:0px 25px 0px 25px;

    .btns{
        min-width: 0;
        margin: 0; 
    }

    @include phones-max {
        padding:0px;
        display:flex;
        flex-wrap: wrap;
        width:90%;
        justify-content: space-around;
        margin: 0px 15px 15px 0px;
        margin-top: 15px;
        }
}
}
