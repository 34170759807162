@import '../../../../../Assets/Theme/mixins.scss';
.pending-tours-management-dialog {
  .form-item {
    padding: 0.5rem;
    @include d-inline-flex;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    width: 50%;
    @include tablets-max {
      width: 100%;
    }
    @include small-phones-max {
      width: 100%;
    }
  }
}
