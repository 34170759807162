@import '../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../Assets/Theme/mixins.scss';

.mobile-rectangle-wrapper {
  padding: 2rem;
  .mobile-rectangle-title {
    font-size: 18px;
    padding-bottom: 1rem;
  }
  .tour-price {
    font-weight: bold;
    color: $c-secondary;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .points-card-description {
    display: flex;
    font-size: 14px;
    margin-top: 5.4rem;
    margin-left: 1.2rem;
  }
  .card-content-wrapper {
    height: 17rem;
    display: flex;
    overflow-x: auto;
    .mobile-rectangle-content {
      display: flex;
      .mobile-rectangle-card {
        width: 100%;
        min-width: 370px;
        display: inline-flex;
        .bottom-card {
          position: relative;
          .bottom-image {
            background-color: darkgray;
            border-radius: 1rem 1rem 1rem 4.5rem;
            @include rtl {
              border-radius: 1.5rem 1.5rem 4.5rem 1.5rem;
            }
            width: 22rem;
            height: 10rem;
            position: absolute;
            z-index: 90;
            overflow: hidden;
            .img-center {
              height: 10rem;
              width: 100%;
            }
          }
          .card-cart-button {
            position: absolute;
            right: 1rem;
            top: 3rem;
            @include rtl {
              right: auto;
              left: 1rem;
            }
            z-index: 99;
            .MuiIconButton-root {
              box-shadow: 0px 3px 15px #0000001e;
              background-color: white;
              border-radius: 20rem;
              width: 3rem;
              height: 3rem;
              .mdi::before {
                font-size: 24px;
              }
            }
          }
          .bottom-footer {
            background-color: white;
            border: 0.18rem solid #e8e8e873;
            box-shadow: 0px 3px 15px #0000001c;
            border-radius: 1.5rem;
            width: 22rem;
            float: left;
            @include rtl {
              float: right;
            }
            height: 10.5rem;
            position: absolute;
            top: 5rem;
            .rating-wrapper {
              position: absolute;
              bottom: 0.2rem;
              padding-left: 1rem;
              @include rtl {
                padding-left: 0;
                padding-right: 1rem;
              }
              .MuiRating-root {
                font-size: 1.5rem;
              }
              .rating-amount {
                flex-wrap: nowrap;
                font-size: 14px;
                width: 5rem;
                padding-left: 0.2rem;
                @include rtl {
                  padding-left: 0;
                  padding-right: 0.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
}
