.show-all-wrapper {
  .MuiAvatar-root {
    margin-right: 0.5rem;
    width: 3rem;
    height: 3rem;
  }
  .driver-image {
    margin-right: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
  }
  .driver-wrapper {
    padding: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .driver-info-container {
    height: 20rem;
    overflow-y: auto;
  }
}
