@import '../../../../Assets/Theme/mixins.scss';
.car-activities-view {
  .second-filter-section {
    .section {
      @include d-inline-flex;
      width: inherit !important;
      @include tablets-max {
        width: 100% !important;
      }
    }
    .MuiSelect-select{
      min-width: 200px;
    }
  }
}
