@import '../../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../../Assets/Theme/mixins.scss';
.offer-tour-card-component-wrapper {
  @include d-flex-center;
  flex-wrap: wrap;
  // height: calc(100vh - 335px);
  // overflow-y: auto;
  .mxw-calc {
    max-width: calc(100% - 25px) !important;
    .item-header {
      word-break: break-all;
    }
  }
  .drop-navigator {
    border: 1px solid $c-success;
    border-radius: 1rem;
    pointer-events: none;
    width: 100%;
    margin-bottom: 1rem;
    &.after {
      margin-bottom: 0;
      margin-top: 1rem;
    }
    @include d-flex-center;
    min-height: 30px;
    padding: 0.5rem;
    &.not-allowed {
      border-color: $c-danger;
    }
    &.is-drop {
      border-width: 2px;
      font-weight: bold;
    }
  }
  .distance-info {
    @include d-inline-flex-v-center;
    color: $c-secondary;
    font-weight: bold;
    padding: 0.5rem 1rem 0;
  }
  &.fake-dragging-item {
    pointer-events: none;
    .offer-tour-card-wrapper {
      width: 100%;
    }
  }
  &.is-offer {
    .offer-tour-card-wrapper {
      width: 50%;
      @include phones-max {
        width: 100%;
      }
    }
  }
  .offer-tour-card-wrapper {
    @include d-inline-flex;
    flex-wrap: wrap;
    opacity: 1;
    animation: show_animation 0.5s linear 0s 1 normal;
    width: 33.3333%;
    padding: 0 0.5rem;
    margin-bottom: 1rem;
    min-width: 233px;

    @include laptops-max {
      width: 50%;
    }
    @include phones-max {
      width: 100%;
    }
    .offer-cover-image {
      @include d-inline-flex;
      width: 100px !important;
      min-width: 100px;
      border-radius: 1rem;
      box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
      height: 100px;
      margin-left: 1rem;
      margin-right: 0.5rem;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
    }
    .cards-wrapper {
      position: relative;
      &.is-draggable {
        cursor: move;
        // * {
        //   pointer-events: none;
        // }
      }
      &.is-drag-over {
        padding-top: 25%;
        // .cards-body {
        //   pointer-events: none;
        // }
        // box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
        //   0 3px 14px 2px rgba(0, 0, 0, 0.12);
      }
      .cards-body {
        position: relative;
        padding: 1rem 0;
        pointer-events: none;
        flex-direction: row;
        .item-wrapper {
          width: 50%;
          max-width: initial;
        }
      }
    }
  }
}
// @keyframes show_animation {
//   0% {
//     opacity: 0;
//     height: 0;
//     overflow: hidden;
//   }
//   100% {
//     opacity: 1;
//     height: 100%;
//   }
// }
