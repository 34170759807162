.no-data-error {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-error-text {
    padding-left: 80px;
    display: flow-root;
    justify-content: center;
  }
  .no-error-title {
    padding-top: 30px;
    color: #31aec4;
    text-align: center;
    display: flex;
    align-items: center;
    font-size: 110px;
    font-weight: bolder;
    font-family: Poppins;
    letter-spacing: 0px;
  }
  .no-data-error-subtitle {
    width: 60%;
    color: #006186;
    font-size: 22px;
    line-height: 1cm;
    text-align: left;
  }
  .no-error-img {
    height: 390px;
  }
}
