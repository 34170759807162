.slider {
  .editbutton {
    height: 40px;
  }
  .titles {
    margin-right: 20px !important;
    padding-left: 10px;
    display: flex;
  }
  .textslabel {
    color: black;
    margin-top: 5px;
    margin-left: 10px;
  }

  .mdi-car {
    color: black;
  }
  .mdi-home {
    color: black;
  }

  .mdi-map-marker {
    color: black;
    display: flex;
  }

  .titlessingl {
    padding-left: 40px !important;

    display: flex;
    width: 161px;

    color: var(--unnamed-color-272727);
    text-align: left;
    font: Medium 13px/20px Poppins;
    letter-spacing: 0px;
    color: #272727;
    font-weight: bold;
  }
  .textslabelmain {
    padding-left: 8px;
    font-size: 16px;
    color: black;
  }
  .textsdletemain {
    padding-left: 8px;
    font-size: 16px;
    color: #31aec4;
  }
  .map {
    border-style: solid;
    border-width: 1px;
    border-radius: 20px;
    margin-top: 20px;
    margin-left: 15px;
    border-color:  rgb(132,194,231);
    width: 258px;
    height: 125px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    background: rgb(132,194,231);
    background: linear-gradient(52deg, rgba(132,194,231,1) 0%, rgba(33,84,222,0.8295693277310925) 59%, rgba(39,10,205,1) 100%);
  }
  .Buttonedits {
    margin-top: 40px;
  }
  .btcolor {
    background-color: #006186 !important;
  }
  .btns.MuiButtonBase-root.theme-solid {
    background-color: #006186 !important;
  }
}
.labelcard
{
  font-weight: 600;
}
.GoogleMaps {
  margin-top: 30px;
  margin-bottom: 30px;
  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
