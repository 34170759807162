@import '../../Assets/Theme/variables.scss';
@import '../../Assets/Theme/mixins.scss';
.order-by-wrapper {
  @include d-flex;
  width: 100%;
  .display-wrapper {
    min-width: 250px;
    @include d-flex-v-center-h-between;
    width: 100%;
    padding: 0.25rem 0.5rem;
    min-height: 32px;
    border-radius: 1rem;
    border: 1px solid $c-gray-default;
  }
}
