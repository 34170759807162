@import '../../../Assets/Theme/variables.scss';
@import '../../../Assets//Theme/mixins.scss';

.promo-code-wrapper {
  .promo-wrapper {
    padding: 0.5rem 1rem 0 !important;
    .MuiAccordionSummary-root {
      display: inline-flex;
    }
    .MuiPaper-elevation1 {
      box-shadow: 0 0 0 0 $c-white;
    }
    .MuiDivider-root {
      margin: 0 1.3rem;
    }
  }
  .public-promo {
    color: $c-secondary;
  }
  .private-promo {
    color: $c-warning;
  }
  .active-promo {
    overflow-y: auto;
    height: 13rem;
  }
  .all-promo {
    overflow-y: auto;
    height: calc(100vh - 545px);
  }
  .inputs.MuiFormControl-root {
    border-radius: 0.5rem !important;
  }
  .MuiCard-root {
    padding: 1rem 0 1rem 1rem;
    @include rtl {
      padding: 1rem 1rem 1rem 0;
    }
    cursor: pointer;
    border: 1px solid #e6e6e6;
    border-radius: 17px;
    box-shadow: 0 0 0 0 $c-white;
    &:hover {
      box-shadow: 3px 3px 6px $c-gray-lightest;
    }
  }
  .promo-detailes {
    .progresses-wrapper {
      width: 90%;
    }
  }
  .promo-icon {
    padding: 0.2rem 0;
    .mdi:before {
      font-size: 16px;
      margin-right: 0.4rem;
      @include rtl {
        margin-left: 0.4rem;
        margin-right: 0;
      }
    }
  }
  .public-button {
    .MuiButton-root {
      background-color: $c-blue-lightest;
    }
  }
  .disable-button {
    pointer-events: initial;
    cursor: not-allowed;
    .MuiButton-root {
      color: $c-gray-light;
    }
    .MuiTypography-root {
      color: $c-gray-light;
    }
    .mdi:before {
      color: $c-gray-light !important;
    }
  }
  .is-multible {
    .MuiTypography-body1 {
      font-size: 14px !important;
    }
  }
  .WAMuiChipInput-chipContainer-12 {
    padding-top: 0.4rem;
    max-width: 35rem !important;
  }
  .MuiInputBase-root:not(.MuiOutlinedInput-multiline):not(.MuiInputBase-multiline) {
    height: auto;
    max-width: 35rem !important;
  }
  .promo-generate {
    display: flex;
    align-items: baseline;
    .MuiButton-root {
      border-radius: 0.5rem;
      margin-left: -20px;
      height: 2.1rem !important;
      @include rtl {
        margin-left: 0;
        margin-right: -20px;
      }
    }
  }
  .extended-card {
    .MuiCard-root {
      padding-top: 0;
    }
  }
  .promo-name {
    padding-bottom: 0.5rem;
    .MuiTypography-body1 {
      color: $c-black;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .add-promo-name {
    .MuiTypography-body1 {
      margin-bottom: 0.5rem;
      color: $c-black;
      font-size: 20px;
      font-weight: bold;
    }
    font-size: 16px;
  }
  .promo-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 0.7rem;
    font-size: 14px;
    font-weight: bold;
    @include rtl {
      padding-left: 0.7rem;
      padding-right: 0;
    }
  }
  .promo-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    .promo-code-status {
      border-radius: 0px 0px 15px 15px;
      height: 2rem;
      width: 80%;
      margin-bottom: 1rem;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .public-active-color {
      color: $c-white;
      background-color: $c-secondary;
    }
    .public-expired-color {
      color: $c-primary;
      background-color: $c-blue-lightest;
    }
    .private-active-color {
      color: $c-white;
      background-color: $c-warning;
    }
    .private-expired-color {
      color: $c-red;
      background-color: $c-red-lighter;
    }
  }

  .card-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    @include rtl {
      align-items: flex-end;
    }
    .mdi:before {
      font-size: 18px !important;
    }
    .MuiButton-root {
      margin-top: 0.5rem;
      min-width: 3.2rem;
      height: 2rem;
      margin-right: -0.1rem;
      padding-left: 0.7rem;
      z-index: 1;
      background-color: $c-white;
      flex-direction: column;
      color: $c-secondary;
      align-items: flex-start;
      border: 1px solid #e6e6e6;
      border-radius: 17px 0px 0px 17px;
      @include rtl {
        border-radius: 0px 17px 17px 0px;
        padding-left: 0;
        padding-right: 0.7rem;
        margin-right: 0;
      }
    }
  }
  .add-promo-actions {
    padding-top: 1rem;
    justify-content: center;
    .mdi:before {
      font-size: 5rem;
      color: $c-secondary;
      padding: 0.5rem;
    }
    .MuiButton-root {
      max-width: 17.9rem;
      max-height: 12rem;
      padding: 4rem;
      border: 1px solid $c-secondary;
      border-radius: 20px;
    }
  }
}
.new-promo-description {
  .MuiTypography-body1 {
    color: $c-black;
    font-size: 14px;
    padding-bottom: 0.7rem;
    padding-top: 1rem;
  }
  .inputs {
    margin-bottom: 0.7em;
  }
}
.add-button {
  .MuiButton-root {
    width: 14rem;
  }
}
.save-promo-code {
  .MuiButton-root {
    width: 14rem;
  }
}
.add-new-promo-name {
  .MuiTypography-body1 {
    color: $c-black;
    font-size: 16px;
    font-weight: bold;
    text-transform: capitalize;
  }
  .MuiButton-root {
    text-transform: capitalize;
  }
  font-size: 14px;
}
