@import '../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../Assets/Theme/mixins.scss';

.file-wrapper {
  padding: 1rem;
}
.imagesuploder {
  .MuiPaper-rounded {
    border-radius: 15px !important;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -ms-border-radius: 15px;
    -o-border-radius: 15px;
  }
  .iconscolor {
    color: #31aec4;
  }

  .icon-headeruploder {
    margin-left: 90px !important;
    @include rtl {
      margin-right: 90px !important;
    }
  }

  .counter-text {
    margin-left: 15%;
    margin-top: 55px;
    @include rtl {
      margin-top: 55px;
      margin-right: 15%;
    }
  }
}
