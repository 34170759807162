@import '../../../../../Assets/Theme/mixins.scss';
.SalesFunnel {
  background-color: #ffffff;
  border-radius: 20px;
  position: relative;
  @include laptops-xxl-max {
    width: 80%;
  }
  @include tablets-max {
    width: 86%;
  }
}

.SalesTitle {
  display: flex;
  .SalesPargraph {
    width: 100%;
    flex-basis: 100%;
    padding: 0 2rem;
    text-align: start;
    margin-top: 5px;
  }
}
.funnel-Chart {
  position: relative;
  height: 340px;

  .canvasjs-chart-container {
    @include rtl {
      text-align: right !important;
    }
    .canvasjs-chart-canvas {
      width: 100% !important;
      height: 320px !important;
    }
    .canvasjs-chart-credit {
      display: none;
    }
  }
  @include laptops-xxl-max {
    width: 77%;
  }
  @include tablets-max {
    .canvasjs-chart-container {
      width: 100%;
      @include rtl {
        text-align: right !important;
      }
      .canvasjs-chart-canvas {
        width: 100%;
        height: 320px !important;
      }
      .canvasjs-chart-credit {
        display: none;
      }
    }
  }
}
