@import '../../../Assets/Theme/variables.scss';
@import '../../../Assets//Theme/mixins.scss';

.pageContentView .pageWrapper .pageCard .MuiCardContent-root {
  @include laptops-max {
    padding-right: 0px !important;
  }
  @include laptops-xl-max {
    padding-right: 0px !important;
  }
  @include laptops-l-max {
    padding-right: 0px !important;
  }
  @include phones-max {
    padding-right: 10px !important;
  }
  @include rtl {
    padding-right: 1rem !important;
  }
}

.pageContentView {
  .pageWrapper {
    .pageCard {
      .contentTag {
        font-weight: 600;
      }
      .pageContent {
        .MuiGrid-item {
          display: flex;
        }
        .MuiTypography-root.MuiTypography-body1.textFading {
          overflow: hidden;
          min-height: 100px;
          max-height: 140px;
          position: relative;
          &:after {
            content: ' ';
            height: 55px;
            position: absolute;
            bottom: 0;
            width: 100%;
            left: 0;
            background-image: linear-gradient(to bottom, transparent, $c-white);
          }
        }
        display: flex;
        .mdi {
          color: $c-gray-dark;
        }
        .MuiTypography-root {
          padding-bottom: 1rem;
          margin-left: 5px;
        }
      }
      .pageActions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        @include rtl {
          align-items: flex-start;
        }
        .MuiButton-root {
          margin-right: -0.6rem;
          @include rtl {
            margin-right: 0;
          }
          display: flex;
          background-color: $c-white;
          flex-direction: column;
          color: $c-secondary;
          border: 1px solid #e6e6e6;
          border-radius: 17px 0px 0px 17px;
          @include rtl {
            border-radius: 0px 17px 17px 0px;
          }
        }
      }
      .MuiSvgIcon-root {
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: -3px;
        font-size: 15px;
        @include rtl {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
      .pageName {
        .MuiTypography-root {
          padding-bottom: 1rem;
          font-size: 16px !important;
        }
      }
      .MuiCardContent-root {
        .MuiGrid-root.MuiGrid-container {
          min-height: 230px;
          max-height: 220px;
        }
      }
      .MuiCard-root {
        cursor: pointer;
        border: 1px solid #e6e6e6;
        border-radius: 17px;
        box-shadow: 0 0 0 0 $c-white;
        &:hover {
          box-shadow: 3px 3px 6px $c-gray-lightest;
        }
      }
    }
  }
}
.ql-editor {
  max-height: 200px;
  min-height: 200px;
}
.sliderSidePanel {
  .body-content-height {
    height: 45rem;
    overflow-y: auto;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  .mdi-text {
    margin-top: 10px;
  }
  .contentTag {
    font-weight: 600;
    margin-right: 5px;
    margin-left: 5px;
  }
  .bodySection {
    margin-top: 10px;
  }
  .editBtn {
    margin-top: 10px;
  }
}
