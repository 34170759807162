@import '../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../Assets/Theme/mixins.scss';
.offer-tours-component-wrapper {
  background-color: #fff;
  width: 100%;

  @include d-flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  transition-property: left, right;
  transition-duration: 0.8s;
  transition-timing-function: linear;
  left: 0;
  position: relative;
  @include rtl {
    right: 0;
    left: initial;
  }
  .tours-types-item-wrapper {
    position: relative;
    @include d-inline-flex-center;
    padding: 0.1rem 0.5rem;
    border: 1px solid $c-secondary;
    border-radius: 1rem;
    color: $c-secondary;
    margin: 0 0.5rem;
    margin-bottom: 1rem;
    min-width: 100px;
  }
  &.is-next {
    left: 100%;
    position: fixed;
    padding: 0;
    @include rtl {
      right: 100%;
      left: initial;
    }
    // animation: trips_list_slide_animation 1s linear 0s 1 normal;
  }
  .tours-list-wrapper {
    width: 66.666%;
    margin-bottom: 1rem;
    @include tablets-max {
      width: 100%;
    }
    .tours-view {
      border-radius: 1rem;
      border: 1px solid $c-gray-default;
      padding: 1rem 0.5rem;
    }
    // &.is-open {
    //   left: 0;
    //   transition: 0.8s;
    // }
    // &.is-closed {
    //   // position: absolute;
    //   left: -100%;
    //   transition: 0.8s;
    // }
    @include d-inline-flex;
    padding: 0 0.5rem;
  }
  .drag-wrapper {
    @include d-flex-column;
    height: calc(100vh - 335px);
    min-height: 395px;
    overflow-y: auto;
  }
  .drop-wrapper {
    @include d-inline-flex;
    position: relative;
    width: 33.3333%;
    height: 100%;
    @include tablets-max {
      width: 100%;
    }
    padding: 0 0.5rem;
    vertical-align: top;
    flex-direction: column;
    .drop-content-wrapper {
      border: 1px solid $c-gray-default;
      border-radius: 1rem;
      // height: 100%;
      @include d-inline-flex-column;
      .drop-header {
        padding: 0.5rem 1rem;
      }
      .drop-content {
        padding: 0 0.5rem;
        height: calc(100vh - 295px);
        min-height: 435px;
        overflow-y: auto;
      }
      .drop-footer {
        box-shadow: 0px -5px 6px rgba(0, 0, 0, 0.29);
        background-color: $c-white;
        padding: 0.5rem;
        @include d-flex-center;
        width: 100%;
        border-radius: 0 0 1rem 1rem;
      }
    }
    // &.is-open {
    //   right: 0;
    //   transition: 0.8s;
    // }
    // &.is-closed {
    //   position: absolute;
    //   right: 62%;
    //   transition: 0.8s;
    // }
  }
}
