@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';

.re-order-wrapper {
  .re-order-content {
    border-radius: 1.5rem;
    height: calc(100vh - 240px);
    display: flex;
    overflow: hidden;
    .re-order-mobile {
      border: 1px solid $c-gray-light;
      border-right: 1px solid $c-gray-lighter;
      border-radius: 1rem 0 0 1rem;
      height: 100%;
      width: 33.33%;
      @include rtl {
        border-radius: 0 1rem 1rem 0;
        border-left: 1px solid $c-gray-lighter;
        border-right: 1px solid $c-gray-light;
      }
      .re-order-mobile-title {
        border-bottom: 1px solid $c-gray-light;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: black;
        .mdi::before {
          color: $c-secondary;
          font-size: 22px;
        }
      }
      .re-order-mobile-content {
        overflow-y: auto;
        height: 95%;
      }
    }

    .re-order-website {
      border: 1px solid $c-gray-light;
      height: 100%;
      width: 33.33%;
      .re-order-website-title {
        border-bottom: 1px solid $c-gray-light;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: black;
        .mdi::before {
          color: $c-secondary;
          font-size: 22px;
        }
      }
      .re-order-website-content {
        overflow-y: auto;
        height: 95%;
      }
    }

    .re-order-unpublish {
      cursor: pointer !important;
      border: 1px solid $c-gray-light;
      border-left: 1px solid $c-gray-lighter;
      border-radius: 0 1rem 1rem 0;
      height: 100%;
      width: 33.33%;
      @include rtl {
        border-radius: 1rem 0 0 1rem;
        border-right: 1px solid $c-gray-lighter;
        border-left: 1px solid $c-gray-light;
      }
      .re-order-unpublish-title {
        border-bottom: 1px solid $c-gray-light;
        height: 2.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: $c-danger;
        font-weight: bold;
      }
      .re-order-unpublish-content {
        overflow-y: auto;
        height: 95%;
      }
    }
  }
}
