.no-content-result {
  padding-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-content-text {
    display: flow-root;
    padding: 150px;
    padding-left: inherit;
  }
  .no-content-title {
    color: #31aec4;
    text-align: left;
    display: flex;
    align-items: center;
    font-size: 50px;
    font-weight: bolder;
    font-family: Poppins;
    letter-spacing: 0px;
  }
  .no-content-result-subtitle {
    color: #006186;
  }
  .no-content-img {
    position: relative;
    bottom: 30px;
    height: 350px;
    max-width: 100%;
  }
}
