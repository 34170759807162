@import '../../Assets/Theme/variables.scss';
@import '../../Assets/Theme/mixins.scss';
.facebook-gallery-wrapper {
  @include d-flex-column-center;
  .facebook-gallery-active-wrapper {
    width: 100%;
    padding: 0 0.5rem;
    .facebook-gallery-active-image-wrapper {
      height: 550px;
      width: 100%;
      margin-bottom: 1rem;
      background-color: $c-black;
      border-radius: 1rem;
      @include d-flex-center;
      .facebook-gallery-active-image {
        // width: 100%;
        min-width: 290px;
        min-height: 290px;
        max-height: 550px;
        border-radius: 1rem;
        // height: 100%;
      }
    }
  }
  .facebook-gallery-thumbnail-wrapper {
    @include d-flex;
    width: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .facebook-gallery-thumbnail-items-wrapper {
      @include d-flex;
      .facebook-gallery-thumbnail-item {
        @include d-inline-flex;
        margin: 0 0.5rem;
        width: 100px;
        height: 100px;

        .facebook-gallery-thumbnail-image {
          width: 100%;
          height: 100%;
          border-radius: 0.25rem;
          filter: brightness(0.3);
        }
        &:hover,
        &.active-image {
          .facebook-gallery-thumbnail-image {
            filter: initial;
          }
        }
        &.active-image {
          border: 3px solid $c-secondary;
          border-radius: 0.5rem;
        }
      }
    }
  }
}
