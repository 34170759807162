@import '../../../Assets/Theme/variables.scss';
@import '../../../Assets//Theme/mixins.scss';

.adsView {
  .mdi-text {
    margin-top: 10px;
  }
  .mdi {
    color: $c-gray-dark;
  }
  .MuiGrid-item {
    display: flex;
  }
  .contentTag {
    margin-right: 5px;
    margin-left: 5px;
  }
  .cropperBox {
    box-shadow: 3px 3px 6px #00000033;
  }
  .adImage {
    border-radius: 20px !important;
    width: 250px !important;
    height: 80px !important;
  }
  .MuiCard-root {
    min-width: 350px !important;
  }
}
