@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';
.pending-tour-card-component-wrapper {
  padding: 1rem 0.5rem;
  .actions {
    z-index: 2;
  }
  .masonry-wrapper {
    min-width: 300px;
  }
  .slide-images {
    margin-bottom: 0;
    max-width: 178px;
    @include phones-max {
      margin-bottom: 1rem;
    }
  }
  .pending-tour-card-wrapper {
    display: inline-block;
    box-sizing: inherit;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    column-count: 1;
    width: 25%;
    min-width: 300px;
    .icon-box {
      display: inline-flex;
      flex: 0 1 auto;
      border-radius: 0.5rem;
      color: $c-white;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      min-width: 40px;
    }
    .item-separator {
      position: relative;
      &:after {
        content: ' ';
        position: absolute;
        background-color: $c-gray-light;
        width: calc(100% + 61px);
        height: 1px;
        bottom: 0;
        left: -0.5rem;
        @include rtl {
          right: -0.5rem;
          left: unset;
        }
      }
    }
    .cards-wrapper {
      position: relative;
      padding-top: 1rem;
      .cards-header {
        flex-direction: row;
        margin-bottom: 0.5rem;
        align-items: center;
        @include phones-max {
          flex-direction: column;
        }
      }
      .cards-body {
        height: initial;
      }
      .cards-footer {
        .flex-column {
          flex-direction: column;
        }
        .item-wrapper {
          align-items: center;
        }
      }
    }
    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
      width: 100%;
    }
    .cards-footer {
      padding: 0.5rem;
    }
    .cover-image {
      width: 45px;
      border-radius: 100%;
      box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
      height: 45px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin-bottom: 0.25rem;
    }
    .car-plate {
      background-color: $c-black-light;
      border-radius: 1rem;
      color: $c-white;
      margin: 0 0.25rem;
      padding: 0 0.25rem;
    }
  }
  // @include d-flex;
  // flex-wrap: wrap;
  // justify-content: center;
  // width: 100%;
  // padding: 1rem;

  //  {
  //   .cards-wrapper {
  //     position: relative;
  //     .cards-header {
  //       flex-direction: row;
  //     }
  //   }
  //   @include d-inline-flex;
  //   margin-bottom: 0.5rem;
  //   padding: 0 0.5rem;
  //   width: 25%;
  //   min-width: 300px;

  // }
  // .cover-image {
  //   width: 45px;
  //   border-radius: 100%;
  //   box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
  //   height: 45px;
  //   background-size: 100%;
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   margin-bottom: 0.25rem;
  // }
}
