@import '../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../Assets/Theme/mixins.scss';
.offer-form-component-wrapper {
  @include d-flex-column-center;
  padding: 0 1rem;
  width: 100%;
  overflow-x: hidden;
  background-color: $c-white;
  transition-property: left, right;
  transition-duration: 0.8s;
  transition-timing-function: linear;
  left: 0;
  @include rtl {
    right: 0;
    left: initial;
  }
  &.is-next {
    position: absolute;
    padding: 0;
    left: -100%;
    @include rtl {
      right: -100%;
      left: initial;
    }
    // animation: trips_list_slide_animation 1s linear 0s 1 normal;
  }
  .on-input-select {
    position: absolute;
    z-index: 1;
    top: 1.35rem;
    right: 0;
    height: 30px;
    // width: 50px;
    @include rtl {
      right: initial;
      left: 0.5rem;
    }
    .select-wrapper {
      min-width: initial;
      .selects {
        .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu {
          border-radius: 0 0.5rem 0.5rem 0 !important;
          height: 28px;
          @include rtl {
            border-radius: 0.5rem 0 0 0.5rem !important;
          }
        }
      }
    }
  }
  .form-section {
    @include d-flex-column;
    width: 100%;
    max-width: 700px;
    border: 1px solid $c-gray-secondary;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
  .form-item {
    margin-bottom: 1rem;
  }
}
