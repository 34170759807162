@import '../../../../../Assets/Theme/variables.scss';
@import '../../../../../Assets/Theme/mixins.scss';

.sliderView {
  .slideWrapper {
    padding: 10px;
    .sliderCard {
      .slidesDesc {
        .MuiTypography-root {
          font-size: 14px;
        }
      }
      .sliderImgDesc {
        margin-top: 10px;
        display: flex;
      }
      .slideImgParent {
        display: flex;
        z-index: 0;
        align-items: center;
        position: relative;
        @include rtl {
          flex-direction: row-reverse;
          transform: scaleX(-1);
        }
      }
      .slideImg {
        background-color: $c-primary;
        width: 100px;
        min-width: 100px;
        border-radius: 10px;
        color: $c-white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .sliderActions {
        background-color: $c-white;
        padding-top: 10px;
        display: flex;
        margin-top: 30px !important;
        flex-direction: column;
        justify-content: center;
        .MuiButton-root {
          display: flex;
          flex-direction: column;
          color: $c-blue;
          margin-bottom: 10px;
          border: 1px solid #e6e6e6;
          border-radius: 17px 0px 0px 17px;
          @include rtl {
            border-radius: 0px 17px 17px 0px;
          }
        }
      }

      .MuiSvgIcon-root {
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: -3px;
        font-size: 15px;
        @include rtl {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
      .sliderName {
        .MuiTypography-root {
          font-size: 16px !important;
        }
      }
      .inactiveSlider {
        .MuiTypography-root {
          color: $c-danger;
          font-size: 14px;
        }
      }
      .active-slider {
        .MuiTypography-root {
          color: $c-secondary;
          font-size: 14px;
        }
      }
      .sliderStatus {
        .MuiTypography-root {
          color: $c-blue;
          font-size: 14px;
        }
      }
      .MuiCardContent-root {
        padding-right: 10px;
        @include rtl {
          padding-right: 0px;
          padding-left: 10px;
        }
      }
      .MuiCard-root {
        cursor: pointer;
        border: 1px solid #e6e6e6;
        border-radius: 17px;
        box-shadow: 0 0 0 0 $c-white;
        &:hover {
          box-shadow: 3px 3px 6px $c-gray-lightest;
        }
        @include rtl {
          padding-right: 20px;
        }
      }
    }
  }
  .labelcard {
    font-weight: 600;
  }
}
.sliderCard:hover {
  .iconcarcard {
    color: $c-secondary;
  }

  .mainTitle {
    text-decoration: underline;
  }
}
