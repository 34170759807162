@import '../../../../Assets/Theme/variables.scss';
@import '../../../../Assets/Theme/mixins.scss';
.MuiDialogContent-root {
  overflow-y: hidden !important;
}
.dialogTitle {
  padding-bottom: 10px;
  .MuiTypography-root {
    padding-left: 10px;
    padding-bottom: 10px;
    font-size: 16px !important;
    color: $c-blue;
  }
}
.Titlemap {
  color: $c-blue;
  font-size: 13px;
  cursor: pointer;
}

.GridButtonmap {
  margin-left: 27%;
  margin-right: 27%;
  margin-bottom: 10px;
}
.mapdilog {
  min-width: 200px;
  min-height: 40px;
  margin-top: 50px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  .advance-search-wrapper {
  
    padding: 0rem !important;
  }
  div {
    width: 100% !important;
 
  }
  .pigeon-attribution {
    display: none;
  }
}
.mapdilogaddedit {
  min-width: 480px  !important;
  min-height: 300px  !important;
 
 
  }
  //.gmnoprint { display: none!important;}
.inputparking {
  text-align: center;
}

.mapButton-in-Parking:hover {
  background-color: rgb(191, 196, 211);

}
.mapButton-in-Parking {
  height: 35px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.inputmap {
  .inputs.theme-solid-border .MuiInputBase-input {
    padding: 0.5rem;
    color: #121212;
    text-align: center;
  }
}

.colse-button {
  padding: 15px;

  float: right;
  @include rtl {
    float: left;
  }
}
.deletecolse-button {
  float: right;
  @include rtl {
    float: left;
  }
}
.deleteDialog{
.dialogss {
 text-align: center;
 @media only screen and (max-width: 600px) {
  margin-left: 50px;
  margin-right: 50px;
}
  margin-left: 140px;
  margin-right: 140px;
}


}
.nameofparking {
  color: #31aec4 !important;
  font-weight: bold;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
}
.Divider
{
  width: 170px;
  display: inline-block;
}
