@import '../../../../../../Assets/Theme/variables.scss';

.RoleView {
  .colorCheckBoxes {
    margin-bottom: 20px;
  }
  .MuiExpansionPanel-root {
    box-shadow: 0 2px 8px 0 rgba(114, 114, 114, 0.2), 0 6px 20px 0 rgba(187, 187, 187, 0.116) !important ;
  }
  .MuiExpansionPanelDetails-root {
    display: block;
  }
  .PrivateTabIndicator-colorPrimary-2 {
    height: 3px !important;
    background-color: $c-white !important;
  }
  .MuiTabs-flexContainer {
    background-color: $c-primary !important;
    color: $c-white !important;
  }
  .MuiTabs-root {
    background-color: $c-primary !important;
    color: $c-white !important;
  }
  .paperWraper {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
    margin-top: 20px !important;
    margin-left: 20px !important;
    margin-bottom: 20px !important;
    width: 98% !important;
    background-color: $c-white !important;
    border-radius: 20px !important;
    -webkit-border-radius: 20px !important;
    -moz-border-radius: 20px !important;
    -ms-border-radius: 20px !important;
    -o-border-radius: 20px !important;
  }
  .globalCheckBox {
    .checkbox-wrapper.Mui-checked .mdi {
      background-color: $c-purple !important;
    }
  }
  .localCheckBox {
    .checkbox-wrapper.Mui-checked .mdi {
      background-color: $c-green-dark !important;
    }
  }
  .deepCheckBox {
    .checkbox-wrapper.Mui-checked .mdi {
      background-color: $c-blue !important;
    }
  }
  .basicCheckBox {
    .checkbox-wrapper.Mui-checked .mdi {
      background-color: $c-danger !important;
    }
  }
}
