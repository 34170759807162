@import '../../../../Assets/Theme/variables.scss';

.invoice-details-wrapper {
  color: $c-black-light;
  .invoice-header-wrapper {
    margin-bottom: 1rem;
    font-size: 18px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .invoice-time {
    display: flex;
    justify-content: space-between;
    .invoice-time-info {
      display: flex;
    }
  }
  .invoice-car-wrapper {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    .invoice-car-info {
      display: flex;
    }
  }
  .invoice-to-name {
    width: 8.5rem;
    word-break: break-all;
    text-align: end;
  }
  .invoice-tours-wrapper {
    overflow-y: auto;
    max-height: 35rem;
  }
  .invoice-images {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    .gallery-view-btn {
      align-self: flex-end;
      margin-bottom: 0.5rem;
      margin-right: 2rem;
    }
    .tour-name {
      width: 6.5rem;
    }
    .invoice-name-wrapper {
      margin-top: -2rem;
      position: absolute;
      .invoice-tour-name {
        font-weight: bold;
        color: $c-secondary;
      }
    }
  }

  .invoice-car {
    width: 6.5rem;
  }
  .divider-wrapper {
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    .MuiDivider-root {
      background-color: $c-gray-light;
      margin-top: 0.4rem;
      width: 90%;
      height: 0.01rem;
    }
  }
  .invoice-detailes-container {
    display: flex;
    .invoice-info-container {
      color: $c-gray-dark;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .invoice-info-content-container {
      padding: 0 1rem 0.5rem;
      padding-right: 0;
      color: $c-gray-dark;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  .payment-info {
    color: $c-black;
    display: flex;
    margin-left: -1rem;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.4rem;
  }
  .download-button {
    display: flex;
    justify-content: center;
  }
  .invoice-detailes-info {
    width: 100% !important;
    padding: 0.5rem 0 0;
    display: flex;
    .mdi:before {
      margin-left: 0.5rem;
      color: $c-secondary;
    }
  }
  .invoice-divider {
    padding-top: 0.5rem;
    margin-bottom: -0.5rem;
    width: 100% !important;
    border: 1px solid $c-gray-lightest;
  }
  .invoice-pickup {
    width: 15rem;
    margin-bottom: 0;
    .mdi:before {
      color: $c-green;
    }
  }
  .invoice-dropoff {
    width: 15rem;
    .mdi:before {
      color: $c-danger;
    }
  }
}
