@import '../../../../../Assets/Theme/mixins.scss';
.trip-card-conponent-wrapper {
  @include d-flex;
  flex-wrap: wrap;
  justify-content: center;
  .trip-card-wrapper {
    @include d-inline-flex;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    width: 25%;
    min-width: 300px;
    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
        width: 100%;
    }
    .cards-footer {
      padding: 0.5rem;
    }
    .trip-cover-image {
      width: 100%;
      border-radius: 1rem;
      height: 130px;
      background-size: 100%;
      box-shadow: 0 0 3px 1px rgba(#000000, 0.3);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
