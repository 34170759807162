@import '../../../Assets/Theme/variables.scss';
@import '../../../Assets//Theme/mixins.scss';

.sliderView .slideWrapper .sliderCard .MuiCardContent-root {
  @include laptops-max {
    padding-right: 0px !important;
  }
  @include laptops-xl-max {
    padding-right: 0px !important;
  }
  @include laptops-l-max {
    padding-right: 0px !important;
  }
  @include phones-max {
    padding-right: 25px !important;
  }
}

.sliderView {
  .MuiGrid-root .MuiGrid-container:not(.MuiGrid-item) {
    flex-wrap: nowrap;
    @include phones-max {
      flex-wrap: wrap;
    }
  }
  .MuiGrid-root .MuiGrid-container.MuiGrid-item {
    @include phones-max {
      justify-content: center;
    }
  }

  .slideWrapper {
    .MuiGrid-grid-xs-11 {
      max-width: 100%;
      flex-basis: 92.4444%;
    }
    min-width: 300px;
    .sliderCard {
      .slidesDesc {
        @include phones-max {
          margin-left: 0px;
        }
        margin-left: -30px;
        display: flex;
        .MuiTypography-root {
          font-size: 14px;
        }
      }
      .viewAllImg {
        @include phones-max {
          justify-content: center;
          text-align: center;
        }
        .MuiButton-root {
          @include phones-max {
            margin-left: 0px;
          }
          margin-left: -40px;
          text-transform: capitalize;
          font-size: 15px;
          color: $c-blue;
        }
      }
      .sliderImgDesc {
        margin-top: 10px;
        display: flex;
      }
      .slideImgParent {
        z-index: 0;
        display: flex;
        align-items: center;
        position: relative;
        @include rtl {
          flex-direction: row-reverse;
          transform: scaleX(-1);
        }
      }
      .slideImg {
        width: 100px;
        min-width: 100px;
        border-radius: 10px;
        display: flex;
      }
      .sliderImages {
        display: flex;
        padding-top: 10px;
      }
      .sliderActions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        @include rtl {
          align-items: flex-start;
        }
        .MuiButton-root {
          margin-right: -0.4rem;
          @include rtl {
            margin-right: 0;
            margin-left: -0.6rem;
          }
          background-color: $c-white;
          flex-direction: column;
          color: $c-secondary !important;
          border: 1px solid #e6e6e6;
          border-radius: 17px 0px 0px 17px;
          @include rtl {
            border-radius: 0px 17px 17px 0px;
          }
        }
      }
      .MuiSvgIcon-root {
        margin-top: 5px;
        margin-right: 10px;
        margin-bottom: -3px;
        font-size: 15px;
        @include rtl {
          margin-right: 0px;
          margin-left: 10px;
        }
      }
      .sliderName {
        .MuiTypography-root {
          font-size: 16px !important;
        }
      }
      .inactiveSlider {
        .MuiTypography-root {
          color: $c-danger;
          font-size: 14px;
        }
      }
      .sliderStatus {
        .MuiTypography-root {
          color: $c-blue;
          font-size: 14px;
        }
      }
      .MuiCard-root {
        cursor: pointer;
        border: 1px solid #e6e6e6;
        border-radius: 17px;
        box-shadow: 0 0 0 0 $c-white;
        &:hover {
          box-shadow: 3px 3px 6px $c-gray-lightest;
        }
      }
    }
  }
}
