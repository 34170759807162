@import '../../../../../Assets/Theme/mixins.scss';

.LocationsSection {
  width: 86%;
  text-align: center;
  margin-top: 20px;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff;
  .MuiGrid-spacing-xs-2 {
    justify-content: center;
  }

  .Contenater{
      text-align: center;
      display: flex;
      
  }
}
.lables-Sold-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 2rem 2rem 0 2rem;

  .chart-item {
      width:100%;
      height: 20px;
    display: flex;
    justify-content:space-between
  }
    hr{
      margin-top:2px;
      margin-bottom:2px;
  }
    span {
      text-align: left;
    }
  }
  .Soldline{
    width:100% ;
    height:25px;
   
  }

  .sold-tourName{
    width: 50%;
    padding: 0px 0px 5px 7px;
    font-weight: bold;
    color: black;
    text-align: start;
  }
  .sold-total{
    width: 50%;
    padding: 0px 0px 5px 7px;
    font-weight: bold;
    color: black;
    text-align: end;
  }
  
  
  
 



.lables-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 2rem;
  .chart-item {
  width: 50%;
  display: inline-flex;
  flex: 0 1 auto;
  display: space-between;
    span {
      text-align: left;
    }
  }

}

.Locations {
  box-shadow: 0px 25px 30px #0000001a;
  border-radius: 10px;
  opacity: 1;
  min-width: 274px;
  position: relative;
  min-height: 162px;
  height: 100%;
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1 100%;
 
}
.chartjs{
  
  width:325px;
  height: 170px;
  display:initial
    
}
.Total-TourName{
  display:flex;
}

.LocationsTitle {
  display: flex;
  .LocationsPargraph {
    width: 100%;
    flex-basis: 100%;
    padding: 0 2rem;
    text-align: start;
    margin-top: 5px;
  }
  .LocationsPargraph.Select{
    color:#2B0649;
    font-size:16px;
  }
}

.SoldTours-select{
  .select-wrapper.MuiFormControl-root.theme-solid .selects .MuiSelect-select{
    border: none;
    justify-content:flex-end;
    color:#2B0649;
    margin-right: 7px;
  }
  .select-wrapper.MuiFormControl-root.theme-solid .selects .MuiSelect-icon:not(.Mui-disabled){
    fill:#2B0649;
  }
}

.ToursDate{
padding: 40px;
font-size:30px;
height: 180px;
}
.NoData{
  background-size: cover;
  width: 275px;
  height: 209px;
  margin: auto;
}
.NoData.Location{
  background-image: url('../../../../../Assets/Images/defaults/NoLocations.png');
}

.NoData.TotalTours{
  background-image: url('../../../../../Assets/Images/defaults/NOBarChart.png');
}

.NoData.TotalSold{
  background-image: url('../../../../../Assets/Images/defaults/NoLocations.png');
}

.chartDi{
  position: relative;
}
.Total-number{
  position: absolute;
  font-weight:bold;
  color: black;
  top: 1.3rem;
  left: 3.2rem;
}
