// ============================================== Start Colors
$c-primary: #006186;
$c-purple: #ae00ff;
$c-primary-dark: #003366;
$c-secondary: #31aec4;
$c-success: #4cceac;
$c-warning: #ff6f59;
$c-warning-light: #ffe2de;
$c-danger: #bf3c3c;
$c-info: #71a0ff;
$c-black: #000000;
$c-black-dark: #020311;
$c-black-light: #2d3e50;
$c-white: #ffffff;
$c-white-dark: #dfe9ea;
$c-blue: #0066cc;
$c-blue-light: #3e7192;
$c-blue-lighter: #5fb7ff;
$c-blue-lightest: #b6d2ff;
$c-red: #ff0000;
$c-red-lighter: #ffe8e4;
$c-green-dark: #25d366;
$c-green: #8cc63e;
$c-green-light: #dbf5ee;
$c-gray-default: #e6e6e6;
$c-gray-primary: #121212;
$c-gray-secondary: #999999;
$c-gray-dark: #4d4d4d;
$c-gray-light: #b3b3b3;
$c-gray-lighter: #fafafa;
$c-gray-lightest: #f2f2f2;
$c-lavender: #e1e2fe;
$c-error: #f44336;
$c-yellow: #f0d22e;
$c-yellow-lightest: #fff2a885;
$c-orange: #f5a81d;
$c-orange-lightest: #ffe8bea8;

// ============================================== Start Gradients
$bg-gradient-primary: linear-gradient(191deg, $c-primary 91%, $c-primary-dark 10%);
$bg-gradient-primary-right: linear-gradient(to right, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-left: linear-gradient(to left, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-top: linear-gradient(to top, $c-primary 0%, $c-primary-dark 100%);
$bg-gradient-primary-bottom: linear-gradient(to bottom, $c-primary 0%, $c-primary-dark 100%);
// ============================================== Start Font Sizes
$fz-default: 13px;
$fz-13px: 13px;
$fz-15px: 15px;
$fz-16px: 16px;
$fz-30px: 30px;
$fz-11px: 11px;
$fz-10px: 10px;
$fz-9px: 9px;
$fz-table-header: 13px;
$fz-table-body: 11px;
$fz-table-footer: 13px;
// ============================================== Start Box Shadows
$bs-primary: 2px 2px 1px 0 rgba(0, 0, 0, 0.2);
$bs-secondary: 2px 4px 4px 0 rgba(136, 145, 145, 0.75);
// ============================================== Start Images
$img-logo: url('../../Assets/Images/defaults/logo.png');
// ============================================== Start Icons
$i-bell-blue: url('../../Assets/Images/icons/bell-blue.svg');
$i-text-gray: url('../../Assets/Images/icons/text-gray.svg');
$i-home-blue: url('../../Assets/Images/icons/home-blue.svg');
$i-home-white: url('../../Assets/Images/icons/home-white.svg');
$i-home-blue-dark: url('../../Assets/Images/icons/home-blue-dark.svg');
$i-home-gray: url('../../Assets/Images/icons/home-gray.svg');
$i-contacts-blue: url('../../Assets/Images/icons/contacts-blue.svg');
$i-contacts-white: url('../../Assets/Images/icons/contacts-white.svg');
$i-contacts-blue-dark: url('../../Assets/Images/icons/contacts-blue-dark.svg');
$i-contacts-gray: url('../../Assets/Images/icons/contacts-gray.svg');
$i-people-blue: url('../../Assets/Images/icons/people-blue.svg');
$i-people-white: url('../../Assets/Images/icons/people-white.svg');
$i-people-blue-dark: url('../../Assets/Images/icons/people-blue-dark.svg');
$i-people-gray: url('../../Assets/Images/icons/people-gray.svg');
$i-arrow-reverse-blue: url('../../Assets/Images/icons/arrow-reverse-blue.svg');
$i-arrow-reverse-white: url('../../Assets/Images/icons/arrow-reverse-white.svg');
$i-arrow-reverse-blue-dark: url('../../Assets/Images/icons/arrow-reverse-blue-dark.svg');
$i-arrow-reverse-gray: url('../../Assets/Images/icons/arrow-reverse-gray.svg');
$i-speed-monitor-blue: url('../../Assets/Images/icons/speed-monitor-blue.svg');
$i-speed-monitor-white: url('../../Assets/Images/icons/speed-monitor-white.svg');
$i-speed-monitor-blue-dark: url('../../Assets/Images/icons/speed-monitor-blue-dark.svg');
$i-speed-monitor-gray: url('../../Assets/Images/icons/speed-monitor-gray.svg');
$i-report-edit-blue: url('../../Assets/Images/icons/report-edit-blue.svg');
$i-report-edit-white: url('../../Assets/Images/icons/report-edit-white.svg');
$i-report-edit-blue-dark: url('../../Assets/Images/icons/report-edit-blue-dark.svg');
$i-report-edit-gray: url('../../Assets/Images/icons/report-edit-gray.svg');
$i-report-view-blue: url('../../Assets/Images/icons/report-view-blue.svg');
$i-report-view-white: url('../../Assets/Images/icons/report-view-white.svg');
$i-report-view-blue-dark: url('../../Assets/Images/icons/report-view-blue-dark.svg');
$i-report-view-gray: url('../../Assets/Images/icons/report-view-gray.svg');
$i-folder-blue: url('../../Assets/Images/icons/folder-blue.svg');
$i-folder-white: url('../../Assets/Images/icons/folder-white.svg');
$i-folder-blue-dark: url('../../Assets/Images/icons/folder-blue-dark.svg');
$i-folder-gray: url('../../Assets/Images/icons/folder-gray.svg');
$i-box-circle-blue: url('../../Assets/Images/icons/box-circle-blue.svg');
$i-box-circle-white: url('../../Assets/Images/icons/box-circle-white.svg');
$i-box-circle-blue-dark: url('../../Assets/Images/icons/box-circle-blue-dark.svg');
$i-box-circle-gray: url('../../Assets/Images/icons/box-circle-gray.svg');
$i-activities: url('../../Assets/Images/icons/activities.svg');
$i-cloud: url('../../Assets/Images/icons/cloud.svg');
$i-upload: url('../../Assets/Images/icons/upload.svg');
$i-shield: url('../../Assets/Images/icons/shield.svg');
$i-eiffel-tower: url('../../Assets/Images/icons/eiffel-tower.svg');
$i-calendar-blank: url('../../Assets/Images/icons/calendar-blank.svg');
$i-calendar: url('../../Assets/Images/icons/calendar.svg');
$i-oclock: url('../../Assets/Images/icons/oclock.svg');
$i-email: url('../../Assets/Images/icons/email.svg');
$i-account: url('../../Assets/Images/icons/account.svg');
$i-slider: url('../../Assets/Images/icons/slider.svg');
$i-slider-white: url('../../Assets/Images/icons/slider-white.svg');
$i-slider-gray: url('../../Assets/Images/icons/slider-gray.svg');
$i-slider-primary: url('../../Assets/Images/icons/slider-primary.svg');

// Start Background Images
$bg-dubai: url('../../Assets/Images/defaults/bg-dubai.png');
$bg-login-dubai: url('../../Assets/Images/pages/login/bg-login-dubai.svg');
$bg-login: url('../../Assets/Images/pages/login/bg-login.jpg');
$bg-city: url('../../Assets/Images/pages/login/city.svg');
$bg-city-shadow: url('../../Assets/Images/pages/login/city-shadow.svg');
$bg-box-curve-right: url('../../Assets/Images/pages/login/box-curve-right.svg');
$bg-box-curve-left: url('../../Assets/Images/pages/login/box-curve-left.svg');
