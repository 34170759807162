@import '../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../Assets/Theme/mixins.scss';

.features-wrapper {
  .header-items-wrapper {
    @include d-flex-v-center-h-between;
    flex-wrap: wrap;
    .header-item {
      @include d-inline-flex-center;
      flex-wrap: wrap;
    }
  }
  .padding-feature {
    padding: 1rem;
  }
  .features-buttons {
    .btns.MuiButtonBase-root {
      background-color: $c-secondary;
      color: $c-white;
      margin: 0.2rem;
    }
  }
  .last-item {
    padding-bottom: 2.5rem;
  }
  .items-wrapper {
    display: flex;
    justify-content: center;
  }
  .MuiList-root {
    min-height: 19.7rem;
  }
  .last-item {
    margin-top: -1rem;
  }
  .feature-container {
    position: relative;
    .feature-button {
      position: absolute;
      justify-content: center;
      bottom: 0;
    }
  }
  .list-subheader {
    background-color: $c-white;
  }
  .group-name {
    .MuiTypography-root {
      padding: 0.5rem 0 0.5rem 1rem;
      color: $c-gray-dark;
      font-weight: bold;
      font-size: 14px;
      @include rtl {
        padding: 0.5rem 1rem 0.5rem 0;
      }
    }
  }
  .MuiPaper-elevation1 {
    width: 20rem;
    min-height: 20rem;
    max-height: 20rem;
    overflow-y: auto;
    border-radius: 17px;
    border: 1px solid $c-gray-lightest;
    box-shadow: 5px 3px 10px $c-gray-lightest;
  }
  .MuiTypography-root {
    color: $c-gray-dark;
    padding: 0.3rem;
  }
  .select-wrapper.MuiFormControl-root.theme-solid {
    min-width: 25rem;
    width: auto;
  }
}
