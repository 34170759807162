@import '../../../../../../Assets/Theme/mixins.scss';
.users-card-component-wrapper {
  @include d-flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 1rem;
  .users-card-wrapper {
    .cards-wrapper {
      position: relative;
      .cards-header {
        flex-direction: row;
      }
    }
    @include d-inline-flex;
    margin-bottom: 0.5rem;
    padding: 0 0.5rem;
    width: 25%;
    min-width: 300px;
    @include laptops-l-max {
      width: 33.3333%;
    }
    @include laptops-max {
      width: 50%;
    }
    @include tablets-max {
      width: 100%;
    }
    .user-cover-image {
      height: 55px;
      width: 55px;
      border-radius: 100%;
      background-size: 100%;
      box-shadow: 0 0 2px .5px rgba(#000000, 0.3);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}
