@import '../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../Assets/Theme/mixins.scss';
.json-form-wrapper {
  @include d-inline-flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  margin-bottom: 0.5rem;
  box-shadow: 0 0 5px 2px rgba($c-black, $alpha: 0.2);
  border-radius: 0.5rem;
  min-height: 200px;
  .notification-form-wrapper {
    @include d-flex-center;
    align-self: center;
  }
}
