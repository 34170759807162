@import '../../Assets/Theme/mixins.scss';
.slide-images {
  &.with-gallery {
    display: inline-flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    align-items: center;
    .slide-image {
      left: 0;
      @include rtl {
        left: initial;
        right: 0;
      }
    }
  }
  // .gallery-view-btn{

  // }
}
