.invoice-container {
  .MuiPaper-rounded {
    padding: 0.6rem;
    border-radius: 17px !important;
  }
}
.price-slider {
  padding-right: 1rem;
  padding-left: 0.5rem;
  width: 25rem;
}
.btns-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .MuiButton-root {
    margin: 0.5rem;
    width: 100%;
    border-radius: 10px !important;
    border: solid 1px #e6e6e6 !important;
  }
}
