@import '../../../Assets/Theme/mixins.scss';
@import '../../../Assets/Theme/variables.scss';

.tracking-view-wrapper {
  .google-maps {
    border-radius: 1rem;
  }
  .tracking-google-map {
    height: calc(100% - 25px);
    width: 100%;
  }
  .tour-driver {
    display: flex;
    align-items: center;
    .MuiIconButton-root {
      margin-left: 1rem;
      background-color: $c-secondary;
      color: $c-white;
      @include rtl {
        margin-left: 0;
        margin-right: 1rem;
      }
    }
    .mdi:before {
      font-size: 16px;
    }
  }
  .select-wrapper.MuiFormControl-root.theme-solid {
    width: 50%;
    @include phones-max {
      width: 100% !important;
    }
  }
  #player {
    z-index: 1;
    position: relative;
  }
  #inner {
    transform: rotate(45deg);
    background-color: $c-secondary;
    height: 75px;
    left: -50px;
    position: relative;
    border-radius: 20px;
  }
  #triangle-up {
    bottom: -35px;
    transform: rotate(-90deg);
    width: 70px;
    height: 140px;
    position: absolute;
    overflow: hidden;
  }
  #outer {
    z-index: 1;
    transform: rotate(-90deg);
    width: 70px;
    bottom: -65px;
    height: 140px;
    position: absolute;
    overflow: hidden;
  }
  .arrow-up {
    width: 0;
    right: -10px;
    height: 0;
    top: 10px;
    position: absolute;
    z-index: 100;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid $c-white;
  }
  .arrow-up-table {
    width: 0;
    right: -10px;
    height: 0;
    top: -15px;
    position: absolute;
    z-index: 100;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $c-white;
  }
  .select-all-cities {
    margin-left: 10px;
  }
  .bottom-drawer-wrapper {
    .mdi:before {
      font-size: 45px !important;
    }
    display: flex;
    justify-content: center;
    .bottom-drawer {
      background-color: $c-secondary;
      border-radius: 40px 40px 0 0;
      overflow: hidden;
      width: 65px;
      height: 30px;
    }
  }
  .bottom-table {
    display: flex;
    justify-content: center;
  }
  .map-table-wrapper {
    position: relative;
    top: 0;
    margin-top: 25px;
    z-index: 99;
    transition: all 0.5s linear;

    .map-table {
      // transform: translateY(-50%);
      position: relative;
      background-color: $c-white;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      @include rtl {
        border-top-right-radius: 1rem;
        border-top-left-radius: 0;
      }
      .table-tag-btn {
        position: absolute;
        cursor: pointer;
        top: -30px;
        height: 38px;
        width: 50px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        .middle-ico {
          &:before {
            color: $c-white;
            font-size: 30px;
            position: absolute;
            z-index: 1;
            left: 50%;
            transform: translate(-50%, -50%);
            top: 50%;
          }
        }
        .background-ico {
          &:before {
            color: $c-secondary;
            font-size: 60px;
            position: absolute;
            left: 50%;
            transform: translateX(-50%) rotate(90deg);
            top: 0;
          }
        }
      }
      .table-responsive {
        height: 37px;
        overflow: hidden;
        transition: all 0.5s linear;
      }
    }
    &.is-open {
      top: -35%;
      .map-table {
        background-color: $c-white;
        height: 30vh;
        .table-responsive {
          height: 100%;
        }
      }
    }
  }
  // .map-table {
  //   position: absolute;
  //   width: 100%;
  //   padding: 10px;
  //   bottom: 0;
  //   padding-top: 0;
  // }
  // .map-table-height {
  //   position: relative;
  //   margin: 10px;
  //   margin-top: 0;
  //   .table-responsive {
  //     height: 34px !important;
  //     overflow: hidden;
  //     height: 200px;
  //     position: absolute;
  //     bottom: -64px;
  //   }
  // }
}
.view-wrapper,
.section {
  height: calc(100% - 80px);
}
