@import './../../Assets/Theme/variables.scss';
@import './../../Assets/Theme/mixins.scss';
.page-not-exist-component {
  @include d-flex-center;
  padding: 1rem;
  flex-wrap: wrap;
  .text-section,
  .image-section {
    @include d-inline-flex-column-center;
    color: $c-secondary;
  }
  .image-section {
    height: 100%;
  }
}
