@import '../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../Assets/Theme/mixins.scss';

.shifted-cards-wrapper {
  padding: 2rem;
  .shifted-cards-title {
    font-size: 18px;
    padding-bottom: 1rem;
  }
  .tour-price {
    font-weight: bold;
    color: $c-secondary;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .points-card-description {
    display: flex;
    font-size: 14px;
    margin-top: 5.5rem;
    margin-left: 1.2rem;
  }
  .shifted-cards-content {
    position: relative;
    display: flex;
    overflow-x: auto;
    height: 19.5rem;
    .shifted-card {
      min-width: 20rem;
      margin-right: 1.5rem;
      @include rtl {
        margin-right: 0;
        margin-left: 1.5rem;
      }
      .top-card {
        background-color: white;
        border: 0.18rem solid #e8e8e873;
        box-shadow: 0px 3px 15px #00000010;
        border-radius: 1rem;
        width: 90%;
        float: right;
        @include rtl {
          float: left;
        }
        height: 11rem;
        display: flex;
        justify-content: space-between;
        .top-card-title {
          padding: 0.5rem;
          padding-left: 1.5rem;
          @include rtl {
            padding-left: 0;
            padding-right: 1.5rem;
          }
          font-weight: 600;
          font-size: 12px;
        }
        .favourite-heart {
          padding: 0.5rem;
          .MuiIconButton-root {
            border-radius: 20rem;
            float: right;
            @include rtl {
              float: left;
            }
          }
        }
      }
      .bottom-card {
        position: relative;
        .bottom-image {
          background-color: darkgray;
          border-radius: 1rem 1rem 1rem 4.5rem;
          @include rtl {
            border-radius: 1.5rem 1.5rem 4.5rem 1.5rem;
          }
          width: 89%;
          height: 10.3rem;
          position: absolute;
          top: 2.5rem;
          z-index: 90;
          overflow: hidden;
          .img-center {
            height: 10.5rem;
            width: 100%;
          }
        }
        .card-cart-button {
          position: absolute;
          bottom: 3.5rem;
          right: 1rem;
          @include rtl {
            right: auto;
            left: 1rem;
          }
          z-index: 99;
          .MuiIconButton-root {
            box-shadow: 0px 3px 15px #0000001e;
            background-color: white;
            border-radius: 20rem;
            width: 3rem;
            height: 3rem;
            .mdi::before {
              font-size: 24px;
            }
          }
        }
        .bottom-footer {
          background-color: white;
          border: 0.18rem solid #e8e8e873;
          box-shadow: 0px 3px 15px #0000001c;
          border-radius: 1.5rem;
          width: 89%;
          float: left;
          @include rtl {
            float: right;
          }
          height: 10.4rem;
          position: absolute;
          top: 7.5rem;
          .rating-wrapper {
            position: absolute;
            bottom: 0.2rem;
            padding-left: 1rem;
            @include rtl {
              padding-left: 0;
              padding-right: 1rem;
            }
            .MuiRating-root {
              font-size: 1.5rem;
            }
            .rating-amount {
              flex-wrap: nowrap;
              font-size: 14px;
              width: 5rem;
              padding-left: 0.2rem;
              @include rtl {
                padding-left: 0;
                padding-right: 0.2rem;
              }
            }
          }
        }
      }
    }
  }
}
