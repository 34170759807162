@import '../../../../Assets/Theme/variables.scss';
@import '../../../../Assets//Theme/mixins.scss';

.promo-code-side-wrapper {
  padding-left: 0.5rem;
  .promo-name {
    padding-bottom: 0.5rem;
    .MuiTypography-body1 {
      color: $c-black;
      font-size: 16px;
      font-weight: bold;
    }
  }
  .promo-user-detailes {
    display: inline;
  }
  .promo-users {
    padding-bottom: 0.5rem;
    .MuiTypography-body1 {
      padding-top: 1rem;
      color: $c-black;
      font-size: 16px;
    }
  }
  .promo-icon {
    padding: 0.2rem 0;
    color: $c-black;
    .mdi:before {
      font-size: 16px;
      margin-right: 0.4rem;
      @include rtl {
        margin-left: 0.4rem;
        margin-right: 0;
      }
    }
  }
  .promo-status {
    font-size: 18px;
    font-weight: bold;
  }
  .public-promo {
    color: $c-secondary;
  }
  .contact-image {
    width: 3rem;
    height: 3rem;
    border-radius: 100%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
  .promo-code-users {
    padding: 0.5rem;
    display: flex;
    align-items: center;
    .MuiTypography-body1 {
      color: $c-gray-dark;
      font-size: 14px;
    }
  }
  .private-promo {
    color: $c-warning;
  }
}
