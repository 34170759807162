@import '../../../../../../../../../Assets/Theme/variables.scss';
@import '../../../../../../../../../Assets/Theme/mixins.scss';
.popover-wrapper {
  &.confirm-popover {
    .MuiPopover-paper {
      border-radius: 1rem;
      overflow: hidden;
    }
    .confirm-wrapper {
      max-width: 350px;
      .confirm-header {
        @include d-flex-v-center-h-between;
        padding: 0.5rem 0;
        .confirm-title-text {
          border-bottom: 1px solid $c-secondary;
          color: $c-secondary;
          font-size: 16px;
          padding: 0 0.5rem 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
      .confirm-body {
        padding: 0.5rem;
      }
      .confirm-footer {
        @include d-flex-column-center;
      }
    }
  }
}
