@import '../../Assets/Theme/variables.scss';
// @import '../../Assets/Theme/mixins.scss';
.tabs-wrapper {
    // @include d-flex;
  &.MuiTabs-root {
    min-height: 35px;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: $c-primary;
  }
  .PrivateTabIndicator-colorPrimary-2 {
    background-color: $c-primary;
  }
  .MuiTab-root {
    min-height: 35px;
    font-size: $fz-13px !important;
    min-width: 100px !important;
    padding: 0.25rem 0.5rem;
    text-transform: none;
  }
  .MuiButtonBase-root {
    color: $c-black;
    transition: min-width 0.3s linear;
  }
  &.icon-only {
    .MuiButtonBase-root {
      min-width: 55px;
    }
  }
}
