.MuiRadio-colorPrimary.Mui-checked {
  color: #006186 !important;
}
#upload-photo {
  display: none;
}
.DisableddoneBtn {
  background: #999999 !important;
}
.imgcar {
  width: auto;
  height: 130px;
}
.nameofdriver {
  color: #31aec4 !important;
  font-weight: bold;
  text-align: center;
}
.deleteDialog {
  .delete-Dialog-seaction {
    margin-left: 140px;
    margin-right: 140px;
    text-align: center;
    @media only screen and (max-width: 600px) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
.Divider {
  width: 170px;
  display: inline-block;
}
