@import '../../../Assets/Theme/variables.scss';
@import '../../../Assets//Theme/mixins.scss';

.order-popover {
  .MuiPopover-paper {
    padding: 0.7rem 0;
    display: flex;
    flex-direction: row;
  }
  .select-wrapper.MuiFormControl-root.theme-solid {
    padding: 0.5rem;
    min-width: 10rem;
  }
}
.order-lable {
  .MuiTypography-body1 {
    margin-left: 0.8rem !important;
  }
}
.clear-filter {
  display: flex;
}
.select-wrapper.MuiFormControl-root.theme-solid {
  @include small-phones-max {
    // min-width: 18rem;
  }
}
.contact-support-wrapper {
  .filter-by {
    padding-left: 1.5rem;
    @include rtl {
      padding-right: 1.5rem;
    }
    @include laptops-max {
      padding-left: 0px !important;
    }
  }
  .search-section {
    @include phones-max {
      margin-bottom: 1rem !important;
    }
  }
  .filter-section {
    justify-content: end !important;
    .select-wrapper.MuiFormControl-root.theme-solid .selects .MuiSelect-select {
      border-radius: 100px !important;
    }
    .select-wrapper.MuiFormControl-root.theme-solid .selects .MuiSelect-icon {
      fill: $c-secondary;
      color: $c-secondary;
    }
    .MuiFormControl-root {
      border-radius: 100px !important;
    }
  }
  .card-section {
    margin-bottom: 1rem;
    position: relative;
    &:after {
      content: ' ';
      left: 0;
      top: 0;
      width: 2.7px;
      height: 100%;
      position: absolute;
      background-color: var(--card-color);
      @include rtl {
        right: 0;
      }
    }
  }
  .contact-name {
    font-weight: bold;
  }
  .contact-detailes {
    display: flex;
    .MuiChip-root {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 4.5rem;
      border-radius: 6px;
      margin-left: 0.5rem;
      @include laptops-max {
        text-overflow: inherit;
        white-space: normal;
        overflow: auto;
        width: auto;
      }
      @include laptops-l-max {
        text-overflow: inherit;
        white-space: normal;
        overflow: auto;
        width: auto;
      }
      @include rtl {
        margin-left: 0;
        margin-right: 0.5rem;
      }
    }
  }

  .card-section-container {
    padding: 1rem 2rem;
    .MuiCard-root {
      padding: 1rem 0;
      padding-left: 0.7rem;
      @include rtl {
        padding-right: 0.7rem;
        padding-left: 0;
      }
      cursor: pointer;
      border: 1px solid #e0e0e0;
      border-radius: 17.4px;
      box-shadow: 0 0 0 0 $c-white;
      &:hover {
        box-shadow: 3px 3px 6px $c-gray-lightest;
      }
    }
  }
  .card-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    @include rtl {
      align-items: flex-end;
    }
    .mdi:before {
      font-size: 18px !important;
    }
    .MuiButton-root {
      min-width: 3.2rem;
      height: 1.5rem;
      margin-right: -0.1rem;
      padding-left: 0.7rem;
      z-index: 99;
      background-color: $c-white;
      flex-direction: column;
      color: $c-secondary;
      align-items: flex-start;
      border: 1px solid #e6e6e6;
      border-radius: 17px 0px 0px 17px;
      @include rtl {
        border-radius: 0px 17px 17px 0px;
        padding-left: 0;
        padding-right: 0.7rem;
        margin-right: 0;
      }
    }
  }
  .support-section {
    overflow-y: auto;
    height: calc(100vh - 270px);
  }
  .support-body {
    color: $c-gray-dark;
    .MuiTypography-root {
      padding: 1rem 0;
      font-size: 14px;
    }
  }
  .support-detailes {
    .MuiTypography-root {
      font-size: 24px;
    }
    display: flex;
    flex-direction: row;
  }
  .support-subject {
    font-weight: bold;
    margin-right: 0.5rem;
    @include rtl {
      margin-left: 0.5rem;
      margin-right: 0;
    }
  }
  .support-contact-name {
    font-weight: bold;
    padding: 1rem 0;
    font-size: 16px;
  }
  .support-chips {
    .MuiChip-root {
      border-radius: 6px;
      margin-right: 0.7rem;
      @include rtl {
        margin-left: 0.7rem;
        margin-right: 0;
      }
    }
  }
  .contacts-card-container {
    overflow-y: auto;
    height: calc(100vh - 270px);
  }
  .contact-image {
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 100%;
    margin-right: 0.5rem;
  }
  .card-content {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 13rem;
    padding-top: 0.2rem;
  }
  .mdi-icons-color {
    .mdi {
      color: $c-secondary !important;
    }
  }
}
